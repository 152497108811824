import PaperclipIcon from "lucide-static/icons/paperclip.svg"
import UploadIcon from "lucide-static/icons/upload.svg"
import { useState } from "react"
import InlineSVG from "react-inlinesvg"

import {
  FileInput,
  FileUploader,
  FileUploaderContent,
  FileUploaderItem,
} from "@fourel/ui"

const FileSvgDraw = () => {
  return (
    <>
      <InlineSVG src={UploadIcon} className="mb-2 h-auto w-5 text-gray-700" />
      <p className="mb-1 text-sm text-gray-500 dark:text-gray-400">
        <span className="font-semibold">Click to upload</span>
        &nbsp; or drag and drop
      </p>
    </>
  )
}

export const UploadArea = ({ onFileChange }: { onFileChange: (file: File) => void }) => {
  const [files, setFiles] = useState<File[] | null>(null)

  const dropZoneConfig = {
    maxFiles: 1,
    maxSize: 1024 * 1024 * 4,
    multiple: false,
  }

  const handleFileChange = (newFile: File[] | null) => {
    setFiles(newFile)
    if (newFile && newFile.length > 0) {
      onFileChange(newFile[0])
    }
  }

  return (
    <FileUploader
      value={files}
      onValueChange={(newFile) => handleFileChange(newFile)}
      dropzoneOptions={dropZoneConfig}
      className="bg-background relative rounded-lg p-2"
    >
      <FileInput className="bg-gray-200 outline-dashed outline-1 outline-gray-400">
        <div className="flex w-full flex-col items-center justify-center pb-4 pt-3">
          <FileSvgDraw />
        </div>
      </FileInput>
      <FileUploaderContent>
        {files &&
          files.length > 0 &&
          files.map((file, i) => (
            <FileUploaderItem key={i} index={i}>
              <InlineSVG src={PaperclipIcon} className="h-4 w-4 stroke-current" />
              <span>{file.name}</span>
            </FileUploaderItem>
          ))}
      </FileUploaderContent>
    </FileUploader>
  )
}
