export const QueryKeys = {
  Operators: {
    Get: "operatorsKey",
    GetById: "operatorByIdKey",
  },
  Clients: {
    GetByOrg: "organizationClientsKey",
  },
  Aircraft: {
    GetByRegNumber: "aircraftByRegNumberKey",
    GetByOperatorId: "arcraftsByOperatorIdKey",
    GetByModelAndRegNumber: "aircraftsByModelAndRegNumberKey",
  },
  Airport: {
    GetByName: "airportByNameKey",
  },
  Trips: {
    GetByOrganizationId: "tripsByOrganizationIdKey",
    Delete: "tripDeleteKey",
    Get: "tripKey",
  },
}
