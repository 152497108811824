import { lazyRouteComponent, Link, Route } from "@tanstack/react-router"
import AlertCircle from "lucide-static/icons/alert-circle.svg"
import * as React from "react"
import InlineSVG from "react-inlinesvg"

import { Alert, AlertDescription, AlertTitle } from "@fourel/ui"

import { loginRoute } from "#router.js"

export const loginInviteRoute = new Route({
  getParentRoute: () => loginRoute,
  path: "/invite",
  validateSearch: (search: Record<string, unknown>): { inviteId: string } => {
    if ("inviteId" in search) {
      return { inviteId: search.inviteId as string }
    }
    throw Error("No inviteId in the url")
  },
}).update({
  errorComponent: ({ error }) => {
    return (
      <div className="relative h-[100vh] w-[100vw]">
        <Alert
          variant="destructive"
          className="absoluabsolute -translate-y-1/2te left-1/2 top-1/2 w-max -translate-x-1/2 transform"
        >
          <InlineSVG src={AlertCircle} className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription className="flex flex-col gap-2">
            <p>
              {error instanceof Error ? error?.message : "Unknown error"}, please retry
            </p>
            <Link to="/login">Redirect to login page</Link>
          </AlertDescription>
        </Alert>
      </div>
    )
  },
  component: lazyRouteComponent(() => import("./invite.js"), "Invite"),
})
