import FilterIcon from "lucide-static/icons/filter.svg"
import InlineSVG from "react-inlinesvg"

import { Button, cn } from "@fourel/ui"

import { useReferenceDateStore } from "#pages/flights/flights-store.js"

const filterButtonValue = (days: number) => {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + days)

  return currentDate
}
const filterButtonsData = [
  {
    id: "followUpListTableFilterUrgent",
    title: "Urgent action",
    days: filterButtonValue(1),
  },
  {
    id: "followUpListTableFilterThreeDays",
    title: "3 days",
    days: filterButtonValue(3),
  },
  {
    id: "followUpListTableFilterFiveDays",
    title: "5 days",
    days: filterButtonValue(5),
  },
  {
    id: "followUpListTableFilterSevenDays",
    title: "7 days",
    days: filterButtonValue(7),
  },
  {
    id: "followUpListTableFilterTenDays",
    title: "10 days",
    days: filterButtonValue(10),
  },
  { id: "followUpListTableFilterAll", title: "Current month", days: null },
]

export const TableFilterHeader = () => {
  const { setReferenceDate, setEndDate, setFilterTitle, filterTitle } =
    useReferenceDateStore()

  const setFilterOptionHandler = (days: Date | null, title: string) => {
    setReferenceDate(new Date())
    setFilterTitle(title)
    setEndDate(days)
  }

  return (
    <div className="mb-2 flex w-full items-center justify-start gap-4 px-6">
      <div className="flex h-8 gap-2 p-2 text-xs">
        <InlineSVG src={FilterIcon} className="text-muted-foreground size-4" />
        <span>Filter</span>
      </div>
      {filterButtonsData.map((filterButtonData) => (
        <Button
          key={filterButtonData.id}
          variant="ghost"
          className={cn(
            filterTitle === filterButtonData.title && "bg-violet-100 text-violet-700",
            "h-8 p-2 text-xs",
          )}
          onClick={() =>
            setFilterOptionHandler(filterButtonData.days, filterButtonData.title)
          }
        >
          {filterButtonData.title}
        </Button>
      ))}
    </div>
  )
}
