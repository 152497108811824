import { useQuery } from "@tanstack/react-query"
import { useRouterState } from "@tanstack/react-router"

import { graphql } from "#gql"
import { client } from "#graphql-client"

const ClientDocument = graphql(/* GraphQL */ `
  query ClientGetName($input: QueryClientInput!) {
    client(input: $input) {
      name
    }
  }
`)

export const StandardHeader = () => {
  const [clientId, category] = useRouterState({
    select: (s) => s.location.pathname.split("/clients")[1]?.split("/").slice(1),
  })

  const { data } = useQuery({
    queryKey: ["clientGetName"],
    queryFn: () => client.request(ClientDocument, { input: { clientId } }),
    enabled: !!clientId,
  })

  const clientsMenuTitle = `${data?.client.name} \\ ${category[0].toUpperCase()}${category.slice(1)}`

  return (
    <div className="flex h-16 w-full items-center justify-between border-b border-gray-200 bg-gray-50 p-4 px-6 dark:bg-gray-950">
      <h1 className="text-xl font-bold md:text-2xl">{clientsMenuTitle}</h1>
    </div>
  )
}
