import { Route } from "@tanstack/react-router"

import { clientsRoute } from "#pages/clients/index.js"

export const Triggers = () => {
  return (
    <div className="flex flex-col">
      <p>Triggers</p>
    </div>
  )
}

export const clientsTriggersRoute = new Route({
  getParentRoute: () => clientsRoute,
  path: "$client/triggers",
  component: Triggers,
})
