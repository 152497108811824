import type { VariablesOf } from "@graphql-typed-document-node/core"
import { useMutation } from "@tanstack/react-query"
import { Link } from "@tanstack/react-router"
import CheckCheck from "lucide-static/icons/check-check.svg"
import Check from "lucide-static/icons/check.svg"
import InlineSVG from "react-inlinesvg"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  toast,
  ToastAction,
} from "@fourel/ui"

import { OfferStatus } from "#components/offers/components/offer-status.js"
import type {
  OrganizationOffer,
  StatusType,
} from "#components/offers/models/offer-types.js"
import { graphql } from "#gql"
import { client } from "#graphql-client"
import { QuoteLegs } from "#pages/offers/components/quote-legs.js"
import { queryClient } from "#query-client"
import { useOnboardedUserInfo } from "#store/user-info.js"

const FlightCreateFromCombinedTripDocument = graphql(/* GraphQL */ `
  mutation FlightCreateFromCombinedTrip(
    $input: MutationFlightCreateFromCombinedTripInput!
  ) {
    flightCreateFromCombinedTrip(input: $input)
  }
`)

const OfferUpdateStatusDocument = graphql(/* GraphQL */ `
  mutation OfferUpdateStatus($input: MutationOfferUpdateStatusInput!) {
    offerUpdateStatus(input: $input) {
      __typename
    }
  }
`)

export const Offer = ({
  status,
  id,
  quotes,
  expiresAt,
  trip,
  optionId,
}: OrganizationOffer) => {
  const { currentOrg } = useOnboardedUserInfo()

  const { mutate: createFlights } = useMutation({
    mutationFn: (variables: VariablesOf<typeof FlightCreateFromCombinedTripDocument>) =>
      client.request(FlightCreateFromCombinedTripDocument, variables),
    onSuccess: () => {
      toast({
        title: "Success!",
        description: "Flights created successfully",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async (error) => {
      console.info(error)
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while creating flights",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const { mutate } = useMutation({
    mutationFn: (variables: VariablesOf<typeof OfferUpdateStatusDocument>) =>
      client.request(OfferUpdateStatusDocument, variables),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["organizationOffersByStatusApproved"],
      })
      createFlights({ input: { combinedTripId: optionId!, tripId: trip.id } })
      toast({
        title: "Success!",
        description: "Offer is being signed",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async (error) => {
      console.info(error)
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while signing offer",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  return (
    <div className="flex flex-col items-start justify-between gap-2 border-b p-4">
      <div className="flex w-full items-center justify-between pl-1 text-sm text-slate-900">
        {trip.client!.name!}{" "}
        <OfferStatus status={status as StatusType} pendingTime={expiresAt} />
      </div>
      <div className="flex w-full flex-col gap-2">
        {quotes.map((quote, index) => (
          <div key={quote.id}>
            <Accordion type="multiple" className="rounded-md border">
              <AccordionItem value={quote.id} className="border-b-0">
                <AccordionTrigger className="bg-slate-100 p-2 hover:no-underline">
                  <div className="flex w-1/2 items-center justify-between">
                    <span>Quote {status === "pending" && index + 1}</span>
                    <span className="text-sm text-black/60">
                      {quote.price} {quote.currency}
                    </span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="overflow-hidden bg-slate-100 px-2">
                  <QuoteLegs legs={quote.quoteLegs} />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        ))}
      </div>

      <div className="flex w-full flex-wrap items-center gap-2">
        <Link
          to="/$slug/offers/preview-offer/$offerId/"
          params={{ slug: currentOrg.slug, offerId: id }}
          search={{ preview: true, bookManually: false }}
        >
          <Button variant="outline" className="w-max">
            Offer preview
          </Button>
        </Link>

        {status === "pending" && (
          <Link
            to="/$slug/offers/preview-offer/$offerId/"
            params={{ slug: currentOrg.slug, offerId: id }}
            search={{ preview: true, bookManually: true }}
          >
            <Button
              variant="outline"
              className="flex w-max items-center gap-2 bg-violet-800 text-white hover:bg-violet-700 hover:text-white"
            >
              <InlineSVG src={Check} className="size-4" />
              Book manually
            </Button>
          </Link>
        )}

        {status === "approved" && (
          <Button
            onClick={() => {
              mutate({ input: { offerId: id, status: "signed" } })
            }}
            variant="outline"
            className="flex w-max items-center gap-2 bg-violet-800 text-white hover:bg-violet-700 hover:text-white"
          >
            <InlineSVG src={CheckCheck} className="size-4" />
            Sign Offer
          </Button>
        )}
      </div>
    </div>
  )
}
