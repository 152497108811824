import { create } from "zustand"

type IntegrationStore = {
  hubspot: string
  salesforce: string
  gmail: string
  outlook: string
  salesforceCodeVerifier: string
  setIntegration: (
    key: "hubspot" | "salesforce" | "gmail" | "outlook",
    value: string,
  ) => void
  removeIntegration: (key: "hubspot" | "salesforce" | "gmail" | "outlook") => void
  setSalesforceCodeVerifier: (value: string) => void
  removeSalesforceCodeVerifier: () => void
}

export const useIntegrationStore = create<IntegrationStore>((set) => ({
  hubspot: localStorage.getItem("hubspot") ?? "",
  salesforce: localStorage.getItem("salesforce") ?? "",
  gmail: localStorage.getItem("gmail") ?? "",
  outlook: localStorage.getItem("outlook") ?? "",
  salesforceCodeVerifier: localStorage.getItem("salesforceCodeVerifier") ?? "",
  setIntegration: (
    key: "hubspot" | "salesforce" | "gmail" | "outlook",
    value: string,
  ) => {
    set((state) => ({ ...state, [key]: value }))
    localStorage.setItem(key, value)
  },
  removeIntegration: (key: "hubspot" | "salesforce" | "gmail" | "outlook") => {
    set((state) => ({ ...state, [key]: "" }))
    localStorage.removeItem(key)
  },
  setSalesforceCodeVerifier: (value: string) => {
    set((state) => ({ ...state, salesforceCodeVerifier: value }))
    localStorage.setItem("salesforceCodeVerifier", value)
  },
  removeSalesforceCodeVerifier: () => {
    set((state) => ({ ...state, salesforceCodeVerifier: "" }))
    localStorage.removeItem("salesforceCodeVerifier")
  },
}))
