import React from "react"

import { SpinningLogo } from "#components/spinning-logo.js"

export const OfferSpinningLogo = () => {
  return (
    <div className="flex h-[calc(100vh-176px)] items-center justify-center md:h-[calc(100vh-64px)]">
      <SpinningLogo />
    </div>
  )
}
