import LayersIcon from "lucide-static/icons/layers.svg"
import InlineSVG from "react-inlinesvg"

import { Button } from "@fourel/ui"

import { useAircraftStore } from "#components/quotations/create-quote/create-quote-store.js"
import type { QuoteFormValuesType } from "#components/quotations/create-quote/models/trip-types.js"

interface DuplicateAircraftProps {
  legIndex: number
  setValue: (
    field:
      | `legs.${number}.operatorName`
      | `legs.${number}.operatorId`
      | `legs.${number}.aircraft`,

    value: string | { id: string; model: string; registration: string },
  ) => void
  getValues: () => QuoteFormValuesType
  revalidate: (name: `legs.${number}.aircraft`) => void
}

export const DuplicateAircraft = ({
  getValues,
  legIndex,
  setValue,
  revalidate,
}: DuplicateAircraftProps) => {
  const { aircraftToLegData, overwriteAircraftToLegData } = useAircraftStore()
  const duplicateAircraftHandler = () => {
    const { legs } = getValues() as QuoteFormValuesType
    const currentLegForm = legs[legIndex]
    legs.forEach((_, index) => {
      setValue(`legs.${index}.operatorName`, currentLegForm.operatorName)
      setValue(`legs.${index}.operatorId`, currentLegForm.operatorId)
      setValue(`legs.${index}.aircraft`, {
        id: currentLegForm.aircraft.id,
        model: currentLegForm.aircraft.model,
        registration: currentLegForm.aircraft.registration,
      })
      const currentLegStore = aircraftToLegData[legIndex]
      overwriteAircraftToLegData(currentLegStore)
      revalidate(`legs.${index}.aircraft`)
    })
  }

  return (
    <Button
      type="button"
      variant="outline"
      className="w-1/2 bg-violet-400/30 p-0 text-xs"
      onClick={() => duplicateAircraftHandler()}
    >
      <span>Duplicate Aircraft</span>
      <InlineSVG src={LayersIcon} className="ml-2 h-4 w-4" />
    </Button>
  )
}
