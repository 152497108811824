import { useQuery } from "@tanstack/react-query"
import type { UseFormReturn } from "react-hook-form"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import type { FlightFollowUpListFormType } from "#pages/flights/flights-list/flights-list.js"

import { ModalHost } from "./modal-host.js"

const FlightDocument = graphql(/* GraphQL */ `
  query FlightHost($input: QueryFlightInput!) {
    flight(input: $input) {
      id
      departureAirportFlightHost {
        id
        name
        phone
      }
      arrivalAirportFlightHost {
        id
        name
        phone
      }
      quoteLeg {
        departureAirport {
          icaoCode
          iataCode
        }
        arrivalAirport {
          icaoCode
          iataCode
        }
      }
    }
  }
`)

export const AccordionFormHost = ({
  flightId,
  form,
  flightIndex,
}: {
  flightId: string
  form: UseFormReturn<FlightFollowUpListFormType>
  flightIndex: number
}) => {
  const { data } = useQuery({
    queryKey: ["FlightHost", flightId],
    queryFn: () =>
      client.request(FlightDocument, {
        input: { flightId: flightId! },
      }),
    enabled: !!flightId,
  })

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2 text-lg font-bold">
        <span>Host</span>
        <ModalHost
          form={form}
          flightId={flightId}
          flightIndex={flightIndex}
          isEditMode={!!data?.flight.departureAirportFlightHost?.id}
        />
      </div>

      <div className="flex items-center gap-4">
        <span className="text-muted-foreground text-xs font-semibold">
          {data?.flight.quoteLeg.departureAirport.icaoCode ||
            data?.flight.quoteLeg.departureAirport.iataCode}
        </span>
        <div className="flex max-w-96 items-center gap-2 text-xs">
          <p>{data?.flight.departureAirportFlightHost?.name || "-"}</p>
          <p>
            {data?.flight.departureAirportFlightHost?.phone
              ? `(${data?.flight.departureAirportFlightHost?.phone})`
              : ""}
          </p>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <span className="text-muted-foreground text-xs font-semibold">
          {data?.flight.quoteLeg.arrivalAirport.icaoCode ||
            data?.flight.quoteLeg.arrivalAirport.iataCode}
        </span>
        <div className="flex max-w-96 gap-2 text-xs">
          <p>{data?.flight.arrivalAirportFlightHost?.name || "-"}</p>
          <p>
            {data?.flight.arrivalAirportFlightHost?.phone
              ? `(${data?.flight.arrivalAirportFlightHost?.phone})`
              : ""}
          </p>
        </div>
      </div>
    </div>
  )
}
