import type { VariablesOf } from "@graphql-typed-document-node/core"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect } from "react"

import { Button, toast, ToastAction } from "@fourel/ui"

import { Config } from "#config-fe.js"
import { graphql } from "#gql"
import { client } from "#graphql-client.js"
import { useIntegrationStore } from "#pages/integration/store.js"

import HubSpotLogo from "../icons/hubspot.png"

const CreateOrganizationHubSpotTokenDocument = graphql(/* GraphQL */ `
  mutation CreateUserHubSpotToken($input: MutationCreateOrganizationHubSpotTokenInput!) {
    createOrganizationHubSpotToken(input: $input)
  }
`)

const IsOrganizationConnectedToHubSpotDocument = graphql(/* GraphQL */ `
  query IsOrganizationConnectedToHubSpot {
    isOrganizationConnectedToHubSpot
  }
`)

export const Hubspot = () => {
  const { hubspot, removeIntegration } = useIntegrationStore()

  const { mutate, isSuccess } = useMutation({
    mutationFn: (variables: VariablesOf<typeof CreateOrganizationHubSpotTokenDocument>) =>
      client.request(CreateOrganizationHubSpotTokenDocument, variables),
    onSuccess: () => {
      toast({
        title: "Success!",
        description: "Integration with HubSpot has been successful.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while integrating with HubSpot.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const { data } = useQuery({
    queryKey: ["isUserConnectedToHubSpot"],
    queryFn: () => client.request(IsOrganizationConnectedToHubSpotDocument),
  })

  const hubspotHandler = () => {
    window.location.href = `https://app-eu1.hubspot.com/oauth/authorize?client_id=${Config.VITE_HUBSPOT_CLIENT_ID}&redirect_uri=${Config.VITE_HUBSPOT_REDIRECT_URL}&scope=crm.objects.contacts.read%20crm.objects.contacts.write`
  }

  useEffect(() => {
    if (hubspot) {
      void mutate({ input: { code: hubspot } })
    }
  }, [mutate, hubspot])

  useEffect(() => {
    if (isSuccess) {
      removeIntegration("hubspot")
    }
  }, [isSuccess, removeIntegration])

  return (
    <div className="flex w-1/4 flex-col items-center justify-center gap-2 border border-gray-300 p-5">
      <img src={HubSpotLogo} alt="HubSpotLogo" className="h-28" />
      <span>HubSpot</span>
      <Button
        disabled={data?.isOrganizationConnectedToHubSpot}
        onClick={hubspotHandler}
        className="w-full bg-violet-900 p-0 hover:bg-violet-500"
      >
        {data?.isOrganizationConnectedToHubSpot ? "Connected" : "Connect"}
      </Button>
    </div>
  )
}
