import { useMutation, useQueryClient } from "@tanstack/react-query"

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenuItem,
  ToastAction,
  useToast,
} from "@fourel/ui"

import { graphql } from "#gql"
import type { MutationDeleteUserInput } from "#gql/graphql"
import { client } from "#graphql-client"

const DeleteUserDocument = graphql(/* GraphQL */ `
  mutation DeleteUser($input: MutationDeleteUserInput!) {
    deleteUser(input: $input)
  }
`)

export const DeleteUser = ({
  userId,
  organizationId,
}: {
  userId: string
  organizationId: string
}) => {
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const deleteUser = useMutation({
    mutationFn: (input: MutationDeleteUserInput) =>
      client.request(DeleteUserDocument, { input }),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["OrganizationUsers"] })
      toast({
        title: "User deleted!",
        description: "User has been deleted.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error",
        description: "An error occurred while deleting the user.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  return (
    <Dialog>
      <DialogTrigger className="w-full">
        <DropdownMenuItem className="w-full">
          <span>Delete user</span>
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete User</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete this user?
          </DialogDescription>
        </DialogHeader>
        <DialogTrigger
          className="mx-auto w-max"
          asChild
          onClick={() => {
            void deleteUser.mutateAsync({ userId, organizationId })
          }}
        >
          <Button className="w-full" variant="destructive">
            Delete
          </Button>
        </DialogTrigger>
        <DialogTrigger className="mx-auto w-max" asChild>
          <Button className="w-full">Cancel</Button>
        </DialogTrigger>
      </DialogContent>
    </Dialog>
  )
}
