import ChevronsUpDownIcon from "lucide-static/icons/chevrons-up-down.svg"
import { useState } from "react"
import type { ControllerRenderProps } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
  Popover,
  PopoverContentCombobox,
  PopoverTrigger,
} from "@fourel/ui"

import type { AircraftFormType } from "../create-aircraft-modal.js"

interface AircraftOperatorSelectProps {
  field: ControllerRenderProps<AircraftFormType, "operatorName">
  setValue: (field: "operatorName" | "operatorId", value: string) => void
  fetchedOperator: {
    id: string
    name: string
  }[]
  revalidate: (name: "operatorName") => void
}

export const AircraftOperatorSelect = ({
  field,
  setValue,
  fetchedOperator,
  revalidate,
}: AircraftOperatorSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOperatorSelect = (operatorName: string) => {
    setValue("operatorName", operatorName)
    const selectedOperatorId = fetchedOperator.find((o) => o.name === operatorName)?.id
    setValue("operatorId", selectedOperatorId!)
    setIsOpen(false)
    void revalidate("operatorName")
  }
  return (
    <FormItem className="w-full">
      <div className="flex items-center justify-between gap-2">
        <FormLabel className="w-1/2 text-xs dark:text-slate-200">Operator Name</FormLabel>
        <Popover modal open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild>
            <FormControl>
              <Button
                variant="outline"
                role="combobox"
                className={cn(
                  "w-full cursor-pointer justify-between text-xs",
                  !field.value && "text-muted-foreground",
                )}
              >
                {field.value
                  ? fetchedOperator.find((o) => o.name === field.value)?.name
                  : "Select operator"}
                <InlineSVG
                  src={ChevronsUpDownIcon}
                  className="ml-2 size-4 shrink-0 opacity-50"
                />
              </Button>
            </FormControl>
          </PopoverTrigger>
          <PopoverContentCombobox className="w-full p-0">
            <Command>
              <CommandInput className="text-xs" placeholder="Search Operator" />
              <CommandEmpty className="p-4 text-xs">No operator found.</CommandEmpty>
              <CommandList className="max-h-60">
                <CommandGroup>
                  {fetchedOperator.map((o) => (
                    <CommandItem
                      value={o.name}
                      key={o.id}
                      onSelect={() => handleOperatorSelect(o.name)}
                      className="cursor-pointer text-xs"
                    >
                      {o.name}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContentCombobox>
        </Popover>
      </div>
      <FormMessage className="text-xs" />
    </FormItem>
  )
}
