import { useRouterState } from "@tanstack/react-router"
import { useEffect, useState } from "react"

import { useMenuStore } from "#pages/lobby/menu-store.js"

export const useTableResize = () => {
  const { isOpenMenu, isSubmenuOpen } = useMenuStore()
  const [containerWidth, setContainerWidth] = useState("100%")
  const pathSegment = useRouterState({
    select: (s) => s.location.pathname.split("/").slice(-1)[0] as string,
  })
  const MENU_FULL_WIDTH = 320
  const MENU_SMALL_WIDTH = 80
  const SUBMENU_FULL_WIDTH = pathSegment === "clients" ? 4 : 225
  const SUBMENU_SMALL_WIDTH = 4

  useEffect(() => {
    const calcTableWidth = () => {
      const menuDrawerWidth = isOpenMenu ? MENU_FULL_WIDTH : MENU_SMALL_WIDTH
      const submenuDrawerWidth = isSubmenuOpen ? SUBMENU_FULL_WIDTH : SUBMENU_SMALL_WIDTH
      const availableWidth = window.innerWidth - menuDrawerWidth - submenuDrawerWidth - 48
      setContainerWidth(
        availableWidth > 0 && window.innerWidth >= 768 ? `${availableWidth}px` : "100%",
      )
    }
    window.addEventListener("resize", calcTableWidth)
    calcTableWidth()
    return () => window.removeEventListener("resize", calcTableWidth)
  }, [SUBMENU_FULL_WIDTH, isOpenMenu, isSubmenuOpen])
  return containerWidth
}
