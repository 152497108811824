import { Route } from "@tanstack/react-router"

import { sluggedRoute } from "#router.js"

import { Integration } from "./integration.js"

export const integrationRoute = new Route({
  getParentRoute: () => sluggedRoute,
  path: "/integration",
  component: Integration,
})

// lazyRouteComponent was removed to get the current location with first click on tab in menu
