import { Route } from "@tanstack/react-router"

import { flightsRoute } from "../flights-route.js"
import { FlightsList } from "./flights-list.js"
import { TableFilterHeader } from "./tables/components/table-filter-header.js"

export const flightsListRoute = new Route({
  getParentRoute: () => flightsRoute,
  path: "/list",
  component: () => (
    <div>
      <TableFilterHeader />
      <FlightsList />
    </div>
  ),
})
