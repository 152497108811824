import { Route } from "@tanstack/react-router"

import { sluggedRoute } from "#router.js"

import { Advertise } from "./advertise.js"

export const advertiseRoute = new Route({
  getParentRoute: () => sluggedRoute,
  path: "/advertise",
  component: Advertise,
})
