import { Link, useParams, useRouterState } from "@tanstack/react-router"

import { cn } from "@fourel/ui"

export type FlightsHeaderProps = {
  id?: string
}

export const FlightsSubheader = ({ id }: FlightsHeaderProps) => {
  const category = useRouterState({
    select: (s) => s.location.pathname.split("/"),
  })

  const { slug } = useParams({
    from: "/authenticated-route/onboarded-route/$slug/flights",
  })

  const tabStyles = "flex flex-col gap-2 hover:cursor-pointer"

  return (
    <div className="flex flex-wrap justify-between gap-2 font-semibold">
      <div className="flex">
        <Link className={tabStyles} to="/$slug/flights" params={{ slug }}>
          <h3 className="px-4 text-center text-base">Calendar</h3>
          <span
            className={cn(
              category.includes("flights") && !category.includes("list")
                ? "border-b-2 border-b-violet-700"
                : "border-b-2 border-b-gray-300",
            )}
          />
        </Link>
        <Link className={tabStyles} to="/$slug/flights/list" params={{ slug }}>
          <h3 className="px-4 text-center text-base">List</h3>
          <span
            className={cn(
              category.includes("list")
                ? "border-b-2 border-b-violet-700"
                : "border-b-2 border-b-gray-300",
            )}
          />
        </Link>
      </div>
    </div>
  )
}
