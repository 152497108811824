import { Route } from "@tanstack/react-router"

import { clientsRoute } from "#pages/clients/index.js"

export const Lists = () => {
  return (
    <div className="flex flex-col">
      <p>Lists</p>
    </div>
  )
}

export const clientsListsRoute = new Route({
  getParentRoute: () => clientsRoute,
  path: "$client/lists",
  component: Lists,
})
