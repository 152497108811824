import { useQuery } from "@tanstack/react-query"
import type { UseFormReturn } from "react-hook-form"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import type { FlightFollowUpListFormType } from "#pages/flights/flights-list/flights-list.js"

import { ModalResponsible } from "./modal-responsible.js"

const FlightsListResponsibleDocument = graphql(/* GraphQL */ `
  query FlightsListResponsible($input: QueryFlightInput!) {
    flight(input: $input) {
      id
      flightBroker {
        id
        name
      }
      flightBrokerAssistance {
        id
        name
      }
      flightSupports {
        id
        name
      }
      quoteLeg {
        aircraft {
          operator {
            id
            name
          }
        }
      }
    }
  }
`)

const renderNames = (items: { name: string }[]) => {
  return items.length > 0 ? items.map((it: { name: string }) => it.name).join(", ") : "-"
}

export const AccordionFormResponsible = ({
  flightId,
  form,
  flightIndex,
}: {
  flightId: string
  form: UseFormReturn<FlightFollowUpListFormType>
  flightIndex: number
}) => {
  const { data } = useQuery({
    queryKey: ["FlightResponsible", flightId],
    queryFn: () =>
      client.request(FlightsListResponsibleDocument, {
        input: { flightId: flightId! },
      }),
    enabled: !!flightId,
  })

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex items-center gap-2 text-lg font-bold">
        <span className="text-lg font-bold">Responsible</span>
        <ModalResponsible form={form} flightId={flightId} flightIndex={flightIndex} />
      </div>
      <div className="flex items-center gap-4">
        <span className="text-xs font-semibold text-slate-500">Broker</span>
        <span className="text-xs">{data?.flight.flightBroker.name}</span>
      </div>
      <div className="flex items-center gap-4">
        <span className="w-fit text-xs font-semibold text-slate-500">
          Broker Assistant
        </span>
        <span className="text-xs">
          {renderNames(data?.flight.flightBrokerAssistance || [])}
        </span>
      </div>
      <div className="flex items-center gap-4">
        <span className="text-xs font-semibold text-slate-500">Support</span>
        <span className="text-xs">{renderNames(data?.flight.flightSupports || [])}</span>
      </div>
    </div>
  )
}
