import React from "react"
import type { UseFormReturn } from "react-hook-form"

import { cn, FormControl, FormField, FormItem, FormMessage } from "@fourel/ui"

import type { FlightsOrganizationByDateQuery } from "#gql/graphql.js"
import { useAccordionStore, useTableStateStore } from "#pages/flights/flights-store.js"
import type { SelectOptionType } from "#pages/flights/models/flight-follow-up-types.js"

import { AccordionContentPreparation } from "../accordion/accordion-content-preparation.js"
import type { FlightFollowUpListFormType } from "../flights-list.js"
import { FlightsListSelect } from "./components/select.js"
import { TableExpandHeader } from "./components/table-expand-header.js"
import { TableStateTag } from "./components/table-state-tag.js"

type PreparationFlightFieldNames =
  | "depArrConfirmation"
  | "pax"
  | "flightCatering"
  | "transfer"
  | "pprForCar"

interface TableFieldConfig {
  fieldName: PreparationFlightFieldNames
  options: SelectOptionType[]
}

const tableSubheaderData = [
  { title: "AOC Dep / ARR Time" },
  { title: "Pax ID" },
  { title: "Catering" },
  { title: "Transfer" },
  { title: "PPR Car" },
]

const aocArrStatusOptions: SelectOptionType[] = [
  { field: "Not selected", value: "not_selected", default: true },
  { field: "Confirmed", value: "confirmed", default: false },
  { field: "Need update", value: "need_update", default: false },
]

const paxIdOptions: SelectOptionType[] = [
  { field: "Requested", value: "requested", default: false },
  { field: "Received", value: "received", default: false },
  { field: "Sent", value: "sent", default: false },
]

const cateringOptions: SelectOptionType[] = [
  { field: "Not selected", value: "not_selected", default: true },
  { field: "Not required", value: "not_required", default: false },
  { field: "Pending", value: "pending", default: false },
  { field: "Confirmed", value: "confirmed", default: false },
  { field: "Request sent to pax", value: "req_sent_to_pax", default: false },
  { field: "Received from pax", value: "received_from_pax", default: false },
  { field: "Request sent to operator", value: "req_sent_to_operator", default: false },
  { field: "Confirmed by operator", value: "confirmed_by_operator", default: false },
]

const transferOptions: SelectOptionType[] = [
  { field: "Not selected", value: "not_selected", default: true },
  { field: "Not required", value: "not_required", default: false },
  { field: "Requested", value: "requested", default: false },
  { field: "Sent to operator", value: "sent_to_operator", default: false },
  { field: "Confirmed by operator", value: "confirmed_by_operator", default: false },
]

const pprCarOptions: SelectOptionType[] = [
  { field: "Not selected", value: "not_selected", default: true },
  { field: "Not required", value: "not_required", default: false },
  { field: "Requested", value: "requested", default: false },
  { field: "Confirmed", value: "confirmed", default: false },
]

const tableFieldsConfig: TableFieldConfig[] = [
  { fieldName: "depArrConfirmation", options: aocArrStatusOptions },
  { fieldName: "pax", options: paxIdOptions },
  { fieldName: "flightCatering", options: cateringOptions },
  { fieldName: "transfer", options: transferOptions },
  { fieldName: "pprForCar", options: pprCarOptions },
]

export const TablePreparation = ({
  flightData,
  form,
}: {
  flightData: FlightsOrganizationByDateQuery["flightsOrganizationByDate"]
  form: UseFormReturn<FlightFollowUpListFormType>
}) => {
  const { isPreparationTableExpanded } = useTableStateStore()
  const { getAccordionState, toggleAccordion } = useAccordionStore()

  return (
    <div>
      <TableExpandHeader tableName="Preparation" />

      <div
        className={`overflow-hidden ${isPreparationTableExpanded ? "w-full" : "w-auto"}`}
      >
        <table className={cn(!isPreparationTableExpanded && "w-52")}>
          <thead className="h-[35px]">
            <tr className="text-muted-foreground border-r text-xs font-bold">
              {isPreparationTableExpanded
                ? tableSubheaderData.map((item, index) => (
                    <th key={index} className="font-medium">
                      {item.title}
                    </th>
                  ))
                : null}
            </tr>
          </thead>

          <tbody>
            {flightData.length &&
              flightData.map((flight, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={cn(
                      (index + 1) % 2 === 0 && "bg-blue-300/10",
                      "h-[60px] border border-l-0 text-center text-sm font-semibold",
                    )}
                    onClick={() => toggleAccordion(flight.id)}
                  >
                    {isPreparationTableExpanded ? (
                      tableFieldsConfig.map(({ fieldName, options }) => (
                        <td key={fieldName}>
                          <FormField
                            control={form.control}
                            name={`preparationTableLegs.${index}.${fieldName}`}
                            render={() => (
                              <FormItem className="relative w-32 space-y-0 p-2">
                                <FormControl>
                                  <FlightsListSelect
                                    form={form}
                                    options={options}
                                    fieldName={`preparationTableLegs.${index}.${fieldName}`}
                                    flightId={flight.id}
                                    flightDocumentsId={flight.flightDocuments?.id}
                                    flightPreparationId={flight.flightPreparation?.id}
                                    preFlightId={flight.preFlight?.id}
                                    flightCateringId={
                                      flight.flightPreparation?.flightCatering[0]?.id
                                    }
                                    index={index}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </td>
                      ))
                    ) : (
                      <td className="flex min-w-36 justify-center p-2">
                        <TableStateTag
                          fieldName={`preparationTableLegs.${index}`}
                          form={form}
                        />
                      </td>
                    )}
                  </tr>
                  {getAccordionState(flight.id)?.isExpanded && (
                    <tr>
                      <td
                        colSpan={tableSubheaderData.length}
                        className={cn(
                          (index + 1) % 2 === 0 && "bg-blue-300/10",
                          "h-[200px] p-4 align-top",
                          index + 1 === flightData.length && "border-b",
                        )}
                      >
                        <AccordionContentPreparation
                          form={form}
                          flightIndex={index}
                          flightId={flight.id!}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
