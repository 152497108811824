import type { VariablesOf } from "@graphql-typed-document-node/core"
import { useMutation } from "@tanstack/react-query"
import PenLine from "lucide-static/icons/pen-line.svg"
import type { UseFormReturn } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ToastAction,
  useToast,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client.js"
import { queryClient } from "#query-client.js"

import type { FlightFollowUpListFormType } from "../../flights-list.js"

const FlightsListHostsUpdateDocument = graphql(/* GraphQL */ `
  mutation FlightsListHostsUpdate($input: MutationFlightHostsUpdateInput!) {
    flightHostsUpdate(input: $input) {
      id
    }
  }
`)

const FlightsListHostCreateDocument = graphql(/* GraphQL */ `
  mutation FlightsListHostCreate($input: MutationFlightHostCreateInput!) {
    flightHostCreate(input: $input) {
      id
    }
  }
`)

export const ModalHost = ({
  form,
  flightId,
  flightIndex,
  isEditMode,
}: {
  form: UseFormReturn<FlightFollowUpListFormType>
  flightIndex: number
  flightId: string
  isEditMode: boolean
}) => {
  const { toast } = useToast()

  const { mutate: mutationUpdateHost } = useMutation({
    mutationFn: (variables: VariablesOf<typeof FlightsListHostsUpdateDocument>) =>
      client.request(FlightsListHostsUpdateDocument, variables),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["FlightHost"] })
      toast({
        title: "Success!",
        description: "Flight host has been updated successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while updating the Flight host.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })
  const { mutate: mutationCreateHost } = useMutation({
    mutationFn: (variables: VariablesOf<typeof FlightsListHostCreateDocument>) =>
      client.request(FlightsListHostCreateDocument, variables),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["FlightHost"] })
      toast({
        title: "Success!",
        description: "Flight host has been created successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while creating the Flight host.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const onSubmitHostHandler = () => {
    void form.handleSubmit((values: FlightFollowUpListFormType) => {
      const data = values.flightHost[flightIndex]
      if (isEditMode) {
        mutationUpdateHost({
          input: {
            arrivalHost: {
              name: data.arrivalHost.name,
              phone: data.arrivalHost.phone,
            },
            departureHost: {
              name: data.departureHost.name,
              phone: data.departureHost.phone,
            },
            arrivalHostId: data.arrivalHost.id!,
            departureHostId: data.departureHost.id!,
          },
        })

        return
      }
      mutationCreateHost({
        input: {
          arrivalHost: {
            name: data.arrivalHost.name,
            phone: data.arrivalHost.phone,
          },
          departureHost: {
            name: data.departureHost.name,
            phone: data.departureHost.phone,
          },
          flightId,
        },
      })
    })()
  }

  return (
    <Dialog>
      <DialogTrigger className="w-max cursor-pointer">
        <Button className="flex size-8 items-center p-2" asChild variant="outline">
          <span className="flex w-max items-center">
            <InlineSVG src={PenLine} className="size-4 text-slate-500" />
          </span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{isEditMode ? "Edit Flight Host" : "Add Flight Host"}</DialogTitle>
        </DialogHeader>
        <div>
          <div className="mb-6 flex flex-col gap-2">
            <span className="font-bold">Departure Flight Host</span>
            <FormField
              control={form.control}
              name={`flightHost.${flightIndex}.departureHost.name`}
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <div className="flex items-center">
                    <FormLabel className="w-1/2">Name</FormLabel>
                    <FormControl>
                      <Input {...field} value={field.value || ""} />
                    </FormControl>
                  </div>
                  <FormMessage className="self-center pl-5 text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`flightHost.${flightIndex}.departureHost.phone`}
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <div className="flex items-center">
                    <FormLabel className="w-1/2">Phone</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9]/g, "")
                          field.onChange(value)
                        }}
                      />
                    </FormControl>
                  </div>
                  <FormMessage className="self-center pl-5 text-xs" />
                </FormItem>
              )}
            />
          </div>
          <div className="mb-6 flex flex-col gap-2">
            <span className="font-bold">Arrival Flight Host</span>
            <FormField
              control={form.control}
              name={`flightHost.${flightIndex}.arrivalHost.name`}
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <div className="flex items-center">
                    <FormLabel className="w-1/2">Name</FormLabel>
                    <FormControl>
                      <Input {...field} value={field.value || ""} />
                    </FormControl>
                  </div>
                  <FormMessage className="self-center pl-5 text-xs" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`flightHost.${flightIndex}.arrivalHost.phone`}
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <div className="flex items-center">
                    <FormLabel className="w-1/2">Phone</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9]/g, "")
                          field.onChange(value)
                        }}
                      />
                    </FormControl>
                  </div>
                  <FormMessage className="self-center pl-5 text-xs" />
                </FormItem>
              )}
            />
          </div>
          <div className="flex gap-4">
            <DialogTrigger type="button" className="w-max" asChild>
              <Button variant="outline">Cancel</Button>
            </DialogTrigger>
            <DialogClose asChild>
              <Button
                type="submit"
                className="bg-violet-700"
                disabled={!form.formState.isValid}
                onClick={() => onSubmitHostHandler()}
              >
                Save
              </Button>
            </DialogClose>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
