import InlineSVG from "react-inlinesvg"

import { SeparatorWithChildren } from "@fourel/ui"

import type { QuoteLegOffer } from "#components/offers/models/offer-types.js"
import { getTimeDifference } from "#pages/offers/preview-offer/helpers/index.js"
import PlaneIcon from "#pages/quotations/combined-quotes/icons/plane.svg"

const formatCustomDateString = (dateString: string) => {
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ]

  const date = new Date(dateString.replace(" ", "T"))

  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear()

  return `${day} ${month} ${year}`
}

export const QuoteLegs = ({ legs }: { legs: QuoteLegOffer[] }) => {
  return (
    <div className="flex flex-col gap-2 lg:flex-row">
      {legs.map((leg, index) => (
        <div className="flex w-full items-end justify-between gap-4 md:pr-2" key={leg.id}>
          <div className="flex flex-col gap-1">
            <span className="block pb-2 text-sm text-slate-500">DEP</span>
            <h3 className="text-lg font-semibold">
              {leg.departureAirport.icaoCode || leg.departureAirport.iataCode}
            </h3>
            <span className="text-nowrap text-xs text-slate-500">
              {formatCustomDateString(leg.departureDate)}
            </span>
            <span className="text-xs text-slate-500">
              {leg.estimatedTimeOfDeparture.slice(0, 5)}
            </span>
          </div>
          <div className="w-full">
            <div className="w-full">
              <SeparatorWithChildren className="w-full bg-slate-500">
                <span className="flex -translate-y-[31%] transform flex-col items-center justify-start gap-2">
                  {getTimeDifference({
                    endTime: leg.estimatedTimeOfArrival,
                    startTime: leg.estimatedTimeOfDeparture,
                    startDateTime: leg.departureDate,
                    endDateTime: leg.arrivalDate,
                  })}
                  <InlineSVG src={PlaneIcon} />
                </span>
              </SeparatorWithChildren>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <span className="block pb-2 text-sm text-slate-500">ARR</span>
            <h3 className="text-lg font-semibold">
              {leg.arrivalAirport.icaoCode || leg.arrivalAirport.iataCode}
            </h3>
            <span className="text-nowrap text-xs text-slate-500">
              {formatCustomDateString(leg.arrivalDate)}
            </span>
            <span className="text-xs text-slate-500">
              {leg.estimatedTimeOfArrival.slice(0, 5)}
            </span>
          </div>
        </div>
      ))}
    </div>
  )
}
