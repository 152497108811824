import { Route } from "@tanstack/react-router"

import { sluggedRoute } from "#router.js"

export const Payments = () => {
  return <div className="mt-5 flex w-1/4 justify-center">Payments</div>
}

export const paymentsRoute = new Route({
  getParentRoute: () => sluggedRoute,
  path: "/payments",
  component: Payments,
})
