import { Outlet } from "@tanstack/react-router"

import { FlightsDateSelect } from "./components/flights-date-select.js"
import { FlightsHeader } from "./components/flights-header.js"
import { FlightsSubheader } from "./components/flights-subheader.js"

export const Flights = () => {
  return (
    <div className="h-screen overflow-auto">
      <FlightsHeader />
      <div className="flex justify-between gap-2 px-6 pb-2 pt-4">
        <FlightsSubheader />
        <FlightsDateSelect />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  )
}
