import type { VariablesOf } from "@graphql-typed-document-node/core"
import { useMutation } from "@tanstack/react-query"
import PenLine from "lucide-static/icons/pen-line.svg"
import type { UseFormReturn } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ToastAction,
  useToast,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client.js"
import type { FlightFollowUpListFormType } from "#pages/flights/flights-list/flights-list.js"
import { queryClient } from "#query-client.js"

const FlightCrewArrayUpdateDocument = graphql(/* GraphQL */ `
  mutation FlightCrewArrayUpdate($input: MutationFlightCrewArrayUpdateInput!) {
    flightCrewArrayUpdate(input: $input) {
      id
    }
  }
`)

export const ModalCrew = ({
  form,
  flightIndex,
  crew,
}: {
  form: UseFormReturn<FlightFollowUpListFormType>
  flightIndex: number
  crew: {
    id: string
    name?: string | null
    phone?: string | null
    position: "pilot_in_command" | "first_officer" | "flight_attendant"
  }[]
}) => {
  const { toast } = useToast()

  const { mutate } = useMutation({
    mutationFn: (variables: VariablesOf<typeof FlightCrewArrayUpdateDocument>) =>
      client.request(FlightCrewArrayUpdateDocument, variables),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["FlightCrew"] })
      toast({
        title: "Success!",
        description: "Flight crew has been updated successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while updating the Flight crew.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const onSubmitCrewHandler = () => {
    void form.handleSubmit((values: FlightFollowUpListFormType) => {
      const currentCrewMember = values.flightCrew[flightIndex]

      void mutate({
        input: {
          members: currentCrewMember.map((it) => ({
            id: it.id,
            name: it.name,
            phone: it.phone,
            position: it.position as
              | "pilot_in_command"
              | "first_officer"
              | "flight_attendant",
          })),
        },
      })
    })()
  }

  const flightCrewFormData = form.getValues(`flightCrew.${flightIndex}`)

  return (
    <Dialog>
      <DialogTrigger className="w-max cursor-pointer">
        <Button className="flex size-8 items-center p-2" asChild variant="outline">
          <span className="flex w-max items-center">
            <InlineSVG src={PenLine} className="size-4 text-slate-500" />
          </span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Flight Crew Members</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-5">
          {flightCrewFormData &&
            flightCrewFormData.map((it, crewMemberIndex) => {
              const mappedCrewIndex =
                crew?.findIndex((member) => member.position === it.position) ??
                crewMemberIndex
              return (
                <div key={it.position} className="mb-6 flex flex-col gap-2">
                  <FormField
                    control={form.control}
                    name={`flightCrew.${flightIndex}.${mappedCrewIndex}.position`}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <div className="flex items-center">
                          <FormLabel className="flex w-1/2 gap-2">
                            Position
                            <span className="font-bold">
                              {field.value === "pilot_in_command"
                                ? "Pilot in Command"
                                : field.value === "first_officer"
                                  ? "First Officer"
                                  : "Flight Attendant"}
                            </span>
                          </FormLabel>
                        </div>
                        <FormMessage className="self-center pl-5 text-xs" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`flightCrew.${flightIndex}.${mappedCrewIndex}.name`}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel>Name</FormLabel>
                        <FormControl>
                          <Input {...field} value={field.value || ""} />
                        </FormControl>
                        <FormMessage className="self-center pl-5 text-xs" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`flightCrew.${flightIndex}.${mappedCrewIndex}.phone`}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel>Phone</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            value={field.value || ""}
                            onChange={(e) => {
                              const value = e.target.value.replace(/[^0-9]/g, "")
                              field.onChange(value)
                            }}
                          />
                        </FormControl>
                        <FormMessage className="self-center pl-5 text-xs" />
                      </FormItem>
                    )}
                  />
                </div>
              )
            })}
          <div className="flex gap-4">
            <DialogTrigger type="button" asChild>
              <Button variant="outline">Cancel</Button>
            </DialogTrigger>
            <DialogClose asChild>
              <Button
                type="button"
                className="bg-violet-700"
                disabled={!form.formState.isValid}
                onClick={() => onSubmitCrewHandler()}
              >
                Save
              </Button>
            </DialogClose>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
