import { useQuery } from "@tanstack/react-query"
import type { UseFormReturn } from "react-hook-form"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import type { FlightFollowUpListFormType } from "#pages/flights/flights-list/flights-list.js"

import { ModalCrew } from "./modal-crew.js"

const FlightDocument = graphql(/* GraphQL */ `
  query FlightCrew($input: QueryFlightInput!) {
    flight(input: $input) {
      id
      paxBrief
      crew {
        id
        name
        phone
        position
      }
    }
  }
`)

export const AccordionFormCrew = ({
  flightId,
  form,
  flightIndex,
}: {
  flightId: string
  form: UseFormReturn<FlightFollowUpListFormType>
  flightIndex: number
}) => {
  const { data } = useQuery({
    queryKey: ["FlightCrew", flightId],
    queryFn: () =>
      client.request(FlightDocument, {
        input: { flightId: flightId! },
      }),
    enabled: !!flightId,
  })

  const crewPositions = [
    { label: "PIC", position: "pilot_in_command" },
    { label: "FA", position: "flight_attendant" },
    { label: "FO", position: "first_officer" },
  ]

  const getCrewMember = (position: string) => {
    return data?.flight.crew.find((member) => member.position === position)
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 text-lg font-bold">
          <span>Crew</span>{" "}
          <ModalCrew
            flightIndex={flightIndex}
            form={form}
            crew={data?.flight.crew || []}
          />
        </div>
        {crewPositions.map(({ label, position }) => {
          const member = getCrewMember(position)
          return (
            <div className="flex items-center gap-4" key={position}>
              <span className="text-xs font-semibold text-slate-500">{label}</span>
              <div className="flex gap-2 text-xs">
                <span>
                  {member
                    ? `${member.name || ""} ${member.phone ? `(${member.phone})` : "-"}`
                    : "-"}
                </span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
