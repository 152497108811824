import { Link, useRouterState } from "@tanstack/react-router"
import ChevronsLeftIcon from "lucide-static/icons/chevrons-left.svg"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  cn,
  Command,
  CommandGroup,
  CommandItemCustom,
  CommandList,
} from "@fourel/ui"

import { useMenuStore } from "#pages/lobby/menu-store.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

export const manageItems = [
  {
    label: "Active",
    url: "",
  },
  {
    label: "Inactive",
    url: "inactive",
  },
]

export const TripRequestsSubmenu = () => {
  const { currentOrg } = useOnboardedUserInfo()
  const { isSubmenuOpen, toggleSubmenu } = useMenuStore()

  const submenuCategoryName = useRouterState({
    select: (s) => {
      const pathnameParts = s.location.pathname.split("/")
      return pathnameParts[pathnameParts.length - 1]
    },
  })

  const commandItemStyle =
    "mb-2 cursor-pointer gap-2 px-6 py-2 transition-all duration-200 ease-in-out hover:bg-violet-600/10 hover:text-violet-600 dark:hover:bg-slate-800 dark:hover:text-white"

  return (
    <div className="hidden md:block">
      <div
        className={cn(
          "relative flex h-full min-w-0 max-w-0 flex-col justify-start border-r border-gray-200 bg-gray-50 pt-4 transition-all duration-300 ease-in-out dark:bg-slate-950",
          isSubmenuOpen && "min-w-56",
        )}
      >
        <div
          className={cn("mb-8 flex items-center gap-3", !isSubmenuOpen && "m-0 gap-0")}
        >
          <Button
            variant="link"
            size="sm"
            data-testid="trips-submenu-button"
            className={cn(
              "h-8 p-2",
              !isSubmenuOpen &&
                "absolute left-0 top-4 z-50 -translate-x-1/2 bg-white p-2 transition-all duration-500 ease-in-out dark:bg-slate-600",
            )}
            onClick={toggleSubmenu}
          >
            <InlineSVG
              src={ChevronsLeftIcon}
              className={cn(
                "h-5 w-5 transition-all duration-300 ease-in-out hover:text-purple-800",
                !isSubmenuOpen && "rotate-180",
              )}
            />
          </Button>
          <span
            className={cn(
              "whitespace-nowrap text-xl font-bold tracking-wider",
              !isSubmenuOpen && "hidden text-[0px]",
            )}
          >
            Trip requests
          </span>
        </div>
        <div className="flex flex-auto overflow-hidden p-1 text-sm">
          <Command className="static w-full flex-auto overflow-y-auto bg-transparent">
            <CommandList className="flex flex-auto flex-col overflow-y-auto [&>*]:flex [&>*]:flex-auto [&>*]:flex-col">
              <CommandGroup className={cn("p-0", !isSubmenuOpen && "mr-4")}>
                {manageItems.map((item) => (
                  <Link
                    to={item.url}
                    from="/$slug/trip-requests"
                    key={item.label}
                    params={{ slug: currentOrg.slug }}
                  >
                    <CommandItemCustom
                      className={cn(
                        commandItemStyle,
                        !isSubmenuOpen && "hidden",
                        item.url.includes(submenuCategoryName) ||
                          (submenuCategoryName === "trip-requests" && item.url === "")
                          ? "bg-violet-700 text-white hover:bg-violet-600/90 hover:text-white dark:hover:bg-violet-600/90"
                          : "",
                      )}
                    >
                      <span
                        className={cn(
                          "whitespace-nowrap",
                          !isSubmenuOpen && "hidden text-[0px]",
                        )}
                      >
                        {item.label}
                      </span>
                    </CommandItemCustom>
                  </Link>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </div>
      </div>
    </div>
  )
}
