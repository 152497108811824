import type { VariablesOf } from "@graphql-typed-document-node/core"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect } from "react"

import { Button, toast, ToastAction } from "@fourel/ui"

import { Config } from "#config-fe.js"
import { graphql } from "#gql"
import { client } from "#graphql-client.js"
import { useIntegrationStore } from "#pages/integration/store.js"

import OutlookIcon from "../icons/outlook.png"

const CreateOrganizationOutlookTokenDocument = graphql(/* GraphQL */ `
  mutation CreateOrganizationOutlookToken(
    $input: MutationCreateOrganizationOutlookTokenInput!
  ) {
    createOrganizationOutlookToken(input: $input)
  }
`)

const IsOrganizationConnectedToOutlookDocument = graphql(/* GraphQL */ `
  query IsOrganizationConnectedToOutlook {
    isOrganizationConnectedToOutlook
  }
`)

export const Outlook = () => {
  const { outlook, removeIntegration } = useIntegrationStore()

  const { mutate, isSuccess } = useMutation({
    mutationFn: (variables: VariablesOf<typeof CreateOrganizationOutlookTokenDocument>) =>
      client.request(CreateOrganizationOutlookTokenDocument, variables),
    onSuccess: () => {
      toast({
        title: "Success!",
        description: "Integration with Outlook has been successful.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while integrating with Outlook.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const { data } = useQuery({
    queryKey: ["isUserConnectedToOutlook"],
    queryFn: () => client.request(IsOrganizationConnectedToOutlookDocument),
  })

  const outlookHandler = () => {
    window.location.href = `https://login.microsoftonline.com/9016ac90-f01c-4416-95ad-1509a4e67dee/oauth2/v2.0/authorize?client_id=${Config.VITE_OUTLOOK_CLIENT_ID}&response_type=code&redirect_uri=${Config.VITE_OUTLOOK_REDIRECT_URL}&response_mode=query&scope=offline_access%20user.read%20mail.read&state=12345`
  }

  useEffect(() => {
    if (outlook) {
      void mutate({ input: { code: outlook } })
    }
  }, [mutate, outlook])

  useEffect(() => {
    if (isSuccess) {
      removeIntegration("outlook")
    }
  }, [isSuccess, removeIntegration])

  return (
    <div className="flex w-1/4 flex-col items-center justify-between gap-2 border border-gray-300 p-5">
      <img src={OutlookIcon} alt="OutlookIcon" className="h-28" />
      <span>Outlook</span>
      <Button
        disabled={data?.isOrganizationConnectedToOutlook}
        onClick={outlookHandler}
        className="w-full bg-violet-900 p-0 hover:bg-violet-500"
      >
        {data?.isOrganizationConnectedToOutlook ? "Connected" : "Connect"}
      </Button>
    </div>
  )
}
