import type { VariablesOf } from "@graphql-typed-document-node/core"
import { useMutation, useQuery } from "@tanstack/react-query"
import PenLine from "lucide-static/icons/pen-line.svg"
import { useEffect, useRef, useState } from "react"
import type { UseFormReturn } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  MultiSelect,
  MultiSelectContent,
  MultiSelectEmpty,
  MultiSelectList,
  MultiSelectSearch,
  MultiSelectTrigger,
  MultiSelectValue,
  renderMultiSelectOptions,
  ToastAction,
  useToast,
  type MultiSelectOption,
  type MultiSelectOptionGroup,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client.js"
import { queryClient } from "#query-client.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

import type { FlightFollowUpListFormType } from "../../flights-list"

const OrganizationUsersDocument = graphql(/* GraphQL */ `
  query OrganizationFlightUsers($input: QueryOrganizationUsersInput!) {
    organizationUsers(input: $input) {
      id
      name
      email
      role
    }
  }
`)

const FlightSetResponsibleDocument = graphql(/* GraphQL */ `
  mutation FlightSetResponsible($input: MutationFlightSetResponsibleInput!) {
    flightSetResponsible(input: $input)
  }
`)

type Items = {
  value: string
  label: string
  group?: string
}

const groupUsers = (options: Array<Items>): MultiSelectOptionGroup[] => {
  return options.reduce((acc, item) => {
    const group = acc.find((g) => g.heading === item.group)
    if (group) {
      group.children.push(item)
    } else {
      acc.push({ heading: item.group, children: [item] })
    }
    return acc
  }, [] as MultiSelectOptionGroup[])
}

export const ModalResponsible = ({
  form,
  flightId,
  flightIndex,
}: {
  form: UseFormReturn<FlightFollowUpListFormType>
  flightIndex: number
  flightId: string
}) => {
  const { toast } = useToast()

  const [options, setOptions] = useState<MultiSelectOption[]>([])
  const [loading, setLoading] = useState(false)
  const indexRef = useRef(0)
  const [open, setOpen] = useState(false)
  const [openBroker, setOpenBroker] = useState(false)
  const [openSupport, setOpenSupport] = useState(false)

  const { currentOrg } = useOnboardedUserInfo()
  const { data } = useQuery({
    queryKey: ["OrganizationFlightUsers"],
    queryFn: () =>
      client.request(OrganizationUsersDocument, { input: { id: currentOrg.id } }),
  })

  useEffect(() => {
    if (data?.organizationUsers) {
      const usersOptions = groupUsers(
        data.organizationUsers.map((user) => ({
          value: user.id,
          label: user.name,
          group: `${user.role.slice(0, 1).toUpperCase()}${user.role.slice(1)}`,
        })),
      )
      setOptions(usersOptions)
      setOpenBroker(true)
      setOpenSupport(true)
    }
  }, [data?.organizationUsers, open])

  const handleSearch = async (keyword: string) => {
    const index = ++indexRef.current
    setLoading(true)
    const lowerKeyword = keyword.toLowerCase()
    const filtered: MultiSelectOption[] = options.filter((user) =>
      // @ts-expect-error - MultiSelectOption has a label property
      user.label.toLowerCase().includes(lowerKeyword),
    )
    if (indexRef.current === index) {
      setOptions(filtered)
      setLoading(false)
    }
  }

  const { mutate } = useMutation({
    mutationFn: (variables: VariablesOf<typeof FlightSetResponsibleDocument>) =>
      client.request(FlightSetResponsibleDocument, variables),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["FlightResponsible"] })
      toast({
        title: "Success!",
        description: "Flight Responsible has been updated successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while updating a Flight Responsible.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const onSubmitResponsibleHandler = () => {
    void form.handleSubmit((values: FlightFollowUpListFormType) => {
      const formData = values.flightAssistant[flightIndex]

      void mutate({
        input: {
          flightId,
          brokerAssistantIds: formData.brokerAssistant || [],
          flightSupportIds: formData.flightSupports || [],
        },
      })
    })()
  }

  return (
    <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
      <DialogTrigger className="w-max cursor-pointer">
        <Button className="flex size-8 items-center p-2" asChild variant="outline">
          <span className="flex w-max items-center">
            <InlineSVG src={PenLine} className="size-4 text-slate-500" />
          </span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Flight Responsible</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <FormField
              control={form.control}
              name={`flightAssistant.${flightIndex}.brokerAssistant`}
              render={({ field }) => (
                <FormItem className="flex w-full items-center gap-2">
                  <FormLabel className="w-1/3 pt-2 dark:text-slate-200">
                    Broker Assistant
                  </FormLabel>
                  <MultiSelect
                    onOpenChange={(value) => setOpenBroker(value)}
                    onValueChange={field.onChange}
                    onSearch={handleSearch}
                    open={openBroker}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <MultiSelectTrigger className="w-full cursor-pointer bg-white hover:bg-slate-100">
                        <MultiSelectValue
                          placeholder="Select User"
                          className="py-[1px]"
                        />
                      </MultiSelectTrigger>
                    </FormControl>
                    <MultiSelectContent className="max-w-full">
                      <MultiSelectSearch placeholder="Search contact" />
                      <MultiSelectList className="max-w-full">
                        {loading ? null : renderMultiSelectOptions(options)}
                        <MultiSelectEmpty>
                          {loading ? "Loading..." : "No results found"}
                        </MultiSelectEmpty>
                      </MultiSelectList>
                    </MultiSelectContent>
                  </MultiSelect>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`flightAssistant.${flightIndex}.flightSupports`}
              render={({ field }) => (
                <FormItem className="flex w-full items-center gap-2">
                  <FormLabel className="w-1/3 pt-2 dark:text-slate-200">
                    Flight Support
                  </FormLabel>
                  <MultiSelect
                    onOpenChange={(value) => setOpenSupport(value)}
                    onValueChange={field.onChange}
                    onSearch={handleSearch}
                    open={openSupport}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <MultiSelectTrigger className="w-full cursor-pointer bg-white hover:bg-slate-100">
                        <MultiSelectValue
                          placeholder="Select User"
                          className="py-[1px]"
                        />
                      </MultiSelectTrigger>
                    </FormControl>
                    <MultiSelectContent className="max-w-full">
                      <MultiSelectSearch placeholder="Search contact" />
                      <MultiSelectList className="max-w-full">
                        {loading ? null : renderMultiSelectOptions(options)}
                        <MultiSelectEmpty>
                          {loading ? "Loading..." : "No results found"}
                        </MultiSelectEmpty>
                      </MultiSelectList>
                    </MultiSelectContent>
                  </MultiSelect>
                </FormItem>
              )}
            />
          </div>
          <div className="flex gap-4">
            <DialogTrigger type="button" className="w-max" asChild>
              <Button variant="outline">Cancel</Button>
            </DialogTrigger>
            <DialogClose asChild>
              <Button
                type="button"
                variant="primary"
                onClick={onSubmitResponsibleHandler}
              >
                Save
              </Button>
            </DialogClose>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
