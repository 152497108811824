import type { Row } from "@tanstack/react-table"

import type { AdvertiseCountryItem } from "../models/advertise-data-types"
import type { AdvertiseItem } from "../models/advertise-data-types.js"

export const selectCountryHandler = (
  isChecked: boolean | string,
  row: Row<AdvertiseCountryItem>,
  advertise: AdvertiseItem,
  setAdvertise: (newAdvertise: Partial<AdvertiseItem> | AdvertiseItem) => void,
) => {
  const priceFromTable = parseFloat(row.getValue("price"))
  const audienceFromTable = parseFloat(row.getValue("audience"))
  const countryFromTable = row.getValue("country") as string

  if (isChecked) {
    row.toggleSelected(true)
    setAdvertise({
      budget: advertise.budget - priceFromTable,
      audience: advertise.audience + audienceFromTable,
      countries: [...advertise.countries, countryFromTable],
    })
  } else {
    row.toggleSelected(false)
    setAdvertise({
      budget: advertise.budget + priceFromTable,
      audience: advertise.audience - audienceFromTable,
      countries: advertise.countries.filter((country) => country !== countryFromTable),
    })
  }
}

export const disableCheckboxHandler = (
  row: Row<AdvertiseCountryItem>,
  advertise: AdvertiseItem,
) => {
  const isSelected = advertise.countries.includes(row.getValue("country"))
  const isDisabled = parseFloat(row.getValue("price")) > advertise.budget
  if (isSelected) {
    return false
  }
  return isDisabled
}

export const isActive = (row: Row<AdvertiseCountryItem>, advertise: AdvertiseItem) => {
  const isExceedingBudget = parseFloat(row.getValue("price")) > advertise.budget
  const isSelected = advertise.countries.includes(row.getValue("country"))
  if (isSelected) {
    return "text-black"
  }
  return isExceedingBudget ? "text-gray-500" : "text-black"
}
