import { Route } from "@tanstack/react-router"

import { Approved } from "#pages/clients/offers/approved/approved.js"

import { clientsOffersRoute } from "../index.js"

export const clientsOffersApprovedRoute = new Route({
  getParentRoute: () => clientsOffersRoute,
  path: "/approved",
  component: Approved,
})
