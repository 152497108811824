export const yearInputHandler = (inputValue: string) => {
  let value = inputValue
  value = value.replace(/[^0-9]/g, "")

  if (value !== "") {
    let parsedValue = parseInt(value, 10)
    if (parsedValue > new Date().getFullYear()) {
      parsedValue = new Date().getFullYear()
    }
    value = parsedValue.toString()
  }
  return value
}
