import { maskitoTransform } from "@maskito/core"
import { maskitoTimeOptionsGenerator } from "@maskito/kit"
import React from "react"

import { FormMessage, Input } from "@fourel/ui"

interface MaskedInputProps extends React.ComponentProps<"input"> {
  revalidate: (
    name:
      | `legs.${number}.estimatedTimeOfArrival`
      | `legs.${number}.estimatedTimeOfDeparture`,
  ) => void
  fieldName:
    | `legs.${number}.estimatedTimeOfArrival`
    | `legs.${number}.estimatedTimeOfDeparture`
}

export const MaskedInput = ({
  onChange,
  revalidate,
  fieldName,
  ...props
}: MaskedInputProps) => {
  const options = maskitoTimeOptionsGenerator({
    mode: "HH:MM",
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.currentTarget.value = maskitoTransform(event.currentTarget.value, options)
    onChange?.(event)
    revalidate(fieldName)
  }

  return (
    <div className="flex w-full flex-col gap-1">
      <Input onChange={handleChange} {...props} className="text-xs placeholder:text-xs" />
      <FormMessage className="pl-1" />
    </div>
  )
}
