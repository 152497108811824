import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "@tanstack/react-router"
import Delete from "lucide-static/icons/x.svg"
import { useFieldArray, useForm } from "react-hook-form"
import InlineSVG from "react-inlinesvg"
import * as z from "zod"

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenuItem,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Label,
  ScrollArea,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@fourel/ui"

import type { MutationAddNewOrganizationInput } from "#gql/graphql.js"
import { graphql } from "#gql/index.js"
import { client } from "#graphql-client.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

const organizationAndMembersSchema = z.object({
  name: z.string().min(1),
  slug: z
    .string()
    .min(1)
    .refine((slug) => /^[a-z0-9-]+$/.test(slug), {
      message:
        "Invalid slug format. Only lowercase letters, numbers, and hyphens allowed.",
    }),
  members: z.array(
    z.object({
      email: z.literal("").or(z.string().email({ message: "Enter valid email" }).min(7)),
      role: z.literal("").or(
        z.string().refine((val) => val !== "", {
          message: "Please select a role.",
        }),
      ),
    }),
  ),
})

type OrganizationAndMembersFormValues = z.infer<typeof organizationAndMembersSchema>

const IsAvailableOrganizationSlugDocument = graphql(/* GraphQL */ `
  query IsAvailableOrganizationSlug($slug: String!) {
    isAvailableOrganizationSlug(slug: $slug)
  }
`)

const AddNewOrganizationDocument = graphql(/* GraphQL */ `
  mutation AddNewOrganization($input: MutationAddNewOrganizationInput!) {
    addNewOrganization(input: $input) {
      id
      name
      slug
    }
  }
`)
export const AddCompanyDialog = () => {
  const { selectOrganization, fetchUserInfo } = useOnboardedUserInfo()
  const navigate = useNavigate()
  const form = useForm<OrganizationAndMembersFormValues>({
    resolver: zodResolver(organizationAndMembersSchema),
    defaultValues: {
      name: "",
      slug: "",
      members: [
        {
          email: "",
          role: "",
        },
        {
          email: "",
          role: "",
        },
        {
          email: "",
          role: "",
        },
      ],
    },
    reValidateMode: "onSubmit",
  })
  const { fields, append, remove } = useFieldArray({
    name: "members",
    control: form.control,
  })
  const addNewOrganization = useMutation({
    mutationFn: (input: MutationAddNewOrganizationInput) =>
      client.request(AddNewOrganizationDocument, { input }),
    onSuccess: (res) => {
      void fetchUserInfo().then(() => {
        selectOrganization(res.addNewOrganization.slug)
        void navigate({
          to: "/$slug/clients",
          params: {
            slug: res.addNewOrganization.slug,
          },
        })
        form.reset()
      })
    },
  })
  const handleEmailOnBlur = (i: number) => {
    const email = form.getValues(`members.${i}.email`)
    const role = form.getValues(`members.${i}.role`)
    if (email && !role) {
      form.setError(`members.${i}.role`, {
        type: "manual",
        message: "Please select a role.",
      })
    }
  }
  const isAvailableSlug = async (slug: string) => {
    const res = await client.request(IsAvailableOrganizationSlugDocument, {
      slug,
    })
    return res.isAvailableOrganizationSlug
  }
  const onSubmit = async (
    organizationData: z.infer<typeof organizationAndMembersSchema>,
  ) => {
    const isAvailable = await isAvailableSlug(organizationData.slug)
    const validMembers = organizationData.members.filter(
      (it) => it.email !== "" && it.role !== "",
    )
    if (!isAvailable) {
      form.setError("slug", {
        type: "manual",
        message: "Slug is already taken. Please try another one.",
      })
    } else {
      const input = {
        organization: {
          name: organizationData.name,
          slug: organizationData.slug,
        },
        invites: validMembers,
      }
      addNewOrganization.mutate(input)
    }
  }

  return (
    <Dialog>
      <DialogTrigger className="w-full">
        <DropdownMenuItem className="cursor-pointer">
          Add new organization
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add new Organization</DialogTitle>
          <DialogDescription>
            Set up a new organization, invite your team, and start collaborating.
          </DialogDescription>
        </DialogHeader>
        <div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="mt-4 space-y-0 px-2">
                    <FormLabel className="text-sm font-medium leading-5">
                      Company name
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="Enter " {...field} />
                    </FormControl>
                    <FormMessage className="pl-1 text-xs" />
                  </FormItem>
                )}
              />
              <div className="mt-6 flex w-full items-center gap-4 px-2">
                <Label htmlFor="link">https://getfourel.app/</Label>
                <FormField
                  control={form.control}
                  name="slug"
                  render={({ field }) => (
                    <FormItem className="w-full space-y-0">
                      <FormControl>
                        <Input placeholder="Enter " id="link" {...field} />
                      </FormControl>
                      <FormMessage className="pl-1 text-xs" />
                    </FormItem>
                  )}
                />
              </div>
              <ScrollArea className="h-[300px]">
                {fields.map((memberField, i) => (
                  <div key={memberField.id} className="mt-6 px-2 pb-2">
                    <Label>Member {i + 1}</Label>
                    <div className="flex gap-4">
                      <FormField
                        name={`members.${i}.email`}
                        control={form.control}
                        render={({ field }) => (
                          <FormItem className="space-y-0">
                            <FormControl>
                              <Input
                                placeholder="Email@example.com"
                                {...field}
                                onBlur={() => handleEmailOnBlur(i)}
                              />
                            </FormControl>
                            <FormMessage className="pl-1 text-xs" />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name={`members.${i}.role`}
                        render={({ field }) => (
                          <FormItem className="w-[30%] space-y-0">
                            <FormControl>
                              <Select
                                value={field.value || undefined}
                                onValueChange={(val) => {
                                  field.onChange(val)
                                  form.clearErrors()
                                }}
                              >
                                <SelectTrigger>
                                  <SelectValue placeholder="Role" {...field} />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup>
                                    <SelectLabel>Role</SelectLabel>
                                    <SelectItem value="owner">Owner</SelectItem>
                                    <SelectItem value="admin">Admin</SelectItem>
                                    <SelectItem value="member">Member</SelectItem>
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                            </FormControl>
                            <FormMessage className="pl-1 text-xs" />
                          </FormItem>
                        )}
                      />
                      <Button onClick={() => remove(i)} variant="ghost">
                        <InlineSVG src={Delete} className="h-4" />
                      </Button>
                    </div>
                  </div>
                ))}
              </ScrollArea>

              <div className="px-2">
                <Button
                  className="mt-6"
                  type="button"
                  variant="outline"
                  onClick={() => append({ email: "", role: "" })}
                >
                  Add more members
                </Button>
              </div>
              {form.formState.isValid ? (
                <DialogTrigger
                  disabled={form.formState.isSubmitting}
                  asChild
                  type="submit"
                >
                  <Button className="mt-3 w-full">Add new organization</Button>
                </DialogTrigger>
              ) : (
                <Button type="submit" className="mt-3 w-full">
                  Add new organization
                </Button>
              )}
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
