import { Link, Outlet, useRouterState } from "@tanstack/react-router"
import ChevronsLeftIcon from "lucide-static/icons/chevrons-left.svg"
import MenuIcon from "lucide-static/icons/menu.svg"
import CloseIcon from "lucide-static/icons/x.svg"
import InlineSVG from "react-inlinesvg"

import { Button, cn, Drawer, DrawerClose, DrawerContent, DrawerTrigger } from "@fourel/ui"

import FourelLogoIcon from "#images/icons/logo.svg"
import { SettingsMenuTabs } from "#pages/lobby/components/settings-menu-tabs.js"
import { SettingsMobileMenuTabs } from "#pages/lobby/components/settings-mobile-menu-tabs.js"
import { useMenuStore } from "#pages/lobby/menu-store.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

import LogoIcon from "../../images/icons/fourel-logo.svg"
import {
  AccountDropdown,
  AdvertiseSubmenu,
  ClientsSubmenu,
  CompanyDropdown,
  MenuTabs,
  MobileAdvertiseSubmenu,
  MobileClientsSubmenu,
  MobileCompanyDropdown,
  MobileCompanyDropdownHeader,
  MobileMenuTabs,
  MobileOffersSubmenu,
  MobileTripRequestsSubmenu,
  OffersSubmenu,
  TripRequestsSubmenu,
} from "./components/index.js"

export const Lobby = () => {
  const { currentOrg } = useOnboardedUserInfo()
  const { toggleMenu, isOpenMenu } = useMenuStore()
  const pathname = useRouterState({
    select: (s) => s.location.pathname,
  })

  let menuCategoryName = "no match"

  if (currentOrg && currentOrg.slug && pathname) {
    const menuCategoryRegex = new RegExp(`/${currentOrg.slug}/([^/]+)`)
    const menuCategoryMatch = pathname.match(menuCategoryRegex)
    menuCategoryName = menuCategoryMatch ? menuCategoryMatch[1] : "no match"
  }
  const isSettingsRoute = pathname.includes("/settings")
  return (
    <>
      {isSettingsRoute ? (
        <>
          <div
            className={cn(
              "flex h-fit w-full items-center justify-between overflow-hidden bg-violet-700 px-6 py-4 transition-all duration-300 ease-in-out md:hidden dark:bg-violet-900",
            )}
          >
            <Drawer direction="left">
              <DrawerTrigger
                asChild
                className={cn("flex w-fit items-center p-0")}
                onClick={toggleMenu}
              >
                <Button variant="link">
                  <InlineSVG
                    src={MenuIcon}
                    className={cn(
                      "text-white transition-all duration-300 ease-in-out",
                      !isOpenMenu && "rotate-180",
                    )}
                  />
                </Button>
              </DrawerTrigger>
              <DrawerContent className="flex h-screen max-w-72 flex-col justify-start rounded-none border-none bg-violet-700 pt-4 md:hidden dark:bg-violet-900">
                <div className={cn("mb-8 flex items-center")}>
                  <DrawerClose asChild>
                    <Button
                      variant="link"
                      className={cn("flex items-center")}
                      onClick={toggleMenu}
                    >
                      <InlineSVG
                        src={CloseIcon}
                        className={cn(
                          "text-white transition-all duration-300 ease-in-out",
                        )}
                      />
                    </Button>
                  </DrawerClose>
                  <Link to="/$slug/clients" params={{ slug: currentOrg.slug }}>
                    <InlineSVG
                      src={FourelLogoIcon}
                      className={cn("h-10 w-auto text-white")}
                    />
                  </Link>
                </div>
                <SettingsMobileMenuTabs open={isOpenMenu} />
              </DrawerContent>
            </Drawer>
            <Link to="/$slug/clients" params={{ slug: currentOrg.slug }}>
              <InlineSVG src={FourelLogoIcon} className={cn("h-10 w-auto text-white")} />
            </Link>
            <MobileCompanyDropdownHeader />
          </div>
          <div className="flex" data-testid="account-settings-element">
            <div
              className={cn(
                "hidden min-h-screen min-w-20 max-w-20 flex-col justify-start overflow-hidden bg-violet-700 pl-4 pt-4 transition-all duration-300 ease-in-out md:flex dark:bg-violet-900",
                isOpenMenu && "min-w-80",
              )}
            >
              <div
                className={cn(
                  "mb-8 flex items-start gap-6",
                  !isOpenMenu && "mb-8 w-12 flex-col items-center gap-8",
                )}
              >
                <Button variant="link" className={cn("h-8 p-2")} onClick={toggleMenu}>
                  <InlineSVG
                    src={ChevronsLeftIcon}
                    className={cn(
                      "h-5 w-5 text-white transition-all duration-300 ease-in-out",
                      !isOpenMenu && "rotate-180",
                    )}
                  />
                </Button>
                <Link to="/$slug/clients" params={{ slug: currentOrg.slug }}>
                  <InlineSVG
                    src={FourelLogoIcon}
                    className={cn(isOpenMenu ? "h-10 w-auto text-white" : "hidden")}
                  />
                  <InlineSVG
                    src={LogoIcon}
                    className={cn(isOpenMenu ? "hidden" : "h-8 w-auto text-white")}
                  />
                </Link>
              </div>
              <SettingsMenuTabs open={isOpenMenu} />
            </div>
            <div className="w-full p-6 pr-0">
              <Outlet />
            </div>
          </div>
        </>
      ) : (
        <div
          data-testid="dashboard-root"
          className="sticky top-0 flex w-full flex-col md:flex-row"
        >
          {/* Mobile drawer menu */}
          <div
            className={cn(
              "flex h-fit w-full items-center justify-between overflow-hidden bg-violet-700 px-6 py-4 transition-all duration-300 ease-in-out md:hidden dark:bg-violet-900",
            )}
          >
            <Drawer direction="left">
              <DrawerTrigger
                asChild
                className={cn("flex w-fit items-center p-0")}
                onClick={toggleMenu}
              >
                <Button variant="link">
                  <InlineSVG
                    src={MenuIcon}
                    className={cn(
                      "text-white transition-all duration-300 ease-in-out",
                      !isOpenMenu && "rotate-180",
                    )}
                  />
                </Button>
              </DrawerTrigger>
              <DrawerContent className="flex h-screen max-w-72 flex-col justify-start rounded-none border-none bg-violet-700 pt-4 md:hidden dark:bg-violet-900">
                <div className={cn("mb-8 flex items-center")}>
                  <DrawerClose asChild>
                    <Button
                      variant="link"
                      className={cn("flex items-center")}
                      onClick={toggleMenu}
                    >
                      <InlineSVG
                        src={CloseIcon}
                        className={cn(
                          "text-white transition-all duration-300 ease-in-out",
                        )}
                      />
                    </Button>
                  </DrawerClose>
                  <Link to="/$slug/clients" params={{ slug: currentOrg.slug }}>
                    <DrawerClose>
                      <InlineSVG
                        src={FourelLogoIcon}
                        className={cn("h-10 w-auto text-white")}
                      />
                    </DrawerClose>
                  </Link>
                </div>
                <div className="mb-8 flex items-center gap-4 px-4">
                  <MobileCompanyDropdown />
                  <AccountDropdown open={isOpenMenu} />
                </div>
                <MobileMenuTabs open={isOpenMenu} />
              </DrawerContent>
            </Drawer>
            <Link to="/$slug/clients" params={{ slug: currentOrg.slug }}>
              <InlineSVG src={FourelLogoIcon} className={cn("h-10 w-auto text-white")} />
            </Link>
            <MobileCompanyDropdownHeader />
          </div>
          {/* Desktop drawer menu */}
          <div
            className={cn(
              "hidden min-h-screen min-w-20 max-w-20 flex-col justify-start overflow-hidden bg-violet-700 pl-4 pt-4 transition-all duration-300 ease-in-out md:flex dark:bg-violet-900",
              isOpenMenu && "min-w-80",
            )}
          >
            <div
              className={cn(
                "mb-8 flex items-start gap-6",
                !isOpenMenu && "mb-8 w-12 flex-col items-center gap-8",
              )}
            >
              <Button variant="link" className={cn("h-8 p-2")} onClick={toggleMenu}>
                <InlineSVG
                  src={ChevronsLeftIcon}
                  className={cn(
                    "h-5 w-5 text-white transition-all duration-300 ease-in-out",
                    !isOpenMenu && "rotate-180",
                  )}
                />
              </Button>
              <Link to="/$slug/clients" params={{ slug: currentOrg.slug }}>
                <InlineSVG
                  src={FourelLogoIcon}
                  className={cn(isOpenMenu ? "h-10 w-auto text-white" : "hidden")}
                />
                <InlineSVG
                  src={LogoIcon}
                  className={cn(isOpenMenu ? "hidden" : "h-8 w-auto text-white")}
                />
              </Link>
            </div>

            <div className="mb-8 flex items-center gap-4 pr-4">
              <CompanyDropdown open={isOpenMenu} />
              <AccountDropdown open={isOpenMenu} />
            </div>
            <MenuTabs open={isOpenMenu} />
          </div>

          {
            // Clients Submenu
            pathname.includes("clients/") && !pathname.includes("clients/add-client") && (
              <>
                <ClientsSubmenu />
                <MobileClientsSubmenu />
              </>
            )
          }

          {
            // Advertise Submenu
            menuCategoryName === "advertise" && (
              <>
                <AdvertiseSubmenu />
                <MobileAdvertiseSubmenu />
              </>
            )
          }

          {
            // Offers Submenu
            menuCategoryName === "offers" && !pathname.includes("create-offer") && (
              <>
                <OffersSubmenu />
                <MobileOffersSubmenu />
              </>
            )
          }

          {
            // Trip Requests Submenu
            menuCategoryName === "trip-requests" && (
              <>
                <TripRequestsSubmenu />
                <MobileTripRequestsSubmenu />
              </>
            )
          }
          <div className="w-full overflow-y-auto">
            <Outlet />
          </div>
        </div>
      )}
    </>
  )
}
