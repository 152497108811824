import type { VariablesOf } from "@graphql-typed-document-node/core"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import { Link, Route, useNavigate } from "@tanstack/react-router"
import MinusIcon from "lucide-static/icons/minus.svg"
import PlusIcon from "lucide-static/icons/plus.svg"
import React from "react"
import { useFieldArray, useForm } from "react-hook-form"
import InlineSVG from "react-inlinesvg"
import z from "zod"

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Separator,
  toast,
  ToastAction,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { ClientAirportSelect } from "#pages/clients/general/components/client-airport-select.js"
import { clientsRoute } from "#pages/clients/index.js"
import { queryClient } from "#query-client"
import { useOnboardedUserInfo } from "#store/user-info.js"

const ClientCreateDocument = graphql(/* GraphQL */ `
  mutation ClientCreate($input: MutationClientCreateInput!) {
    clientCreate(input: $input) {
      id
    }
  }
`)

const clientSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Name is required" })
    .refine((value) => value.trim().split(/\s+/).length >= 2, {
      message: "Name must contain two words",
    }),
  primaryDepartureAirport: z
    .object({
      id: z.string(),
      label: z.string(),
    })
    .optional(),
  contacts: z
    .array(
      z.object({
        contactFirstName: z.string().trim().min(1, { message: "First Name is required" }),
        contactLastName: z.string().trim().min(1, { message: "Last Name is required" }),
        contactEmail: z
          .string()
          .trim()
          .min(1, { message: "Email is required" })
          .email("This is not a valid email."),
        contactPhone: z
          .string()
          .trim()
          .min(10, { message: "Must be a valid mobile number" })
          .max(14, { message: "Must be a valid mobile number" }),
      }),
    )
    .optional(),
})

export type ClientFormType = z.infer<typeof clientSchema>

type ClientSubFormType = {
  field: "name"
  label: string
  placeholder: string
}

const clientFormFields: ClientSubFormType[] = [
  { field: "name", label: "Name*", placeholder: "Name" },
]

const contactFormFields = [
  { field: "contactFirstName", label: "First Name*", placeholder: "First name" },
  { field: "contactLastName", label: "Last Name*", placeholder: "Last name" },
  { field: "contactEmail", label: "Email*", placeholder: "Email" },
  { field: "contactPhone", label: "Phone*", placeholder: "Phone" },
]

export const AddClient = () => {
  const { currentOrg } = useOnboardedUserInfo()
  const navigate = useNavigate()

  const { mutate } = useMutation({
    mutationFn: (variables: VariablesOf<typeof ClientCreateDocument>) =>
      client.request(ClientCreateDocument, variables),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["clientGet"] })
      void navigate({ to: "/$slug/clients", params: { slug: currentOrg.slug } })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while adding a client.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const form = useForm<ClientFormType>({
    resolver: zodResolver(clientSchema),
    defaultValues: {
      name: "",
      primaryDepartureAirport: {
        id: "",
        label: "",
      },
      contacts: [],
    },
    reValidateMode: "onSubmit",
  })

  const {
    fields: contactFields,
    append,
    remove,
  } = useFieldArray({
    name: "contacts",
    control: form.control,
  })

  const resetField = form.resetField

  const onSubmitHandler = (values: ClientFormType) => {
    const formattedContacts =
      values.contacts?.map((c) => ({
        firstName: c.contactFirstName,
        lastName: c.contactLastName,
        email: c.contactEmail,
        phone: c.contactPhone,
        preferredContactMethod: "email",
      })) || []
    const backendShapeClient = {
      name: values.name,
      primaryDepartureAirportId: values.primaryDepartureAirport?.id || null,
      contacts: formattedContacts,
      preferredContactMethod: "email",
    }
    void mutate({ input: { client: backendShapeClient, organizationId: currentOrg.id } })
    form.reset()
  }

  const addContactFormHandler = () => {
    append({
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhone: "",
    })
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmitHandler)}
        className="flex w-full flex-col items-start justify-start gap-4"
      >
        <h3 className="text-xl font-semibold">Add a client</h3>
        <div className="flex w-full flex-col gap-2 lg:w-2/3">
          {clientFormFields.map((item, index) => (
            <FormField
              key={index}
              control={form.control}
              name={item.field}
              render={({ field }) => (
                <FormItem className="flex items-center">
                  <FormLabel className="w-1/2">{item.label}</FormLabel>
                  <div className="flex w-full flex-col gap-1">
                    <FormControl>
                      <Input
                        placeholder={item.placeholder}
                        {...field}
                        defaultValue=""
                        onChange={(e) => {
                          resetField(item.field)
                          form.setValue(item.field, e.target.value)
                        }}
                      />
                    </FormControl>
                    <FormMessage className="pl-1 text-xs" />
                  </div>
                </FormItem>
              )}
            />
          ))}
          <FormField
            name="primaryDepartureAirport"
            control={form.control}
            render={({ field }) => (
              <FormItem className="flex items-center">
                <FormLabel className="w-1/2">Primary Departure Airport</FormLabel>
                <FormControl>
                  <ClientAirportSelect
                    field={field}
                    fieldName="primaryDepartureAirport"
                    setValue={form.setValue}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <Separator />

        {contactFields.length > 0 && (
          <>
            <h3 className="text-xl font-semibold">Add a contact</h3>
            <div className="flex w-full flex-col gap-2 lg:w-1/2">
              {contactFields.map((item, index) => (
                <div key={item.id}>
                  {contactFormFields.map((contactField) => (
                    <FormField
                      key={contactField.field}
                      control={form.control}
                      name={
                        `contacts.${index}.${contactField.field}` as keyof ClientFormType
                      }
                      render={({ field }) => (
                        <FormItem className="flex items-center">
                          <FormLabel className="w-1/2">{contactField.label}</FormLabel>
                          <div className="flex w-full flex-col gap-1">
                            <FormControl>
                              <Input
                                placeholder={contactField.placeholder}
                                {...field}
                                value={field.value as keyof ClientFormType}
                                onChange={(event) => {
                                  resetField(
                                    `contacts.${index}.${contactField.field}` as keyof ClientFormType,
                                  )
                                  form.setValue(
                                    `contacts.${index}.${contactField.field}` as keyof ClientFormType,
                                    event.target.value,
                                  )
                                }}
                              />
                            </FormControl>
                            <FormMessage className="pl-1 text-xs" />
                          </div>
                        </FormItem>
                      )}
                    />
                  ))}
                  <Button variant="outline" type="button" onClick={() => remove(index)}>
                    <InlineSVG src={MinusIcon} className="min-w-4 pr-2" />
                    Remove
                  </Button>
                  <Separator className="mt-4" />
                </div>
              ))}
            </div>
          </>
        )}

        <div className="flex w-full justify-start">
          <Button variant="outline" type="button" onClick={addContactFormHandler}>
            <InlineSVG src={PlusIcon} className="min-w-4 pr-2" />
            <span>Add a contact</span>
          </Button>
        </div>
        <Separator />

        <div className="flex w-full justify-start gap-4">
          <Link to="/$slug/clients" params={{ slug: currentOrg.slug }}>
            <Button variant="outline">Cancel</Button>
          </Link>
          <Button variant="primary">Save</Button>
        </div>
      </form>
    </Form>
  )
}

export const clientsAddClientRoute = new Route({
  getParentRoute: () => clientsRoute,
  path: "add-client",
  component: AddClient,
})
