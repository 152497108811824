import { create } from "zustand"

import type { AdvertiseItem, SetAdvertiseType } from "./models/advertise-data-types.js"

interface AdvertiseMyClientsStore {
  advertise: AdvertiseItem
  setAdvertise: SetAdvertiseType
}

interface AdvertiseNewClientStore {
  advertise: AdvertiseItem
  setAdvertise: SetAdvertiseType
}
const MIN_ADVERTISE_PRICE = 500

const persistedAdvertiseMyClientsDataString = localStorage.getItem("advertise_my_clients")
const persistedAdvertiseMyClientsData = persistedAdvertiseMyClientsDataString
  ? (JSON.parse(persistedAdvertiseMyClientsDataString) as AdvertiseItem)
  : null

const PERSISTED_ADVERTISE_MY_CLIENTS_COUNTRIES =
  persistedAdvertiseMyClientsData && persistedAdvertiseMyClientsData.countries
    ? persistedAdvertiseMyClientsData.countries
    : []

const PERSISTED_ADVERTISE_MY_CLIENTS_PRICE =
  persistedAdvertiseMyClientsData && persistedAdvertiseMyClientsData.price
    ? persistedAdvertiseMyClientsData.price
    : MIN_ADVERTISE_PRICE

const PERSISTED_ADVERTISE_MY_CLIENTS_AUDIENCE =
  persistedAdvertiseMyClientsData && persistedAdvertiseMyClientsData.audience
    ? persistedAdvertiseMyClientsData.audience
    : 0

const PERSISTED_ADVERTISE_MY_CLIENTS_BUDGET =
  persistedAdvertiseMyClientsData && persistedAdvertiseMyClientsData.budget
    ? persistedAdvertiseMyClientsData.budget
    : MIN_ADVERTISE_PRICE

export const useAdvertiseStore = create<AdvertiseMyClientsStore>((set) => ({
  advertise: {
    countries: PERSISTED_ADVERTISE_MY_CLIENTS_COUNTRIES,
    price: PERSISTED_ADVERTISE_MY_CLIENTS_PRICE,
    audience: PERSISTED_ADVERTISE_MY_CLIENTS_AUDIENCE,
    budget: PERSISTED_ADVERTISE_MY_CLIENTS_BUDGET,
  },

  setAdvertise: (newAdvertise) => {
    set((prev) => {
      localStorage.setItem(
        "advertise_my_clients",
        JSON.stringify({ ...prev.advertise, ...newAdvertise }),
      )
      return { advertise: { ...prev.advertise, ...newAdvertise } }
    })
  },
}))

const persistedAdvertiseNewClientsDataString = localStorage.getItem(
  "advertise_new_clients",
)
const persistedAdvertiseNewClientsData = persistedAdvertiseNewClientsDataString
  ? (JSON.parse(persistedAdvertiseNewClientsDataString) as AdvertiseItem)
  : null

const PERSISTED_ADVERTISE_NEW_CLIENTS_COUNTRIES =
  persistedAdvertiseNewClientsData && persistedAdvertiseNewClientsData.countries
    ? persistedAdvertiseNewClientsData.countries
    : []

const PERSISTED_ADVERTISE_NEW_CLIENTS_PRICE =
  persistedAdvertiseNewClientsData && persistedAdvertiseNewClientsData.price
    ? persistedAdvertiseNewClientsData.price
    : MIN_ADVERTISE_PRICE

const PERSISTED_ADVERTISE_NEW_CLIENTS_AUDIENCE =
  persistedAdvertiseNewClientsData && persistedAdvertiseNewClientsData.audience
    ? persistedAdvertiseNewClientsData.audience
    : 0

const PERSISTED_ADVERTISE_NEW_CLIENTS_BUDGET =
  persistedAdvertiseNewClientsData && persistedAdvertiseNewClientsData.budget
    ? persistedAdvertiseNewClientsData.budget
    : MIN_ADVERTISE_PRICE

export const useAdvertiseNewClientStore = create<AdvertiseNewClientStore>((set) => ({
  advertise: {
    countries: PERSISTED_ADVERTISE_NEW_CLIENTS_COUNTRIES,
    price: PERSISTED_ADVERTISE_NEW_CLIENTS_PRICE,
    audience: PERSISTED_ADVERTISE_NEW_CLIENTS_AUDIENCE,
    budget: PERSISTED_ADVERTISE_NEW_CLIENTS_BUDGET,
  },

  setAdvertise: (newAdvertise) => {
    set((prev) => {
      localStorage.setItem(
        "advertise_new_clients",
        JSON.stringify({ ...prev.advertise, ...newAdvertise }),
      )
      return { advertise: { ...prev.advertise, ...newAdvertise } }
    })
  },
}))
