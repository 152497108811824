import type { VariablesOf } from "@graphql-typed-document-node/core"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect } from "react"

import { Button, toast, ToastAction } from "@fourel/ui"

import { Config } from "#config-fe.js"
import { graphql } from "#gql"
import { client } from "#graphql-client"
import { useIntegrationStore } from "#pages/integration/store.js"

import SalesForceIcon from "../icons/salesforce.png"

const CreateOrganizationSalesforceTokenDocument = graphql(/* GraphQL */ `
  mutation CreateSalesforceToken(
    $input: MutationCreateOrganizationSalesforceTokenInput!
  ) {
    createOrganizationSalesforceToken(input: $input)
  }
`)

const IsOrganizationConnectedToSalesforceDocument = graphql(/* GraphQL */ `
  query IsOrganizationConnectedToSalesforce {
    isOrganizationConnectedToSalesforce
  }
`)

// https://help.salesforce.com/s/articleView?id=sf.remoteaccess_oauth_web_server_flow.htm&type=5
const base64url = (arrayBuffer: ArrayBuffer) => {
  const bytes = new Uint8Array(arrayBuffer)
  let str = ""
  for (let i = 0; i < bytes.length; i++) {
    str += String.fromCharCode(bytes[i])
  }
  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "")
}
const generateCodeVerifier = () => {
  const array = new Uint8Array(32)
  window.crypto.getRandomValues(array)
  return base64url(array.buffer)
}

const generateCodeChallenge = async (verifier: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(verifier)
  const digest = await crypto.subtle.digest("SHA-256", data)
  return base64url(digest)
}
export const Salesforce = () => {
  const {
    salesforce,
    setSalesforceCodeVerifier,
    salesforceCodeVerifier,
    removeSalesforceCodeVerifier,
    removeIntegration,
  } = useIntegrationStore()

  const { data } = useQuery({
    queryKey: ["isUserConnectedToSalesforce"],
    queryFn: () => client.request(IsOrganizationConnectedToSalesforceDocument),
  })

  const { mutate, isSuccess } = useMutation({
    mutationFn: (
      variables: VariablesOf<typeof CreateOrganizationSalesforceTokenDocument>,
    ) => client.request(CreateOrganizationSalesforceTokenDocument, variables),
    onSuccess: () => {
      toast({
        title: "Success!",
        description: "Integration with Salesforce has been successful.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while integrating with Salesforce.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  useEffect(() => {
    if (salesforce && salesforceCodeVerifier) {
      void mutate({
        input: { code: salesforce, verifier: salesforceCodeVerifier },
      })
    }
  }, [mutate, salesforce, salesforceCodeVerifier])

  useEffect(() => {
    if (isSuccess) {
      removeSalesforceCodeVerifier()
      removeIntegration("salesforce")
    }
  }, [removeSalesforceCodeVerifier, removeIntegration, isSuccess])
  const salesforceHandle = () => {
    const verifier = generateCodeVerifier()
    setSalesforceCodeVerifier(verifier)
    void generateCodeChallenge(verifier).then((code) => {
      window.location.href =
        `https://${Config.VITE_SALESFORCE_DOMAIN}/services/oauth2/authorize` +
        `?client_id=${Config.VITE_SALESFORCE_CLIENT_ID}` +
        `&redirect_uri=${Config.VITE_SALESFORCE_REDIRECT_URL}` +
        `&response_type=code` +
        `&code_challenge=${code}` +
        `&code_challenge_method=S256`
    })
  }
  return (
    <div className="flex w-1/4 flex-col items-center justify-between gap-2 border border-gray-300 p-5">
      <img src={SalesForceIcon} alt="SalesForceIcon" className="h-28" />
      <span>Salesforce</span>
      <Button
        onClick={salesforceHandle}
        disabled={data?.isOrganizationConnectedToSalesforce}
        className="w-full bg-violet-900 p-0 hover:bg-violet-500"
      >
        {data?.isOrganizationConnectedToSalesforce ? "Connected" : "Connect"}
      </Button>
    </div>
  )
}
