import { Route } from "@tanstack/react-router"

import { advertiseRoute } from "#pages/advertise/index.js"

import { AdvertiseBalance } from "../components/advertise-balance.js"
import { AdvertiseCheckout } from "../components/advertise-checkout.js"
import { AdvertiseSearch } from "../components/advertise-search-table.js"
import { useAdvertiseNewClientStore } from "../store.js"

export const ToNewClients = () => {
  const { advertise, setAdvertise } = useAdvertiseNewClientStore()
  return (
    <div className="flex flex-col gap-4 lg:flex-row lg:gap-0">
      <div className="flex w-full flex-col items-start justify-start gap-4 border-gray-200 bg-white px-4 py-4 lg:w-1/2 lg:border-r dark:bg-slate-950">
        <AdvertiseBalance advertise={advertise} setAdvertise={setAdvertise} />
        <AdvertiseSearch advertise={advertise} setAdvertise={setAdvertise} />
      </div>
      <AdvertiseCheckout advertise={advertise} />
    </div>
  )
}

export const toNewClientsRoute = new Route({
  getParentRoute: () => advertiseRoute,
  path: "/new",
  component: ToNewClients,
})
