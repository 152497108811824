import { useQuery } from "@tanstack/react-query"

import { graphql } from "#gql"
import { client } from "#graphql-client"

const FlightsListResponsibleDocument = graphql(/* GraphQL */ `
  query FlightsListResponsible($input: QueryFlightInput!) {
    flight(input: $input) {
      id
      flightBroker {
        id
        name
      }
      flightBrokerAssistance {
        id
        name
      }
      flightSupports {
        id
        name
      }
      quoteLeg {
        aircraft {
          operator {
            id
            name
          }
        }
      }
    }
  }
`)

export const AccordionFormOperator = ({ flightId }: { flightId: string }) => {
  const { data } = useQuery({
    queryKey: ["FlightResponsible", flightId],
    queryFn: () =>
      client.request(FlightsListResponsibleDocument, {
        input: { flightId: flightId! },
      }),
    enabled: !!flightId,
  })
  return (
    <div className="flex w-full flex-col gap-2">
      <span className="h-8 text-lg font-bold">Operator</span>
      <div className="flex items-center gap-4">
        <span className="text-xs font-semibold text-slate-500">Operator</span>
        <span className="text-xs">
          {data?.flight.quoteLeg.aircraft.operator.name || "-"}
        </span>
      </div>
      <div className="flex items-center gap-4">
        <span className="text-xs font-semibold text-slate-500">Tel.</span>
        <span className="text-xs">-</span>
      </div>
    </div>
  )
}
