import type { VariablesOf } from "@graphql-typed-document-node/core"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import * as z from "zod"

import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
  toast,
  ToastAction,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { queryClient } from "#query-client"

const AvinodeTokenSaveDocument = graphql(/* GraphQL */ `
  mutation AvinodeTokenSave($input: MutationAvinodeTokenSaveInput!) {
    avinodeTokenSave(input: $input) {
      id
    }
  }
`)

const avinodeTokens = z.object({
  apiToken: z.string().min(1, "Enter valid API token"),
  authToken: z.string().min(1, "Enter valid Auth token"),
  email: z.string().email("Enter a valid email address"),
})

type AvinodeTokens = z.infer<typeof avinodeTokens>

export const AvinodeModal = ({ disabled }: { disabled: boolean }) => {
  const form = useForm<AvinodeTokens>({
    resolver: zodResolver(avinodeTokens),
    defaultValues: {
      apiToken: "",
      authToken: "",
      email: "",
    },
    reValidateMode: "onSubmit",
  })

  const { mutate } = useMutation({
    mutationFn: (variables: VariablesOf<typeof AvinodeTokenSaveDocument>) =>
      client.request(AvinodeTokenSaveDocument, variables),
    onSuccess: () => {
      form.reset()
      void queryClient.invalidateQueries({ queryKey: ["avinodeOrganizationToken"] })
      toast({
        title: "Success!",
        description: "Avinode Token saved successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description:
          "An error occurred while saving Avinode Token. Check your API and Auth tokens.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const onSubmit = async (data: AvinodeTokens) => {
    mutate({
      input: {
        avinodeToken: data,
      },
    })
  }

  return (
    <Dialog>
      <DialogTrigger asChild disabled={disabled}>
        <Button className="w-full bg-violet-900 p-0 hover:bg-violet-500">Connect</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="pb-5">Enter your Avinode Credentials</DialogTitle>
          <div>
            <Form {...form}>
              <form
                className="flex flex-col gap-4"
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <FormField
                  name="email"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="space-y-0">
                      <FormControl>
                        <div className="flex flex-col gap-2">
                          <FormLabel className="pl-1">Email</FormLabel>
                          <Input
                            placeholder="Email associated with the token"
                            {...field}
                          />
                        </div>
                      </FormControl>
                      <FormMessage className="pl-1 pt-1 text-xs" />
                    </FormItem>
                  )}
                />
                <FormField
                  name="apiToken"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="space-y-0">
                      <FormControl>
                        <div className="flex flex-col gap-2">
                          <FormLabel className="pl-1">API Token*</FormLabel>
                          <Input placeholder="API Token" {...field} />
                        </div>
                      </FormControl>
                      <FormMessage className="pl-1 pt-1 text-xs" />
                    </FormItem>
                  )}
                />
                <FormField
                  name="authToken"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="space-y-0">
                      <FormControl>
                        <div className="flex flex-col gap-2">
                          <FormLabel className="pl-1">Authentication Token*</FormLabel>
                          <Textarea placeholder="Authentication Token" {...field} />
                        </div>
                      </FormControl>
                      <FormMessage className="pl-1 pt-1 text-xs" />
                    </FormItem>
                  )}
                />
                {form.formState.isValid ? (
                  <DialogTrigger
                    type="submit"
                    className="w-full bg-violet-900 p-0 hover:bg-violet-500"
                    asChild
                    disabled={form.formState.isSubmitting}
                  >
                    <Button>Connect</Button>
                  </DialogTrigger>
                ) : (
                  <Button
                    type="submit"
                    className="w-full bg-violet-900 p-0 hover:bg-violet-500"
                  >
                    Connect
                  </Button>
                )}
              </form>
            </Form>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
