export function convertTimeFormat(time: string): string {
  const [hours, minutes] = time.split(":")
  const formattedHours = parseInt(hours, 10).toString()
  return `${formattedHours}:${minutes}`
}

export function convertDateFormat(date: Date | string): string {
  if (!date) {
    return "no info"
  }

  if (typeof date === "string") {
    date = new Date(date)
  }

  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return "Invalid date"
  }
  const dateUtc = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  const month = monthNames[dateUtc.getUTCMonth()]
  const day = dateUtc.getUTCDate()

  return `${month} ${day}`
}
