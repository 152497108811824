import { useQuery } from "@tanstack/react-query"
import { Route, useRouterState } from "@tanstack/react-router"

import { Avatar, AvatarFallback, AvatarImage, Input, Separator } from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { DeleteModal } from "#pages/clients/components/delete-modal.js"
import { EditContact } from "#pages/clients/contacts/components/edit-contact.js"

import { clientsContactsRoute } from "../index.js"

const ClientContactByIdDocument = graphql(/* GraphQL */ `
  query ClientContactById($input: QueryClientContactByIdInput!) {
    clientContactById(input: $input) {
      id
      email
      phone
      firstName
      lastName
    }
  }
`)

export const ContactsEdit = () => {
  const [clientId, , contactId] = useRouterState({
    select: (s) => s.location.pathname.split("/clients")[1].split("/").slice(1),
  })

  const { data, isLoading } = useQuery({
    queryKey: ["clientContactGet"],
    queryFn: () => client.request(ClientContactByIdDocument, { input: { contactId } }),
  })
  if (isLoading) return <div>Loading...</div>

  const { lastName, phone, firstName, email, id } = data!.clientContactById

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-col gap-y-5">
        <div className="flex items-center gap-5">
          <Avatar>
            <AvatarImage src="" />
            <AvatarFallback>VK</AvatarFallback>
          </Avatar>
          <h4 className="text-xl font-semibold">
            {lastName} {firstName}
          </h4>
        </div>
        <span className="text-lg font-semibold">General</span>
        <div className="flex flex-col gap-3 [&_div]:flex [&_div]:w-1/2 [&_div]:items-center [&_div]:justify-between [&_h4]:text-xs [&_h4]:text-slate-900 [&_h4]:dark:text-gray-200 [&_input]:w-2/3 [&_input]:bg-gray-200 [&_input]:dark:bg-gray-900 [&_input]:dark:text-white">
          <div>
            <h4>First Name</h4>
            <Input value={firstName} disabled />
          </div>
          <div>
            <h4>Last Name</h4>
            <Input value={lastName} disabled />
          </div>
          <div>
            <h4>Email*</h4>
            <Input value={email || ""} disabled />
          </div>
          <div>
            <h4>Phone</h4>
            <Input value={phone || ""} disabled />
          </div>
        </div>
        <EditContact
          id={id}
          email={email || ""}
          lastName={lastName}
          firstName={firstName}
          phone={phone || ""}
          clientId={clientId}
        />
      </div>
      <Separator />
      <DeleteModal
        id={id}
        clientId={clientId}
        buttonName="Delete contact"
        modalDescription="Do you want to delete the contact?"
      />
    </div>
  )
}

export const clientsContactsEditRoute = new Route({
  getParentRoute: () => clientsContactsRoute,
  path: "/$contact",
  component: ContactsEdit,
})
