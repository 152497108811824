import React from "react"
import type { UseFormReturn } from "react-hook-form"

import { cn, FormControl, FormField, FormItem, FormMessage } from "@fourel/ui"

import type { FlightsOrganizationByDateQuery } from "#gql/graphql.js"
import { useAccordionStore, useTableStateStore } from "#pages/flights/flights-store.js"
import type { SelectOptionType } from "#pages/flights/models/flight-follow-up-types.js"

import { AccordionContentPreFlight } from "../accordion/accordion-content-pre-flight.js"
import type { FlightFollowUpListFormType } from "../flights-list.js"
import { FlightsListSelect } from "./components/select.js"
import { TableExpandHeader } from "./components/table-expand-header.js"
import { TableStateTag } from "./components/table-state-tag.js"

type PreFlightFieldNames =
  | "acPosition"
  | "slots"
  | "crew"
  | "fuel"
  | "catering"
  | "acStatus"
  | "pax"

interface TableFieldConfig {
  fieldName: PreFlightFieldNames
  options: SelectOptionType[]
}

const tableSubheaderData = [
  { title: "AC position" },
  { title: "Slots" },
  { title: "Crew" },
  { title: "Fuel" },
  { title: "Catering" },
  { title: "AC Status" },
  { title: "Pax" },
]

const acPositionOptions: SelectOptionType[] = [
  { field: "Not departed", value: "not_departed", default: true },
  { field: "Departed", value: "departed", default: false },
]

const slotsOptions: SelectOptionType[] = [
  { field: "Not granted", value: "not_granted", default: true },
  { field: "Granted", value: "granted", default: false },
]

const crewOptions: SelectOptionType[] = [
  { field: "Not arrived", value: "not_arrived", default: true },
  { field: "Arrived", value: "arrived", default: false },
]

const fuelOptions: SelectOptionType[] = [
  { field: "No", value: "no", default: true },
  { field: "Yes", value: "yes", default: false },
]

const cateringOptions: SelectOptionType[] = [
  { field: "Not loaded", value: "not_loaded", default: true },
  { field: "Loaded", value: "loaded", default: false },
]

const acStatusOptions: SelectOptionType[] = [
  { field: "Not ready", value: "not_ready", default: true },
  { field: "Ready", value: "ready", default: false },
]

const paxOptions: SelectOptionType[] = [
  { field: "Not arrived", value: "not_arrived", default: true },
  { field: "Arrived", value: "arrived", default: false },
]

const tableFieldsConfig: TableFieldConfig[] = [
  { fieldName: "acPosition", options: acPositionOptions },
  { fieldName: "slots", options: slotsOptions },
  { fieldName: "crew", options: crewOptions },
  { fieldName: "fuel", options: fuelOptions },
  { fieldName: "catering", options: cateringOptions },
  { fieldName: "acStatus", options: acStatusOptions },
  { fieldName: "pax", options: paxOptions },
]

export const TablePreFlight = ({
  flightData,
  form,
}: {
  flightData: FlightsOrganizationByDateQuery["flightsOrganizationByDate"]
  form: UseFormReturn<FlightFollowUpListFormType>
}) => {
  const { getAccordionState } = useAccordionStore()
  const { isPreFlightTableExpanded } = useTableStateStore()

  return (
    <div>
      <TableExpandHeader tableName="PreFlight" />

      <div
        className={`overflow-hidden ${isPreFlightTableExpanded ? "w-full" : "w-auto"}`}
      >
        <table className={cn(!isPreFlightTableExpanded && "w-52")}>
          <thead className="h-[35px]">
            <tr className="text-muted-foreground border-r text-xs font-bold">
              {isPreFlightTableExpanded
                ? tableSubheaderData.map((item, index) => (
                    <th key={index} className="font-medium">
                      {item.title}
                    </th>
                  ))
                : null}
            </tr>
          </thead>

          <tbody>
            {flightData.length &&
              flightData.map((flight, index) => (
                <React.Fragment key={index}>
                  <tr
                    key={index}
                    className={cn(
                      (index + 1) % 2 === 0 && "bg-blue-300/10",
                      "h-[60px] border border-l-0 text-center text-sm font-semibold",
                    )}
                  >
                    {isPreFlightTableExpanded ? (
                      tableFieldsConfig.map(({ fieldName, options }, fieldIndex) => (
                        <td key={fieldIndex}>
                          <FormField
                            control={form.control}
                            name={`preFlightTableLegs.${index}.${fieldName}`}
                            render={() => (
                              <FormItem className="relative w-32 space-y-0 p-2">
                                <FormControl>
                                  <FlightsListSelect
                                    form={form}
                                    options={options}
                                    fieldName={`preFlightTableLegs.${index}.${fieldName}`}
                                    flightId={flight.id}
                                    flightDocumentsId={flight.flightDocuments?.id}
                                    flightPreparationId={flight.flightPreparation?.id}
                                    preFlightId={flight.preFlight?.id}
                                    flightCateringId={
                                      flight.flightPreparation?.flightCatering[0]?.id
                                    }
                                    index={index}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </td>
                      ))
                    ) : (
                      <td className="flex min-w-36 justify-center p-2">
                        <TableStateTag
                          fieldName={`preFlightTableLegs.${index}`}
                          form={form}
                        />
                      </td>
                    )}
                  </tr>
                  {getAccordionState(flight.id)?.isExpanded && (
                    <tr>
                      <td
                        colSpan={tableSubheaderData.length}
                        className={cn(
                          (index + 1) % 2 === 0 && "bg-blue-300/10",
                          "h-[200px] p-4 align-top",
                          index + 1 === flightData.length && "border-b",
                        )}
                      >
                        <AccordionContentPreFlight
                          form={form}
                          flightIndex={index}
                          flightId={flight.id!}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
