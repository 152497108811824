import { Route } from "@tanstack/react-router"

import { redirectPerIntegration } from "#pages/integration/callback-routes/redirect.js"
import { onboardedGuardRoute } from "#router.js"

export const gmailCallbackRoute = new Route({
  getParentRoute: () => onboardedGuardRoute,
  path: "/integration/gmail",
  beforeLoad: async () => redirectPerIntegration("gmail"),
})
