import { Route } from "@tanstack/react-router"

import { Cancelled } from "#pages/clients/offers/cancelled/cancelled.js"

import { clientsOffersRoute } from "../index.js"

export const clientsOffersCancelledRoute = new Route({
  getParentRoute: () => clientsOffersRoute,
  path: "/cancelled",
  component: Cancelled,
})
