import DollarSignIcon from "lucide-static/icons/dollar-sign.svg"
import MinusIcon from "lucide-static/icons/minus.svg"
import PlusIcon from "lucide-static/icons/plus.svg"
import type { ChangeEvent } from "react"
import { useEffect, useState } from "react"
import InlineSVG from "react-inlinesvg"
import { z } from "zod"

import { Button, Input } from "@fourel/ui"

import type {
  AdvertiseItem,
  SetAdvertiseType,
} from "#pages/advertise/models/advertise-data-types.js"

export const AdvertiseBalance = ({
  advertise,
  setAdvertise,
}: {
  advertise: AdvertiseItem
  setAdvertise: SetAdvertiseType
}) => {
  const MIN_ADVERTISE_PRICE = 500
  const BUDGET_UPDATE_VALUE = 50
  const [inputBudget, setInputBudget] = useState<number>(advertise.price)
  const [isWrongBudgetInput, setIsWrongBudgetInput] = useState(
    advertise.price < MIN_ADVERTISE_PRICE,
  )

  useEffect(() => {
    setInputBudget(advertise.price)
  }, [advertise.price])

  const increaseBudgetHandler = () => {
    const newPrice = advertise.price + BUDGET_UPDATE_VALUE
    const newBudget = advertise.budget + BUDGET_UPDATE_VALUE
    setAdvertise({ price: newPrice, budget: newBudget })
    setIsWrongBudgetInput(newPrice < MIN_ADVERTISE_PRICE)
  }

  const decreaseBudgetHandler = () => {
    const newPrice = advertise.price - BUDGET_UPDATE_VALUE
    const newBudget = advertise.budget - BUDGET_UPDATE_VALUE
    if (newPrice >= MIN_ADVERTISE_PRICE) {
      setAdvertise({ price: newPrice, budget: newBudget })
    }
  }

  const inputPriceHandler = (element: ChangeEvent<HTMLInputElement>) => {
    const inputValue = element.target.value
    const schema = z.string().regex(/^\d*$/).max(10)
    const validationResult = schema.safeParse(inputValue)
    const delta = advertise.budget - advertise.price
    if (validationResult.success) {
      const parsedValue = inputValue === "" ? 0 : parseInt(inputValue, 10)
      setIsWrongBudgetInput(parsedValue < MIN_ADVERTISE_PRICE)
      setAdvertise({ price: parsedValue, budget: parsedValue + delta })
    }
  }

  return (
    <>
      <p className="w-full text-start text-2xl font-bold">
        Pick you balance and choose region
      </p>
      <p className="w-full text-start">
        Outline the desired budget and align it with the available region
      </p>
      <div className="flex flex-wrap items-center gap-2">
        <p className="flex-shrink-0 text-gray-500">Budget</p>
        <div className="flex items-center justify-start gap-2">
          <Button variant="outline" onClick={decreaseBudgetHandler}>
            <InlineSVG src={MinusIcon} className="w-5" />
          </Button>
          <InlineSVG src={DollarSignIcon} className="min-w-4 max-w-4" />
          <Input
            type="text"
            value={inputBudget}
            onChange={(element) => inputPriceHandler(element)}
          />
          <Button variant="outline" onClick={increaseBudgetHandler}>
            <InlineSVG src={PlusIcon} className="w-5" />
          </Button>
        </div>
      </div>
      {isWrongBudgetInput && (
        <p className="w-full text-start text-sm text-red-500">
          Please increase the budget, minimum quote is 500$
        </p>
      )}
      <p className="w-full text-start">
        Regions you can efficiently advertise with the chosen budget
      </p>
      <p className="text-sm text-gray-500">
        (keep in mind that depending on a demand, advertising cost in specific regions
        differs due to a competition level)
      </p>
    </>
  )
}
