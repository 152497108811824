import type { VariablesOf } from "@graphql-typed-document-node/core"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import PenLine from "lucide-static/icons/pen-line.svg"
import React from "react"
import { useForm } from "react-hook-form"
import InlineSVG from "react-inlinesvg"
import * as z from "zod"

import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ToastAction,
  useToast,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { queryClient } from "#query-client"

const ClientContactUpdateDocument = graphql(/* GraphQL */ `
  mutation ClientContactUpdate($input: MutationClientContactUpdateInput!) {
    clientContactUpdate(input: $input) {
      id
      email
      firstName
      lastName
      phone
    }
  }
`)

const contactType = z.object({
  email: z.string().email().min(7),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  phone: z.string().min(1),
})

type ContactType = z.infer<typeof contactType>

export const EditContact = ({
  email,
  id,
  phone,
  firstName,
  lastName,
  clientId,
}: {
  email: string
  id: string
  phone: string
  firstName: string
  lastName: string
  clientId: string
}) => {
  const { toast } = useToast()
  const form = useForm<ContactType>({
    resolver: zodResolver(contactType),
    defaultValues: {
      email,
      firstName,
      lastName,
      phone,
    },
    reValidateMode: "onChange",
  })

  const { mutate } = useMutation({
    mutationFn: (variables: VariablesOf<typeof ClientContactUpdateDocument>) =>
      client.request(ClientContactUpdateDocument, variables),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["clientContactGet"] })
      toast({
        title: "Success!",
        description: "Client has been updated successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while updating a client.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const onSubmit = (data: z.infer<typeof contactType>) => {
    mutate({
      input: {
        clientId,
        contactId: id,
        contact: { ...data, preferredContactMethod: "email" },
      },
    })
  }
  return (
    <Dialog>
      <DialogTrigger className="w-max cursor-pointer">
        <Button className="flex items-center gap-2" asChild variant="outline">
          <span className="flex w-max items-center gap-2">
            <InlineSVG src={PenLine} className="size-4" /> Change
          </span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>General</DialogTitle>
        </DialogHeader>
        <div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-5">
              <div className="flex flex-col gap-4">
                {["firstName", "lastName", "email", "phone"].map((it) => (
                  <FormField
                    key={it}
                    control={form.control}
                    name={it as keyof ContactType}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <div className="flex items-center">
                          <FormLabel className="w-1/2">
                            {it
                              .replace(/([A-Z])/g, " $1")
                              .replace(/^./, (str) => str.toUpperCase())}
                          </FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              defaultValue={form.getValues(it as keyof ContactType)}
                            />
                          </FormControl>
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
              </div>
              <div className="flex gap-4">
                <DialogTrigger type="button" className="w-max" asChild>
                  <Button variant="outline">Cancel</Button>
                </DialogTrigger>
                <DialogTrigger
                  type="submit"
                  className="w-max bg-violet-700 hover:bg-violet-800"
                  asChild
                  disabled={!form.formState.isValid}
                >
                  <Button>Save</Button>
                </DialogTrigger>
              </div>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
