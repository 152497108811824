import { useQuery } from "@tanstack/react-query"
import { Route, useRouterState } from "@tanstack/react-router"
import type { ColumnDef, ColumnFiltersState, SortingState } from "@tanstack/react-table"
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import ChevronDownIcon from "lucide-static/icons/chevron-down.svg"
import ChevronUpIcon from "lucide-static/icons/chevron-up.svg"
import SearchIcon from "lucide-static/icons/search.svg"
import UploadIcon from "lucide-static/icons/upload.svg"
import { useMemo, useState } from "react"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { clientsRoute } from "#pages/clients/index.js"
import { useTableResize } from "#pages/clients/utils/use-table-resize.js"

import { DeleteDocumentModal } from "./components/delete-document-modal.js"

export type DocumentsDataType = {
  id: string
  documentName: string
  owner: string
  tag: string
  url: string
}

const handleDownloadPdf = (
  url: string,
  name: string,
  removeAfterDownload: boolean = true,
) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.setAttribute("download", `${name}.pdf`)
      document.body.appendChild(link)
      link.click()
      if (removeAfterDownload) {
        document.body.removeChild(link)
      }
    })
    .catch((error) => console.error("Error downloading PDF:", error))
}

const handleDownloadAllPdf = (documentsData: DocumentsDataType[]) => {
  documentsData.forEach((item) => {
    handleDownloadPdf(`/api/file/${item.url}`, item.documentName, false)
  })
}

const ClientDocumentsDocument = graphql(/* GraphQL */ `
  query ClientDocuments($input: QueryClientDocumentsInput!) {
    clientDocuments(input: $input) {
      id
      owner
      documentName
      tag
      url
    }
  }
`)

export const columns: ColumnDef<DocumentsDataType>[] = [
  {
    accessorKey: "documentName",
    header: ({ column }) => (
      <button
        className="flex cursor-pointer items-center gap-2 font-medium"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Name of document
        <InlineSVG
          src={column.getIsSorted() === "asc" ? ChevronUpIcon : ChevronDownIcon}
          className="min-w-6 pr-2"
        />
      </button>
    ),
    cell: ({ row }) => {
      const docName = row.getValue("documentName") as string
      const docUrl = row.getValue("url") as string
      return (
        <button
          className="cursor-pointer font-semibold text-violet-800 hover:underline dark:text-violet-700"
          onClick={() => handleDownloadPdf(`api/file/${docUrl}`, docName)}
        >
          {row.getValue("documentName")}
        </button>
      )
    },
  },
  {
    accessorKey: "owner",
    header: ({ column }) => (
      <button
        className="flex cursor-pointer items-center gap-2 font-medium"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Owner
        <InlineSVG
          src={column.getIsSorted() === "asc" ? ChevronUpIcon : ChevronDownIcon}
          className="min-w-6 pr-2"
        />
      </button>
    ),
    cell: ({ row }) => <div>{row.getValue("owner")}</div>,
  },
  {
    accessorKey: "tag",
    header: ({ column }) => (
      <button
        className="flex cursor-pointer items-center gap-2 font-medium"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Tag
        <InlineSVG
          src={column.getIsSorted() === "asc" ? ChevronUpIcon : ChevronDownIcon}
          className="min-w-6 pr-2"
        />
      </button>
    ),
    cell: ({ row }) => {
      return <div className="font-medium">{row.getValue("tag")}</div>
    },
  },
  {
    accessorKey: "id",
    header: () => <div>Action</div>,
    cell: ({ row }) => {
      const id = row.getValue("id") as string
      const url = row.getValue("url") as string
      return (
        <div className="flex gap-2">
          <DeleteDocumentModal
            documentId={id}
            documentKey={url}
            buttonName="Delete"
            modalDescription=" Are you sure you want to delete document?"
          />
        </div>
      )
    },
  },
  {
    id: "url",
    accessorKey: "url",
    header: () => null,
    cell: () => null,
  },
]

export function Documents() {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [rowSelection, setRowSelection] = useState({})
  const dynamicTableWidth = useTableResize()

  const [clientId] = useRouterState({
    select: (s) => s.location.pathname.split("/clients")[1].split("/").slice(1),
  })

  const { data: queryData } = useQuery({
    queryKey: ["clientDocs"],
    queryFn: () => client.request(ClientDocumentsDocument, { input: { clientId } }),
  })

  const data: DocumentsDataType[] = useMemo(
    () =>
      queryData?.clientDocuments.map((it) => ({
        id: it.id,
        documentName: it.documentName,
        owner: it.owner,
        tag: it.tag,
        url: it.url,
      })) ?? [],
    [queryData],
  )

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      columnVisibility: {
        url: false,
        key: false,
      },
    },
  })

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col items-start justify-between gap-4 lg:flex-row lg:items-center">
        <Button variant="outline" onClick={() => handleDownloadAllPdf(data)}>
          <span className="pr-4">Upload all</span>
          <InlineSVG src={UploadIcon} className="w-4" />
        </Button>
        <div className="relative">
          <Input
            placeholder="Search"
            value={(table.getColumn("documentName")?.getFilterValue() as string) ?? ""}
            onChange={(event) =>
              table.getColumn("documentName")?.setFilterValue(event.target.value)
            }
            className="max-w-sm"
          />
          <InlineSVG
            src={SearchIcon}
            className="absolute right-2 top-2 w-4 text-gray-400"
          />
        </div>
      </div>
      <div
        className="overflow-x-auto rounded-md border"
        style={{ width: dynamicTableWidth }}
      >
        <Table className="whitespace-nowrap">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="w-1/3">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export const clientsDocumentsRoute = new Route({
  getParentRoute: () => clientsRoute,
  path: "$client/documents",
  component: Documents,
})
