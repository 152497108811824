import { Link } from "@tanstack/react-router"
import LogOutIcon from "lucide-static/icons/log-out.svg"
import SettingsIcon from "lucide-static/icons/settings.svg"
import InlineSVG from "react-inlinesvg"

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@fourel/ui"

import { useOnboardedUserInfo } from "#store/user-info.js"
import { signOut } from "#utils/auth.js"

export function AccountDropdown({ open }: { open: boolean }) {
  const { me } = useOnboardedUserInfo()
  const userInitials = me.user.name.includes(" ")
    ? me.user.name
        .split(" ")
        .map((part) => part[0].toUpperCase())
        .join("")
    : me.user.name[0].toUpperCase()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className={cn("relative h-8 w-8 rounded-full", !open && "md:hidden")}
        >
          <Avatar className="h-9 w-9">
            <AvatarImage src="src" alt="@fourel" />
            <AvatarFallback data-testid="dashboard-avatar-btn">
              {userInitials}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className={cn("w-[224px]", !open && "md:hidden")}>
        <div className="flex items-center px-2 py-[0.375rem] text-xs/[20px] text-slate-500">
          <span>My Account</span>
        </div>
        <DropdownMenuSeparator />
        <DropdownMenuItem className="cursor-pointer">
          <Link from="/$slug" to="settings/members" className="flex w-full items-center">
            <InlineSVG src={SettingsIcon} className="mr-2 h-4 w-4" />
            <span>Settings</span>
            <DropdownMenuShortcut>⌘⇧B</DropdownMenuShortcut>
          </Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={signOut} className="cursor-pointer">
          <InlineSVG src={LogOutIcon} className="mr-2 h-4 w-4" />
          <span>Log out</span>
          <DropdownMenuShortcut>⌘⇧B</DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
