import { useMutation, useQuery } from "@tanstack/react-query"

import { Button, toast, ToastAction } from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { AvinodeModal } from "#pages/integration/components/avinode-modal.js"
import AvinodeLogo from "#pages/integration/icons/avinode.png"
import { queryClient } from "#query-client"

// avinodeTokenRevoke
const AvinodeTokenExistsDocument = graphql(/* GraphQL */ `
  query AvinodeTokenExists {
    avinodeTokenExists
  }
`)

const AvinodeTokenRevokeDocument = graphql(/* GraphQL */ `
  mutation AvinodeTokenRevoke {
    avinodeTokenRevoke
  }
`)

export const Avinode = () => {
  const { data } = useQuery({
    queryKey: ["avinodeOrganizationToken"],
    queryFn: () => client.request(AvinodeTokenExistsDocument),
  })

  const { mutate } = useMutation({
    mutationFn: () => client.request(AvinodeTokenRevokeDocument),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["avinodeOrganizationToken"] })
      toast({
        title: "Success!",
        description: "Avinode Token deleted successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while deleting Avinode Token.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  return (
    <div className="flex w-1/3 flex-col items-center justify-between gap-2 border border-gray-300 p-5 sm:w-1/4">
      <img src={AvinodeLogo} alt="HubSpotLogo" className="h-28 w-auto" />
      <span>Avinode</span>
      {data?.avinodeTokenExists ? (
        <Button
          onClick={() => mutate()}
          className="w-full bg-red-500 p-0 hover:bg-red-600"
        >
          Revoke tokens
        </Button>
      ) : (
        <AvinodeModal disabled={data?.avinodeTokenExists || false} />
      )}
    </div>
  )
}
