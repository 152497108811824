import { Outlet, useRouterState } from "@tanstack/react-router"

import { AdvertiseHeader } from "./components/advertise-header.js"

export const Advertise = () => {
  const subMenuName = useRouterState({
    select: (s) => s.location.pathname,
  })

  return (
    <>
      {subMenuName.includes("/advertise") && <AdvertiseHeader />}

      <div className="h-[calc(100vh-176px)] overflow-y-auto md:h-[calc(100vh-64px)]">
        <Outlet />
      </div>
    </>
  )
}
