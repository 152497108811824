import { Link, useNavigate } from "@tanstack/react-router"
import CheckIcon from "lucide-static/icons/check.svg"
import ChevronUpDownIcon from "lucide-static/icons/chevrons-up-down.svg"
import LogOutIcon from "lucide-static/icons/log-out.svg"
import UsersIcon from "lucide-static/icons/users.svg"
import InlineSVG from "react-inlinesvg"

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@fourel/ui"

import { AddMember } from "#components/add-member.js"
import { FeatureGate } from "#feature-gate/feature-gate.js"
import { getFragmentData } from "#gql"
import {
  FetchUserInfo_OrganizationFragmentDocument,
  useOnboardedUserInfo,
} from "#store/user-info.js"
import { signOut } from "#utils/auth.js"

import { AddCompanyDialog } from "./add-company-dialog.js"

export function MobileCompanyDropdown() {
  const {
    currentOrg: { id, name },
    me: { user },
    selectOrganization,
  } = useOnboardedUserInfo()
  const navigate = useNavigate()
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild className="w-[100%]">
          <Button
            variant="ghost"
            className={cn(
              "flex h-12 justify-between border-[1px] border-violet-500 p-2 text-white hover:bg-transparent hover:text-white",
            )}
          >
            <div className="flex items-center gap-2">
              <Avatar className="h-8 w-8 rounded-md">
                <AvatarImage src="src" className="rounded-md p-1" />
                <AvatarFallback className="rounded-md text-black dark:text-white">
                  {name.slice(0, 2).toUpperCase()}
                </AvatarFallback>
              </Avatar>
              <span>{name}</span>
            </div>
            <InlineSVG
              src={ChevronUpDownIcon}
              className={cn("ml-2 h-4 w-4 align-middle text-white")}
            />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[252px]">
          <span className="ml-2 select-none pb-[10px] text-xs/[20px] text-slate-500">
            {user.email}
          </span>
          {getFragmentData(
            FetchUserInfo_OrganizationFragmentDocument,
            user.organizations,
          ).map((orgData) => {
            return (
              <DropdownMenuItem
                key={orgData.id}
                className="cursor-pointer justify-between text-xs/[20px]"
                onClick={() => {
                  selectOrganization(orgData.slug)
                  void navigate({
                    to: "/$slug",
                    params: {
                      slug: orgData.slug,
                    },
                  })
                }}
              >
                <div className="flex items-center gap-2">
                  <Avatar>
                    <AvatarImage src="src" className="p-1" />
                    <AvatarFallback>
                      {orgData.name.slice(0, 2).toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                  <span>{orgData.name}</span>
                </div>
                {id === orgData.id && (
                  <InlineSVG src={CheckIcon} className="ml-2 h-4 w-4 text-slate-500" />
                )}
              </DropdownMenuItem>
            )
          })}

          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem className="cursor-pointer">
              <Link from="$slug" to="./settings/notifications" className="w-full">
                <span>Workspace settings</span>
              </Link>
            </DropdownMenuItem>
            <FeatureGate name="SettingsBillingRoute">
              <DropdownMenuItem className="cursor-pointer">
                <Link from="/$slug" to="./settings/billing" className="w-full">
                  <span>Billing</span>
                </Link>
              </DropdownMenuItem>
            </FeatureGate>
            <DropdownMenu>
              <AddCompanyDialog />
            </DropdownMenu>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem className="cursor-pointer">
            <InlineSVG src={UsersIcon} className="mr-2 h-4 w-4" />
            <Link from="$slug" to="./settings/members" className="w-full">
              <span>Members</span>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuGroup>
            <DropdownMenu>
              <AddMember asMenu open />
            </DropdownMenu>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={signOut} className="cursor-pointer">
            <InlineSVG src={LogOutIcon} className="mr-2 h-4 w-4" />
            <span>Log out</span>
            <DropdownMenuShortcut>⌘⇧B</DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
