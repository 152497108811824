import { useState } from "react"

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  SeparatorCustom,
} from "@fourel/ui"

import type { QuoteFormValuesType } from "#components/quotations/create-quote/models/trip-types.js"
import { formatDate } from "#components/utils/format-date-time.js"

export const QuoteSummaryModal = ({
  isSubmitting,
  onSubmitHandler,
  clientName,
  selectedValues,
}: {
  isSubmitting: boolean
  onSubmitHandler: () => void
  clientName: string
  selectedValues: QuoteFormValuesType
}) => {
  const [open, setOpen] = useState(false)
  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button
            variant="primary"
            disabled={isSubmitting}
            aria-label="open submit modal"
          >
            Submit
          </Button>
        </DialogTrigger>
        <DialogContent className="max-h-[calc(100vh-5rem)] max-w-[calc(100vw-2rem)] space-y-2 md:w-[650px]">
          <DialogHeader>
            <DialogTitle>Create Quote</DialogTitle>
          </DialogHeader>
          <div className="flex w-full flex-col flex-wrap items-center justify-between gap-2 rounded-lg bg-violet-700/90 p-3 text-sm text-white">
            <div className="flex w-full justify-between gap-2">
              <p>
                Client: <span className="font-bold">{clientName}</span>
              </p>
              <p>
                Currency: <span className="font-bold">{selectedValues.currency}</span>
              </p>
            </div>
            <div className="flex w-full justify-between gap-2">
              <p>
                Price: <span className="font-bold">{selectedValues.price}$</span>
              </p>
            </div>
          </div>
          {selectedValues.legs.map((leg, index) => (
            <section className="flex flex-col gap-1" key={index}>
              <h3 className="font-semibold">Leg {index + 1}</h3>
              <div className="flex w-full items-center gap-7">
                <div className="flex flex-col items-start gap-2 sm:flex-row">
                  <span className="text-xl font-bold md:text-3xl">
                    {leg.departureAirport.code}
                  </span>
                  <span className="text-xs text-slate-900 dark:text-slate-400">
                    {leg.arrivalDate ? formatDate(leg.departureDate) : "No arrival date"}
                  </span>
                </div>
                <SeparatorCustom className="w-1/2 bg-violet-700 dark:bg-slate-200" />
                <div className="flex flex-col items-start gap-2 sm:flex-row">
                  <span className="text-xl font-bold md:text-3xl">
                    {leg.arrivalAirport.code}
                  </span>
                  <span className="text-xs text-slate-900 dark:text-slate-400">
                    {leg.arrivalDate ? formatDate(leg.arrivalDate) : "No arrival date"}
                  </span>
                </div>
              </div>
              <p className="text-sm">
                Aircraft model:{" "}
                <span className="font-semibold">{leg.aircraft.registration}</span>
              </p>
            </section>
          ))}
          <div className="flex gap-4">
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>
            <Button
              variant="primary"
              onClick={() => {
                onSubmitHandler()
                setOpen(false)
              }}
            >
              Create
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
