import ChevronLeftIcon from "lucide-static/icons/chevron-left.svg"
import ChevronRightIcon from "lucide-static/icons/chevron-right.svg"
import type { MouseEvent } from "react"
import InlineSVG from "react-inlinesvg"

import { Button } from "@fourel/ui"

import { useTableStateStore } from "#pages/flights/flights-store.js"

interface TableHeaderProps {
  tableName: string
}

export const TableExpandHeader = ({ tableName }: TableHeaderProps) => {
  const {
    isGeneralTableExpanded,
    isContractTableExpanded,
    isPreFlightTableExpanded,
    isPreparationTableExpanded,
    toggleContractTable,
    togglePreFlightTable,
    toggleGeneralTable,
    togglePreparationTable,
  } = useTableStateStore()

  const tableStates: Record<string, { isExpanded: boolean; toggle: () => void }> = {
    General: { isExpanded: isGeneralTableExpanded, toggle: toggleGeneralTable },
    Preparation: {
      isExpanded: isPreparationTableExpanded,
      toggle: togglePreparationTable,
    },
    PreFlight: {
      isExpanded: isPreFlightTableExpanded,
      toggle: togglePreFlightTable,
    },
    Contract: { isExpanded: isContractTableExpanded, toggle: toggleContractTable },
  }

  const { isExpanded, toggle } = tableStates[tableName] || {}

  const toggleTableHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    toggle && toggle()
  }

  const truncatedTableName =
    !isExpanded && tableName !== "General" ? `${tableName.slice(0, 11)}...` : tableName

  return (
    <div className="flex items-center justify-end gap-2 border-r border-t px-2 pt-2">
      <h3 className="text-muted-foreground text-sm font-medium">{truncatedTableName}</h3>
      <Button variant="outline" onClick={toggleTableHandler} className="size-6 p-0">
        <InlineSVG
          src={isExpanded ? ChevronLeftIcon : ChevronRightIcon}
          className="h-4 w-4"
        />
      </Button>
    </div>
  )
}
