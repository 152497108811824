import * as Sentry from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"
import { RouterProvider } from "@tanstack/react-router"
import AlertCircle from "lucide-static/icons/alert-circle.svg"
// import { ThemeProvider } from "next-themes"
import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import InlineSVG from "react-inlinesvg"
import CacheProvider from "react-inlinesvg/provider"

import { Alert, AlertDescription, AlertTitle, Toaster, TooltipProvider } from "@fourel/ui"

import Logo from "#images/icons/logo.svg"
import { queryClient } from "#query-client.js"
import { createRouter } from "#router.js"

import "#services/sentry.js"
import "#services/supertokens.js"

import { useUserInfo } from "#store/user-info.js"

import "./globals.css"

import {
  attemptRefreshingSession,
  doesSessionExist,
} from "supertokens-web-js/recipe/session"

// If user is logged in. Good, it will refresh. If not, it will do nothing :)
setInterval(
  async () => {
    const sessionExists = await doesSessionExist()
    if (sessionExists) {
      await attemptRefreshingSession()
    }
  },
  60 * 60 * 1000,
)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
void attemptRefreshingSession().then(() =>
  useUserInfo
    .getState()
    .fetchUserInfo()
    .then(() => {
      root.render(
        <CacheProvider>
          {/* <ThemeProvider attribute="class"> */}
          <QueryClientProvider client={queryClient}>
            <Suspense>
              <Toaster />
              <TooltipProvider>
                <RouterProvider router={createRouter()} />
              </TooltipProvider>
            </Suspense>
          </QueryClientProvider>
          {/* </ThemeProvider> */}
        </CacheProvider>,
      )
    })
    .catch((err) => {
      Sentry.captureException(err)
      root.render(
        <div>
          <svg className="absolute left-8 top-4 z-10 h-[31px] w-[150px] text-violet-900 dark:text-inherit">
            <use xlinkHref={`#${Logo}`} />
          </svg>
          <React.StrictMode>
            {/* <ThemeProvider attribute="class"> */}
            <div className="h-[100vh] w-[100vw]">
              <Alert
                variant="destructive"
                className="-translate-y-1/2te absolute left-1/2 top-1/2 w-max -translate-x-1/2 transform"
              >
                <InlineSVG src={AlertCircle} className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>
                  Something wrong has happened, please refresh the page
                </AlertDescription>
              </Alert>
            </div>
            {/* </ThemeProvider> */}
          </React.StrictMode>
        </div>,
      )
    }),
)
