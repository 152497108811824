import { Outlet, Route } from "@tanstack/react-router"

import { clientsRoute } from "#pages/clients/index.js"

export const Offers = () => {
  return (
    <div className="-m-6">
      <Outlet />
    </div>
  )
}

export const clientsOffersRoute = new Route({
  getParentRoute: () => clientsRoute,
  path: "$client/offers",
  component: Offers,
})
