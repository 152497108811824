import { Link, useRouter, useRouterState } from "@tanstack/react-router"

import { Button } from "@fourel/ui"

import { useOnboardedUserInfo } from "#store/user-info.js"

export const TripRequestsHeader = () => {
  const router = useRouter()

  const category = useRouterState({
    select: (s) => s.location.pathname.split("/"),
  })

  const { currentOrg } = useOnboardedUserInfo()

  const subcategory = category[category.length - 1]

  const headerNames: Record<string, string> = {
    "trip-requests": "Active",
    add: "Create Trip requests",
  }

  const headerName = headerNames[subcategory] || subcategory
  const formattedHeaderName = `${headerName.slice(0, 1).toUpperCase()}${headerName.slice(1)}`

  return headerName === "Create Trip requests" ? (
    <div className="flex h-16 w-full items-center gap-4 border-b border-gray-200 bg-gray-50 p-4 px-6 dark:bg-gray-950">
      <Button
        variant="outline"
        className="w-10 rounded-full text-base"
        onClick={() => router.history.back()}
      >
        &larr;
      </Button>
      <h1 className="text-xl font-bold md:text-2xl">{formattedHeaderName}</h1>
    </div>
  ) : (
    <div className="flex h-16 w-full items-center justify-between gap-2 border-b border-gray-200 bg-gray-50 p-4 px-6 dark:bg-gray-950">
      <h1 className="text-xl font-bold md:text-2xl">
        Trip request \ {formattedHeaderName}
      </h1>
      <Link to="/$slug/trip-requests/add" params={{ slug: currentOrg.slug }}>
        <Button variant="primary" className="flex flex-col p-2 text-xs md:p-4 md:text-sm">
          <span>Add Trip requests</span>
        </Button>
      </Link>
    </div>
  )
}
