import { useQuery } from "@tanstack/react-query"
import debounce from "debounce"
import CheckIcon from "lucide-static/icons/check.svg"
import ChevronsUpDownIcon from "lucide-static/icons/chevrons-up-down.svg"
import { useState } from "react"
import type { ControllerRenderProps } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  FormItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@fourel/ui"

import { QueryKeys } from "#constants/query-keys.js"
import { graphql } from "#gql"
import { client } from "#graphql-client.js"
import type { ClientFormType } from "#pages/clients/add-client/index.js"

const AirportByNameDocument = graphql(/* GraphQL */ `
  query AirportBySearch($input: QueryAirportByNameInput!) {
    airportByName(input: $input) {
      id
      name
      icaoCode
      iataCode
    }
  }
`)

interface AirportModelSelectProps {
  field: ControllerRenderProps<ClientFormType, "primaryDepartureAirport">
  fieldName: "primaryDepartureAirport"
  setValue: (
    field: "primaryDepartureAirport",
    value: { id: string; label: string },
  ) => void
}

export interface AirportType {
  id: string
  name: string
  icaoCode: string
  iataCode: string
}

export const ClientAirportSelect = ({
  field,
  fieldName,
  setValue,
}: AirportModelSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [airportSearchParam, setAirportSearchParam] = useState<string>("")
  const { data } = useQuery({
    queryKey: [QueryKeys.Airport.GetByName, { airportSearchParam }],
    queryFn: () =>
      client.request(AirportByNameDocument, {
        input: { airportName: airportSearchParam },
      }),
    enabled: !!airportSearchParam,
  })
  const handleInputChange = debounce((value) => {
    if (value.length > 2) {
      setAirportSearchParam(value)
    } else {
      setAirportSearchParam("")
    }
  }, 500)

  const airportSelectHandler = (item: AirportType) => {
    setValue(fieldName, { id: item.id, label: item.name })
    setIsOpen(false)
  }
  return (
    <FormItem className="flex w-full flex-col gap-0">
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              variant="outline"
              role="combobox"
              className={cn(
                "w-full cursor-pointer justify-between text-xs font-medium text-slate-900",
                !field.value?.label && "text-muted-foreground",
              )}
            >
              {field.value?.label ? (
                <div className="flex w-full justify-between">
                  <p>{field.value.label}</p>
                  <InlineSVG
                    src={ChevronsUpDownIcon}
                    className="ml-2 size-4 shrink-0 opacity-50"
                  />
                </div>
              ) : (
                <p>Search Airport</p>
              )}
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent className="w-72 p-0">
          <Command shouldFilter={false}>
            <CommandInput
              placeholder="Search Airport"
              className="placeholder:text-xs"
              onValueChange={handleInputChange}
            />
            <CommandEmpty>No airport found.</CommandEmpty>
            <CommandList className="max-h-60">
              <CommandGroup>
                {data?.airportByName.map((item) => (
                  <CommandItem
                    value={item.name}
                    key={item.id}
                    onSelect={() => airportSelectHandler(item as AirportType)}
                    className="cursor-pointer text-xs"
                  >
                    <InlineSVG
                      src={CheckIcon}
                      className={cn(
                        "mr-2 size-4",
                        field.value && item.name === field.value.label
                          ? "opacity-100"
                          : "opacity-0",
                      )}
                    />
                    {item.name} - {item.icaoCode || item.iataCode}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </FormItem>
  )
}
