import { Route } from "@tanstack/react-router"

import { redirectPerIntegration } from "#pages/integration/callback-routes/redirect.js"
import { onboardedGuardRoute } from "#router.js"

export const hubspotCallbackRoute = new Route({
  getParentRoute: () => onboardedGuardRoute,
  path: "/integration/hubspot",
  beforeLoad: async () => redirectPerIntegration("hubspot"),
})
