import type { CombinedTrip, QuotesByAircraft } from "#pages/offers/models/offer-types.js"

export const getTimeDifference = ({
  startDateTime,
  endDateTime,
  startTime,
  endTime,
}: {
  startDateTime: string
  endDateTime: string
  startTime: string
  endTime: string
}) => {
  const startDate = startDateTime.split("T")[0]
  const endDate = endDateTime.split("T")[0]

  const myStartDateTime = `${startDate}T${startTime}.000Z`
  const myEndDateTime = `${endDate}T${endTime}.000Z`

  const startDateTimeInMs = new Date(myStartDateTime).getTime()
  const endDateTimeInMs = new Date(myEndDateTime).getTime()

  const differenceInMilliseconds = endDateTimeInMs - startDateTimeInMs
  const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000)

  const hours = Math.floor(differenceInMinutes / 60)
    .toString()
    .padStart(2, "0")
  const minutes = (differenceInMinutes % 60).toString().padStart(2, "0")

  return `${hours}:${minutes}`
}

export const tripOptionsQuoteLegsToMap = (tripOption: CombinedTrip) =>
  tripOption.quotes.flatMap((quote) => quote.quoteLegs)

export const aircraftByLegs = (tripOption: CombinedTrip) => {
  const airplaneMap = new Map()
  tripOptionsQuoteLegsToMap(tripOption).forEach((leg) => {
    const airplane = leg.aircraft
    if (!airplaneMap.has(airplane.id)) {
      airplaneMap.set(airplane.id, {
        airplane,
        legs: [],
      })
    }
    airplaneMap.get(airplane.id).legs.push(leg)
  })
  return Array.from(airplaneMap.values())
}

export const quotesByAircraft = (tripOption: CombinedTrip) => {
  return tripOption.quotes.reduce((acc, quote) => {
    quote.quoteLegs.forEach((leg) => {
      acc[leg.aircraft.id] = acc[leg.aircraft.id] || {}
      acc[leg.aircraft.id].price = quote.price
      acc[leg.aircraft.id].currency = quote.currency
      acc[leg.aircraft.id].id = quote.id
    })
    return acc
  }, {} as QuotesByAircraft)
}
