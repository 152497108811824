import CheckIcon from "lucide-static/icons/check.svg"
import XIcon from "lucide-static/icons/x.svg"
import type { UseFormReturn } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import { cn } from "@fourel/ui"

import type { FlightFollowUpListFormType } from "../../flights-list.js"

interface TableStateTagProps {
  fieldName:
    | `generalTableLegs.${number}`
    | `preparationTableLegs.${number}`
    | `preFlightTableLegs.${number}`
    | `contractPaymentTableLegs.${number}`
  form: UseFormReturn<FlightFollowUpListFormType>
}

export function TableStateTag({ fieldName, form }: TableStateTagProps) {
  const tableCurrentValues = form.getValues(fieldName)
  const defaultValues = [
    "not_selected",
    "not_departed",
    "not_granted",
    "not_arrived",
    "no",
    "not_loaded",
    "not_ready",
    "not_signed",
    "not_paid",
  ]

  const isDefault =
    tableCurrentValues &&
    Object.values(tableCurrentValues).some((value) => defaultValues.includes(value))
  const bgColor = isDefault ? "bg-red-100" : "bg-green-100"

  return (
    <div
      className={cn(
        bgColor,
        "flex h-10 w-24 items-center justify-center rounded-lg border",
      )}
    >
      <InlineSVG src={isDefault ? XIcon : CheckIcon} className="size-4" />
    </div>
  )
}
