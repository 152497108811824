import PaperclipIcon from "lucide-static/icons/paperclip.svg"
import UploadIcon from "lucide-static/icons/upload.svg"
import { useState } from "react"
import InlineSVG from "react-inlinesvg"

import {
  FileInput,
  FileUploader,
  FileUploaderContent,
  FileUploaderItem,
} from "@fourel/ui"

interface UploadAreaProps {
  onFileChange: (files: File[]) => void
  setValue: (field: "files", value: File[]) => void
  revalidate: (name: "files") => void
}

const FileSvgDraw = () => (
  <>
    <InlineSVG src={UploadIcon} className="mb-2 h-auto w-5 text-gray-700" />
    <p className="mb-1 text-sm text-gray-500 dark:text-gray-400">
      <span className="font-semibold">Click to upload</span>
      &nbsp; or drag and drop
    </p>
  </>
)

export const UploadArea = ({ onFileChange, setValue, revalidate }: UploadAreaProps) => {
  const [files, setFiles] = useState<File[]>([])

  const dropZoneConfig = {
    maxFiles: 4630,
    maxSize: 1024 * 1024 * 4,
    multiple: true,
  }

  const handleFileChange = (newFile: File[] | null) => {
    setFiles(newFile ?? [])
    if (newFile && newFile.length > 0) {
      onFileChange(newFile)
      setValue("files", newFile)
    } else if (newFile && newFile.length === 0) {
      setValue("files", [])
    }
    revalidate("files")
  }

  return (
    <FileUploader
      value={files}
      onValueChange={(newFiles) => handleFileChange(newFiles)}
      dropzoneOptions={dropZoneConfig}
      className="bg-background relative max-h-[95vh] rounded-lg p-2"
    >
      <FileInput className="bg-gray-200 outline-dashed outline-1 outline-gray-400">
        <div className="flex w-full flex-col items-center justify-center pb-4 pt-3">
          <FileSvgDraw />
        </div>
      </FileInput>
      <FileUploaderContent>
        {files &&
          files.length > 0 &&
          files.map((file, i) => (
            <FileUploaderItem key={i} index={i}>
              <InlineSVG src={PaperclipIcon} className="h-4 w-4 stroke-current" />
              <span>{file.name}</span>
            </FileUploaderItem>
          ))}
      </FileUploaderContent>
    </FileUploader>
  )
}
