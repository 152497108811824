import { lazyRouteComponent, Link, Route } from "@tanstack/react-router"
import AlertCircle from "lucide-static/icons/alert-circle.svg"
import * as React from "react"
import InlineSVG from "react-inlinesvg"

import { Alert, AlertDescription, AlertTitle } from "@fourel/ui"

import { indexRoute } from "#router.js"

export const loginMappingRoute = new Route({
  getParentRoute: () => indexRoute,
  path: "/login/mapping",
  validateSearch: (search: Record<string, unknown>) => {
    if ("mappingId" in search) {
      return { mappingId: search.mappingId as string }
    }
    return {}
  },
}).update({
  errorComponent: ({ error }) => {
    return (
      <div className="relative h-[100vh] w-[100vw]">
        <Alert
          variant="destructive"
          className="absoluabsolute -translate-y-1/2te left-1/2 top-1/2 w-max -translate-x-1/2 transform"
        >
          <InlineSVG src={AlertCircle} className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription className="flex flex-col gap-2">
            <p>
              {error instanceof Error ? error?.message : "Unknown error"}, please retry
            </p>
            <Link to="/login">Redirect to login page</Link>
          </AlertDescription>
        </Alert>
      </div>
    )
  },
  component: lazyRouteComponent(() => import("./login-mapping.js"), "Mapping"),
})
