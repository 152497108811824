import { Route } from "@tanstack/react-router"

import { advertiseRoute } from "#pages/advertise/index.js"

export const Templates = () => {
  return (
    <div className="flex flex-col">
      <p>Templates</p>
    </div>
  )
}

export const templatesRoute = new Route({
  getParentRoute: () => advertiseRoute,
  path: "/templates",
  component: Templates,
})
