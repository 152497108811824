import type { VariablesOf } from "@graphql-typed-document-node/core"
import { maskitoTransform } from "@maskito/core"
import { maskitoTimeOptionsGenerator } from "@maskito/kit"
import { useMutation, useQuery } from "@tanstack/react-query"
import CalendarIcon from "lucide-static/icons/calendar.svg"
import PenLine from "lucide-static/icons/pen-line.svg"
import React from "react"
import type { UseFormReturn } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  Calendar,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  format,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ToastAction,
  useToast,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { flightQueryKeys } from "#pages/flights/flight-query-keys.js"
import { queryClient } from "#query-client"

import type { FlightFollowUpListFormType } from "../../flights-list.js"

const FlightDocument = graphql(/* GraphQL */ `
  query FlightQuoteLeg($input: QueryFlightInput!) {
    flight(input: $input) {
      id
      flightNumber
      quoteLeg {
        id
        estimatedTimeOfDeparture
        departureDate
        pax
      }
    }
  }
`)

const QuoteLegUpdateDocument = graphql(/* GraphQL */ `
  mutation QuoteLegUpdate($input: MutationQuoteLegUpdateInput!) {
    quoteLegUpdate(input: $input)
  }
`)

export const EditFlight = ({
  form,
  flightId,
  flightIndex,
}: {
  form: UseFormReturn<FlightFollowUpListFormType>
  flightId: string
  flightIndex: number
}) => {
  const { data } = useQuery({
    queryKey: ["FlightQuoteLeg", flightId],
    queryFn: () =>
      client.request(FlightDocument, {
        input: { flightId: flightId! },
      }),
    enabled: !!flightId,
  })

  const { toast } = useToast()

  const { mutate } = useMutation({
    mutationFn: (variables: VariablesOf<typeof QuoteLegUpdateDocument>) =>
      client.request(QuoteLegUpdateDocument, variables),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [flightQueryKeys.OrganizationFlights],
      })
      toast({
        title: "Success!",
        description: "Flight has been updated successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while updating a Flight.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const options = maskitoTimeOptionsGenerator({
    mode: "HH:MM",
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.currentTarget.value = maskitoTransform(event.currentTarget.value, options)
    form.setValue(
      `flightLeg.${flightIndex}.estimatedTimeOfDeparture`,
      event.currentTarget.value,
    )
  }

  const onSubmitHandler = () => {
    if (!data) {
      return
    }

    void form.handleSubmit((values: FlightFollowUpListFormType) => {
      const currentFlightFormData = values.flightLeg[flightIndex]

      const { departureDate, estimatedTimeOfDeparture, pax } = currentFlightFormData
      const departureDateObject =
        typeof departureDate === "string" ? new Date(departureDate) : departureDate

      const departureDateUTC = new Date(
        Date.UTC(
          departureDateObject!.getFullYear(),
          departureDateObject!.getMonth(),
          departureDateObject!.getDate(),
          0,
          0,
          0,
        ),
      )

      const input = {
        departureDate: departureDateUTC.toISOString(),
        estimatedTimeOfDeparture: `${estimatedTimeOfDeparture}:00`,
        pax: Number(pax),
        quoteLegId: data.flight.quoteLeg.id ?? "",
      }
      void mutate({ input })
    })()
  }

  return (
    <Dialog>
      <DialogTrigger className="w-max cursor-pointer">
        <Button className="flex items-center p-2" asChild variant="outline">
          <span className="flex !size-9 w-max items-center">
            <InlineSVG src={PenLine} className="size-4 text-slate-500" />
          </span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Flight {data?.flight.flightNumber}</DialogTitle>
        </DialogHeader>
        <div className="flex w-full items-center gap-2">
          <span className="w-1/3 text-xs text-slate-900">Date</span>
          <FormField
            control={form.control}
            name={`flightLeg.${flightIndex}.departureDate`}
            render={({ field }) => (
              <FormItem className="w-full">
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        className={cn(
                          "w-3/4 pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground",
                        )}
                      >
                        {field.value ? (
                          format(field.value, "PPP")
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <InlineSVG
                          src={CalendarIcon}
                          className="ml-auto h-4 w-4 opacity-50"
                        />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value ? new Date(field.value) : undefined}
                      onSelect={field.onChange}
                      disabled={(date) =>
                        date <= new Date() || date < new Date("1900-01-01")
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex w-full items-center gap-2">
          <span className="w-1/3 text-xs text-slate-900">Departure Time</span>
          <FormField
            control={form.control}
            name={`flightLeg.${flightIndex}.estimatedTimeOfDeparture`}
            render={({ field }) => (
              <div className="w-full">
                <Input {...field} onChange={handleChange} className="w-3/4 font-normal" />
                <FormMessage className="pl-1" />
              </div>
            )}
          />
        </div>
        <div className="flex w-full items-center gap-2">
          <span className="w-1/3 text-xs text-slate-900">Pax</span>
          <FormField
            control={form.control}
            name={`flightLeg.${flightIndex}.pax`}
            render={({ field }) => (
              <div className="w-full">
                <Input {...field} type="number" className="w-1/3 font-normal" />
                <FormMessage className="pl-1" />
              </div>
            )}
          />
        </div>
        <div className="flex gap-4">
          <DialogTrigger type="button" className="w-max" asChild>
            <Button variant="outline">Cancel</Button>
          </DialogTrigger>
          {!form.formState.isValid ? (
            <Button type="submit" className="bg-violet-700">
              Save
            </Button>
          ) : (
            <DialogTrigger className="w-max bg-violet-700 hover:bg-violet-800" asChild>
              <DialogClose asChild>
                <Button type="button" onClick={onSubmitHandler}>
                  Save
                </Button>
              </DialogClose>
            </DialogTrigger>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
