import { Route } from "@tanstack/react-router"

import { sluggedRoute } from "#router.js"

import { Flights } from "./flights.js"

export const flightsRoute = new Route({
  getParentRoute: () => sluggedRoute,
  path: "/flights",
  validateSearch: (search: Record<string, unknown>): { flightId?: string } => {
    if ("flightId" in search) {
      return {
        flightId: String(search.flightId),
      }
    }
    return {}
  },
  component: Flights,
})
