import type { ColumnDef } from "@tanstack/react-table"
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  useReactTable,
} from "@tanstack/react-table"
import React from "react"

import { Table, TableBody, TableCell, TableRow } from "@fourel/ui"

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}
export function OrganizationUsersTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  return (
    <div>
      <Table className="whitespace-nowrap">
        <TableBody className="">
          {table.getRowModel().rows?.map((row) => (
            <TableRow key={row.id} className="flex justify-between">
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  width={cell.column.id === "name" ? "40%" : "max-content"}
                  align="justify"
                  className="flex justify-start p-0 py-2 pr-2 align-middle"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
