import { Route } from "@tanstack/react-router"

import { Signed } from "#pages/clients/offers/signed/signed.js"

import { clientsOffersRoute } from "../index.js"

export const clientsOffersSignedRoute = new Route({
  getParentRoute: () => clientsOffersRoute,
  path: "/signed",
  component: Signed,
})
