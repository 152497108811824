import type { ColumnFiltersState, SortingState } from "@tanstack/react-table"
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { useState } from "react"

import { Checkbox, cn, Table, TableBody, TableCell, TableRow } from "@fourel/ui"

import type {
  AdvertiseCountryItem,
  AdvertiseItem,
  SetAdvertiseType,
} from "../models/advertise-data-types.js"
import {
  disableCheckboxHandler,
  isActive,
  selectCountryHandler,
} from "../utils/advertise-table-handlers.js"
import { columns } from "./advertise-columns.js"

const data: AdvertiseCountryItem[] = [
  {
    id: "1",
    price: 100,
    region: "East Europe",
    audience: 1000,
    country: "Poland",
  },
  {
    id: "2",
    price: 200,
    region: "East Europe",
    audience: 2000,
    country: "Turkey",
  },
  {
    id: "3",
    price: 300,
    region: "Middle Asia",
    audience: 3000,
    country: "Afghanistan",
  },
  {
    id: "4",
    price: 400,
    region: "East Europe",
    audience: 4000,
    country: "Slovakia",
  },
  {
    id: "5",
    price: 500,
    region: "North America",
    audience: 5000,
    country: "USA",
  },
  {
    id: "6",
    price: 600,
    audience: 6000,
    region: "West Asia",
    country: "AE",
  },
  {
    id: "7",
    price: 700,
    audience: 7000,
    region: "Southeast Asia",
    country: "Singapore",
  },
]

export function AdvertisePopularTable({
  advertise,
  setAdvertise,
}: {
  advertise: AdvertiseItem
  setAdvertise: SetAdvertiseType
}) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [rowSelection, setRowSelection] = useState({})

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      columnVisibility: {
        region: false,
        audience: false,
      },
    },
  })

  return (
    <div className="w-full">
      <p className={cn("mb-2 text-sm text-gray-500")}>Popular regions</p>
      <Table className="whitespace-nowrap">
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className="border-none"
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className={cn(
                      isActive(row, advertise),
                      cell.column.id === "price" ? "p-2 pl-0" : "p-2",
                    )}
                  >
                    {cell.column.id === "select" ? (
                      <Checkbox
                        disabled={disableCheckboxHandler(row, advertise)}
                        checked={advertise.countries.includes(row.getValue("country"))}
                        onCheckedChange={(isChecked) =>
                          selectCountryHandler(isChecked, row, advertise, setAdvertise)
                        }
                        aria-label="Select row"
                        className={cn(
                          "h-6 w-6",
                          advertise.countries.includes(row.getValue("country"))
                            ? "!bg-violet-700"
                            : "bg-white",
                        )}
                      />
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
