import type { ColumnFiltersState, SortingState } from "@tanstack/react-table"
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { useEffect, useState } from "react"

import { Checkbox, cn, Table, TableBody, TableCell, TableRow } from "@fourel/ui"

import type {
  AdvertiseCountryItem,
  AdvertiseItem,
  SetAdvertiseType,
} from "../models/advertise-data-types.js"
import {
  disableCheckboxHandler,
  isActive,
  selectCountryHandler,
} from "../utils/advertise-table-handlers.js"
import { columns } from "./advertise-columns.js"

const data: AdvertiseCountryItem[] = [
  {
    id: "11",
    price: 100,
    region: "East Europe",
    audience: 1000,
    country: "Poland",
  },
  {
    id: "22",
    price: 200,
    region: "East Europe",
    audience: 2000,
    country: "Czech Republic",
  },
  {
    id: "33",
    price: 300,
    region: "East Europe",
    audience: 3000,
    country: "Slovakia",
  },
  {
    id: "44",
    price: 400,
    region: "East Europe",
    audience: 4000,
    country: "Georgia",
  },
  {
    id: "55",
    price: 500,
    region: "East Europe",
    audience: 5000,
    country: "Romania",
  },
]

export function AdvertiseSimilarTable({
  filteredCountries,
  advertise,
  setAdvertise,
}: {
  advertise: AdvertiseItem
  setAdvertise: SetAdvertiseType
  filteredCountries: string
}) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [rowSelection, setRowSelection] = useState({})

  const similarCountriesTable = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      columnVisibility: {
        region: false,
        audience: false,
      },
    },
  })

  useEffect(() => {
    similarCountriesTable.getColumn("region")?.setFilterValue(filteredCountries)
  }, [filteredCountries, similarCountriesTable])

  return (
    <>
      <p className={cn("my-2 text-sm text-gray-500")}>Similar regions</p>
      <Table>
        <TableBody>
          {similarCountriesTable.getRowModel().rows?.length ? (
            similarCountriesTable.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className="border-none"
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className={cn(
                      isActive(row, advertise),
                      cell.column.id === "price" ? "p-2 pl-0" : "p-2",
                    )}
                  >
                    {cell.column.id === "select" ? (
                      <Checkbox
                        disabled={disableCheckboxHandler(row, advertise)}
                        checked={advertise.countries.includes(row.getValue("country"))}
                        onCheckedChange={(isChecked) =>
                          selectCountryHandler(isChecked, row, advertise, setAdvertise)
                        }
                        aria-label="Select row"
                        className={cn(
                          "h-6 w-6",
                          advertise.countries.includes(row.getValue("country"))
                            ? "!bg-violet-700"
                            : "bg-white",
                        )}
                      />
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell className="h-24 text-center">No results.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  )
}
