import { lazyRouteComponent, Route } from "@tanstack/react-router"

import { tripRequestsRoute } from "#pages/trip-requests/trip-requests-route.js"

export const addTripRequestsRoute = new Route({
  getParentRoute: () => tripRequestsRoute,
  path: "/add",
  component: lazyRouteComponent(
    () => import("./add-trip-requests.js"),
    "AddTripRequests",
  ),
})
