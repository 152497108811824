import { useEffect, useState } from "react"

import { Button } from "@fourel/ui"

import type { AdvertiseItem } from "../models/advertise-data-types.js"

export const AdvertiseCheckout = ({ advertise }: { advertise: AdvertiseItem }) => {
  const [leads, setLeads] = useState<number>(0)
  const [viewsNum, setViewsNum] = useState<number>(0)

  useEffect(() => {
    setLeads(advertise.audience ? advertise.audience / (advertise.audience * 2) : 0)
    setViewsNum(advertise.audience / 1000)
  }, [advertise.price, advertise.audience])

  return (
    <div className="flex w-full flex-col items-center justify-start gap-4 bg-white p-4 lg:w-1/2 dark:bg-slate-950">
      <p className="w-full text-start text-2xl font-bold">Checkout</p>
      {advertise.countries.length > 0 ? (
        advertise.countries.map((country, index) => (
          <p key={index} className="w-full text-left text-lg">
            {country}
          </p>
        ))
      ) : (
        <p className="w-full text-start text-sm text-red-500">
          Please select one of the regions to calculate a result and launch the
          advertisement
        </p>
      )}

      <div className="flex w-full flex-col gap-4 rounded-md bg-violet-200 p-2 dark:bg-violet-700">
        <div className="flex justify-between gap-2 text-sm text-gray-500 dark:text-slate-200">
          <p>Expected audience size:</p>
          <div className="flex gap-2">
            <p>{advertise.audience}</p>
            <p>person</p>
          </div>
        </div>
        <div className="flex justify-between gap-2 text-sm text-gray-500 dark:text-slate-200">
          <p>Expected number of views per person:</p>
          <p>{viewsNum}</p>
        </div>
        <div className="flex justify-between gap-2 text-sm text-gray-500 dark:text-slate-200">
          <p>Expected number of leads per month:</p>
          <p>{leads}</p>
        </div>
        {leads === 0 && (
          <div>
            <p className="w-full pb-4 text-start text-sm text-red-500">
              The audience size is to big to efficiently advertise at – the probability to
              convert a lead from this audience within chosen budget tends to 0.
            </p>
            <p className="w-full text-start text-sm text-red-500">
              Consider the idea of removing some regions therefore giving yourself a
              better chance to succeed or just increase the budget.
            </p>
          </div>
        )}
      </div>
      <div className="flex w-full items-center gap-2">
        <p className="text-gray-500">Total</p>
        <p className="text-lg">${advertise.price - advertise.budget} per/mo</p>
      </div>
      <div className="w-full">
        <Button
          disabled={advertise.audience === 0}
          className="bg-violet-700 dark:text-gray-200 hover:dark:text-slate-900"
        >
          Advertise
        </Button>
      </div>
    </div>
  )
}
