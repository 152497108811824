import { useQuery } from "@tanstack/react-query"
import { Route, useNavigate } from "@tanstack/react-router"
import type {
  ColumnDef,
  ColumnFiltersState,
  Row,
  SortingState,
  VisibilityState,
} from "@tanstack/react-table"
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import ChevronDownIcon from "lucide-static/icons/chevron-down.svg"
import DotsHorizontalIcon from "lucide-static/icons/more-horizontal.svg"
import { useMemo, useState, type MouseEvent } from "react"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { clientsRoute } from "#pages/clients/index.js"
import { useTableResize } from "#pages/clients/utils/use-table-resize.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

const OrganizationClientsDocument = graphql(/* GraphQL */ `
  query OrganizationClientsList {
    organizationClients {
      id
      name
      contacts {
        firstName
        lastName
      }
    }
  }
`)

export type ClientType = {
  id: string
  name: string
}

export const columns: ColumnDef<ClientType>[] = [
  {
    accessorKey: "name",
    header: "Client Name",
    cell: ({ row }) => <div className="capitalize">{row.getValue("name")}</div>,
  },
  {
    id: "actions",
    enableHiding: false,
    header: () => <div className="text-center">Action</div>,
    cell: ({ row }) => {
      const payment = row.original

      return (
        <div className="flex justify-center">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <InlineSVG src={DotsHorizontalIcon} className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                className="flex justify-between"
                onClick={() => navigator.clipboard.writeText(payment.id)}
              >
                <span>Edit</span>
                <InlineSVG src={ChevronDownIcon} className="ml-2 h-4 w-4 -rotate-90" />
              </DropdownMenuItem>
              <DropdownMenuItem className="flex justify-between">
                <span>Delete</span>
                <InlineSVG src={ChevronDownIcon} className="ml-2 h-4 w-4 -rotate-90" />
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )
    },
  },
]

export function AllClients() {
  const { currentOrg } = useOnboardedUserInfo()
  const { data: queryData } = useQuery({
    queryKey: ["organizationClients"],
    queryFn: () => client.request(OrganizationClientsDocument),
  })

  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = useState({})
  const navigate = useNavigate()
  const dynamicTableWidth = useTableResize()

  const data: ClientType[] = useMemo(
    () =>
      queryData?.organizationClients.map((it) => ({
        id: it.id,
        name: it.name,
      })) ?? [],
    [queryData],
  )

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  const navigateToClient = (
    row: Row<ClientType>,
    event: MouseEvent<HTMLTableRowElement>,
  ) => {
    if ((event.target as HTMLElement).closest('button[role="checkbox"]')) {
      return
    }
    void navigate({
      to: `/$slug/clients/$client/general`,
      params: { slug: currentOrg.slug, client: row.original.id },
    })
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col items-start justify-between gap-4 lg:flex-row lg:items-center">
        <Input
          placeholder="Search"
          value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
          onChange={(event) =>
            table.getColumn("name")?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
        {/* <Button variant="outline" className="text-sm">
          Edit all
        </Button>
        <div className="flex flex-wrap gap-4">
          {dropDownsData.map((item, index) => (
            <div key={index} className="flex items-center gap-2">
              <span className="text-sm">{item.label}</span>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="ml-auto text-sm">
                    All
                    <InlineSVG src={ChevronDownIcon} className="ml-2 h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {item.content.map((element, idx) => {
                    return (
                      <DropdownMenuCheckboxItem key={idx} className="capitalize">
                        {element}
                      </DropdownMenuCheckboxItem>
                    )
                  })}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          ))}
        </div> */}
      </div>
      <div className="rounded-md border" style={{ width: dynamicTableWidth }}>
        <Table className="whitespace-nowrap">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onClick={(event) => navigateToClient(row, event)}
                  className="cursor-pointer"
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export const clientsAllClientsRoute = new Route({
  getParentRoute: () => clientsRoute,
  path: "/",
  component: AllClients,
})
