import { create } from "zustand"

interface ReferenceDateStore {
  referenceDate: Date
  endDate: Date | null
  filterTitle: string | null
  setReferenceDate: (newDate: Date) => void
  setEndDate: (newDate: Date | null) => void
  setFilterTitle: (title: string | null) => void
}

export const useReferenceDateStore = create<ReferenceDateStore>((set) => ({
  referenceDate: new Date(),
  endDate: null,
  filterTitle: null,
  setReferenceDate: (newDate) => set(() => ({ referenceDate: newDate })),
  setEndDate: (newDate) => set(() => ({ endDate: newDate })),
  setFilterTitle: (title) => set(() => ({ filterTitle: title })),
}))

interface AccordionItem {
  flightId: string
  isExpanded: boolean
}

interface AccordionStore {
  accordionData: AccordionItem[]
  toggleAccordion: (flightId: string) => void
  getAccordionState: (flightId: string) => AccordionItem | undefined // Get the state directly
}

export const useAccordionStore = create<AccordionStore>((set, get) => ({
  accordionData: [],

  toggleAccordion: (flightId) =>
    set((state) => {
      const updatedData = state.accordionData.map((item) =>
        item.flightId === flightId ? { ...item, isExpanded: !item.isExpanded } : item,
      )
      if (!state.accordionData.some((item) => item.flightId === flightId)) {
        updatedData.push({ flightId, isExpanded: true })
      }

      return { accordionData: updatedData }
    }),

  getAccordionState: (flightId) => {
    const state = get()
    return state.accordionData.find((item) => item.flightId === flightId)
  },
}))

interface TableStateStore {
  isGeneralTableExpanded: boolean
  isPreparationTableExpanded: boolean
  isPreFlightTableExpanded: boolean
  isContractTableExpanded: boolean
  toggleGeneralTable: () => void
  togglePreparationTable: () => void
  togglePreFlightTable: () => void
  toggleContractTable: () => void
}

export const useTableStateStore = create<TableStateStore>((set) => ({
  isGeneralTableExpanded: true,
  isPreparationTableExpanded: true,
  isPreFlightTableExpanded: true,
  isContractTableExpanded: true,

  toggleGeneralTable: () =>
    set((state) => ({ isGeneralTableExpanded: !state.isGeneralTableExpanded })),

  togglePreparationTable: () =>
    set((state) => ({ isPreparationTableExpanded: !state.isPreparationTableExpanded })),

  togglePreFlightTable: () =>
    set((state) => ({ isPreFlightTableExpanded: !state.isPreFlightTableExpanded })),

  toggleContractTable: () =>
    set((state) => ({ isContractTableExpanded: !state.isContractTableExpanded })),
}))
