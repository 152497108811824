export const flightQueryKeys = {
  FlightGeneral: "FlightGeneral",
  FlightCrew: "FlightCrew",
  FlightDocs: "FlightDocs",
  FlightHost: "FlightHost",
  FlightPreparation: "FlightPreparation",
  FlightResponsible: "FlightResponsible",
  PreFlight: "PreFlight",
  FlightNumber: "FlightNumber",
  OrganizationFlights: "OrganizationFlights",
}
