import { Route } from "@tanstack/react-router"

import { offersRoute } from "#pages/offers/offers-route.js"

import { Pending } from "./pending.js"

export const pendingRoute = new Route({
  getParentRoute: () => offersRoute,
  path: "/",
  component: Pending,
})
