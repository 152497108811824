import { Route } from "@tanstack/react-router"

import { Pending } from "#pages/clients/offers/pending/pending.js"

import { clientsOffersRoute } from "../index.js"

export const clientsOffersPendingRoute = new Route({
  getParentRoute: () => clientsOffersRoute,
  path: "/pending",
  component: Pending,
})
