import { redirect } from "@tanstack/react-router"

import { useIntegrationStore } from "#pages/integration/store.js"
import { useUserInfo } from "#store/user-info.js"

export const redirectPerIntegration = (
  integration: "salesforce" | "hubspot" | "gmail" | "outlook",
) => {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get("code")
    if (code) {
      const { setIntegration } = useIntegrationStore.getState()
      const userInfo = useUserInfo.getState()
      setIntegration(integration, code)
      if (userInfo.state === "Onboarded") {
        throw redirect({
          to: "/$slug/integration",
          params: {
            slug: userInfo.currentOrg.slug,
          },
        })
      }
    }
  }
}
