import { Route } from "@tanstack/react-router"

import { Expired } from "#pages/clients/offers/expired/expired.js"

import { clientsOffersRoute } from "../index.js"

export const clientsOffersExpiredRoute = new Route({
  getParentRoute: () => clientsOffersRoute,
  path: "/expired",
  component: Expired,
})
