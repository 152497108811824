import { Outlet, Route } from "@tanstack/react-router"

import { clientsRoute } from "#pages/clients/index.js"

export const Quotations = () => {
  return <Outlet />
}

export const clientsQuotationsRoute = new Route({
  getParentRoute: () => clientsRoute,
  path: "$client/quotations",
  component: Quotations,
})
