import type { VariablesOf } from "@graphql-typed-document-node/core"
import { useMutation } from "@tanstack/react-query"
import DeleteIcon from "lucide-static/icons/trash-2.svg"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  ToastAction,
  useToast,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client.js"
import { queryClient } from "#query-client.js"

const ClientDocumentDeleteDocument = graphql(/* GraphQL */ `
  mutation ClientDocumentDelete($input: MutationClientDocumentDeleteInput!) {
    clientDocumentDelete(input: $input)
  }
`)

export const DeleteDocumentModal = ({
  documentId,
  documentKey,
  buttonName,
  modalDescription,
}: {
  documentId: string
  documentKey: string
  buttonName: string
  modalDescription: string
}) => {
  const { toast } = useToast()

  const { mutate } = useMutation({
    mutationFn: (variables: VariablesOf<typeof ClientDocumentDeleteDocument>) =>
      client.request(ClientDocumentDeleteDocument, variables),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["clientDocs"] })

      toast({
        title: "Success!",
        description: "Client contact has been deleted successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while deleting a client contact.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const deleteHandler = () => {
    mutate({ input: { documentId, key: documentKey } })
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">
          <InlineSVG src={DeleteIcon} className="pr-2" />
          <span>{buttonName}</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete</DialogTitle>
          <DialogDescription>{modalDescription}</DialogDescription>
        </DialogHeader>
        <div className="flex gap-4">
          <DialogClose asChild>
            <Button variant="outline">Cancel</Button>
          </DialogClose>
          <DialogTrigger asChild>
            <Button variant="destructive" onClick={deleteHandler}>
              Delete
            </Button>
          </DialogTrigger>
        </div>
      </DialogContent>
    </Dialog>
  )
}
