import type { FC, PropsWithChildren } from "react"

import type { FeatureName } from "./features.js"
import { useIsFeatureEnabled } from "./use-is-feature-enabled.js"

type FeatureGateProps = PropsWithChildren<{
  name: FeatureName
}>

export const FeatureGate: FC<FeatureGateProps> = ({ name, children }) => {
  const isFeatureEnabled = useIsFeatureEnabled()
  return isFeatureEnabled(name) ? children : null
}
