import type { UseFormReturn } from "react-hook-form"

import type { FlightFollowUpListFormType } from "#pages/flights/flights-list/flights-list.js"
import { useTableStateStore } from "#pages/flights/flights-store.js"

import { AccordionFormCrew } from "./components/accordion-form-crew.js"
import { AccordionFormHost } from "./components/accordion-form-host.js"
import { AccordionFormResponsible } from "./components/accordion-form-responsible.js"

export const AccordionContentGeneral = ({
  form,
  flightId,
  flightIndex,
}: {
  form: UseFormReturn<FlightFollowUpListFormType>
  flightId: string
  flightIndex: number
}) => {
  const { isGeneralTableExpanded } = useTableStateStore()

  return (
    <div className="flex gap-80">
      <AccordionFormHost flightId={flightId} form={form} flightIndex={flightIndex} />
      {isGeneralTableExpanded && (
        <>
          <AccordionFormCrew flightId={flightId} form={form} flightIndex={flightIndex} />
          <AccordionFormResponsible
            flightId={flightId}
            form={form}
            flightIndex={flightIndex}
          />
        </>
      )}
    </div>
  )
}
