import { Link, useRouterState } from "@tanstack/react-router"
import ChevronsLeftIcon from "lucide-static/icons/chevrons-left.svg"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  cn,
  Command,
  CommandGroup,
  CommandItemCustom,
  CommandList,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "@fourel/ui"

import { useOnboardedUserInfo } from "#store/user-info.js"

interface AdvertiseItemType {
  label: string
  url: string
}

const menuItems: AdvertiseItemType[] = [
  {
    label: "To my clients",
    url: "",
  },
  {
    label: "To new clients",
    url: "new",
  },
]

const manageItems: AdvertiseItemType[] = [
  {
    label: "Templates",
    url: "templates",
  },
]

export function MobileAdvertiseSubmenu() {
  const { currentOrg } = useOnboardedUserInfo()

  const commandItemStyle =
    "mb-2 cursor-pointer gap-2 px-6 py-2 transition-all duration-200 ease-in-out hover:bg-violet-600/10 hover:text-violet-600 dark:hover:bg-slate-800 dark:hover:text-white"

  const submenuCategoryName = useRouterState({
    select: (s) => {
      const pathnameParts = s.location.pathname.split("/")
      return pathnameParts[pathnameParts.length - 1]
    },
  })

  return (
    <div className="block md:hidden">
      <Drawer direction="left">
        <div className="flex h-10 w-full items-center gap-4 border-b border-gray-200 bg-white px-4 dark:bg-slate-900">
          <DrawerTrigger asChild>
            <Button
              variant="link"
              size="sm"
              data-testid="mobile-advertise-submenu-button"
              className={cn("h-8 p-0")}
            >
              <InlineSVG
                src={ChevronsLeftIcon}
                className={cn(
                  "h-5 w-5 transition-all duration-300 ease-in-out hover:text-purple-800",
                )}
              />
            </Button>
          </DrawerTrigger>
          <span className="text-xl font-bold">Advertise</span>
        </div>
        <DrawerContent className="h-screen max-w-72 rounded-none">
          <div className={cn("flex items-center gap-4 p-6 pt-4")}>
            <DrawerClose asChild>
              <Button variant="link" size="sm" className={cn("h-8 p-0")}>
                <InlineSVG
                  src={ChevronsLeftIcon}
                  className={cn(
                    "h-5 w-5 transition-all duration-300 ease-in-out hover:text-purple-800",
                  )}
                />
              </Button>
            </DrawerClose>
            <span className={cn("whitespace-nowrap text-2xl font-bold tracking-wider")}>
              Advertise
            </span>
          </div>
          <div className="flex flex-auto overflow-hidden p-1 text-sm">
            <Command className="static w-full flex-auto overflow-y-auto bg-transparent">
              <CommandList className="flex flex-auto flex-col overflow-y-auto [&>*]:flex [&>*]:flex-auto [&>*]:flex-col">
                <CommandGroup className={cn("p-0")}>
                  {menuItems.map((item) => (
                    <Link
                      from="/$slug/advertise"
                      to={item.url}
                      key={item.label}
                      params={{ slug: currentOrg.slug }}
                    >
                      <DrawerClose className="w-full">
                        <CommandItemCustom
                          key={item.label}
                          className={cn(
                            commandItemStyle,
                            item.url.includes(submenuCategoryName) ||
                              (submenuCategoryName === "advertise" && item.url === "")
                              ? "bg-violet-700 text-white hover:bg-violet-600/90 hover:text-white dark:hover:bg-violet-600/90"
                              : "",
                          )}
                        >
                          <span className={cn("whitespace-nowrap")}>{item.label}</span>
                        </CommandItemCustom>
                      </DrawerClose>
                    </Link>
                  ))}
                </CommandGroup>
                <p
                  className={cn(
                    "my-5 flex items-center gap-2 pl-6 text-xs text-slate-400 after:block after:h-[1px] after:flex-auto after:bg-slate-400",
                  )}
                >
                  Manage
                </p>
                <CommandGroup className={cn("p-0")}>
                  {manageItems.map((item) => (
                    <Link
                      from="/$slug/advertise"
                      to={item.url}
                      key={item.label}
                      params={{ slug: currentOrg.slug }}
                    >
                      <DrawerClose className="w-full">
                        <CommandItemCustom
                          className={cn(
                            commandItemStyle,
                            item.url.includes(submenuCategoryName)
                              ? "bg-violet-700 text-white hover:bg-violet-600/90 hover:text-white dark:hover:bg-violet-600/90"
                              : "",
                          )}
                        >
                          <span className={cn("whitespace-nowrap")}>{item.label}</span>
                        </CommandItemCustom>
                      </DrawerClose>
                    </Link>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  )
}
