import { Route } from "@tanstack/react-router"

import { redirectPerIntegration } from "#pages/integration/callback-routes/redirect.js"
import { onboardedGuardRoute } from "#router.js"

export const outlookCallbackRoute = new Route({
  getParentRoute: () => onboardedGuardRoute,
  path: "/integration/outlook",
  beforeLoad: async () => redirectPerIntegration("outlook"),
})
