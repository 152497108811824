import type { ColumnFiltersState, SortingState } from "@tanstack/react-table"
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import SearchIcon from "lucide-static/icons/search.svg"
import type { ChangeEvent } from "react"
import { useState } from "react"
import InlineSVG from "react-inlinesvg"

import { Checkbox, cn, Input, Table, TableBody, TableCell, TableRow } from "@fourel/ui"

import type {
  AdvertiseCountryItem,
  AdvertiseItem,
  SetAdvertiseType,
} from "../models/advertise-data-types.js"
import {
  disableCheckboxHandler,
  isActive,
  selectCountryHandler,
} from "../utils/advertise-table-handlers.js"
import { columns } from "./advertise-columns.js"
import { AdvertisePopularTable } from "./advertise-popular-table.js"
import { AdvertiseSimilarTable } from "./advertise-similar-table.js"

const data: AdvertiseCountryItem[] = [
  {
    id: "1",
    price: 100,
    region: "East Europe",
    audience: 5000,
    country: "Ukraine",
  },
  {
    id: "2",
    price: 200,
    region: "Middle Asia",
    audience: 2000,
    country: "Tajikistan",
  },
  {
    id: "3",
    price: 300,
    region: "Middle Asia",
    audience: 3000,
    country: "Uzbekistan",
  },
  {
    id: "4",
    price: 400,
    region: "Middle Asia",
    audience: 4000,
    country: "Turkmenistan",
  },
  {
    id: "5",
    price: 500,
    region: "Middle Asia",
    audience: 5000,
    country: "Afghanistan",
  },
]

export function AdvertiseSearch({
  advertise,
  setAdvertise,
}: {
  advertise: AdvertiseItem
  setAdvertise: SetAdvertiseType
}) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const [rowSelection, setRowSelection] = useState({})
  const [filteredCountries, setFilteredCountries] = useState("")

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      columnVisibility: {
        region: false,
        audience: false,
      },
    },
  })

  const searchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    table.getColumn("country")?.setFilterValue(event.target.value)

    const searchedCountryRegion = table
      .getRowModel()
      .rows.map((row) => row.original.region)
    const uniqueRegions = [...new Set(searchedCountryRegion)]

    setFilteredCountries(uniqueRegions[0])
  }

  return (
    <div className="w-full">
      <div className="relative flex items-center py-4">
        <Input
          placeholder="Search"
          value={(table.getColumn("country")?.getFilterValue() as string) ?? ""}
          onChange={(event) => {
            searchHandler(event)
          }}
          className="w-full"
        />
        <InlineSVG src={SearchIcon} className="absolute right-2 h-4 text-gray-500" />
      </div>
      {table.getColumn("country")?.getFilterValue() ? (
        <div>
          <Table>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className="border-none"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className={cn(
                          isActive(row, advertise),
                          cell.column.id === "price" ? "p-2 pl-0" : "p-2",
                        )}
                      >
                        {cell.column.id === "select" ? (
                          <Checkbox
                            disabled={disableCheckboxHandler(row, advertise)}
                            checked={advertise.countries.includes(
                              row.getValue("country"),
                            )}
                            onCheckedChange={(isChecked) =>
                              selectCountryHandler(
                                isChecked,
                                row,
                                advertise,
                                setAdvertise,
                              )
                            }
                            aria-label="Select row"
                            className={cn(
                              "h-6 w-6",
                              advertise.countries.includes(row.getValue("country"))
                                ? "!bg-violet-700"
                                : "bg-white",
                            )}
                          />
                        ) : (
                          flexRender(cell.column.columnDef.cell, cell.getContext())
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {table.getRowModel().rows?.length > 0 && (
            <AdvertiseSimilarTable
              advertise={advertise}
              setAdvertise={setAdvertise}
              filteredCountries={filteredCountries}
            />
          )}
        </div>
      ) : (
        <AdvertisePopularTable advertise={advertise} setAdvertise={setAdvertise} />
      )}
    </div>
  )
}
