import { Route } from "@tanstack/react-router"
import React from "react"

import { sluggedRoute } from "#router.js"

export const Dashboard = () => {
  return <div className="flex flex-col items-start gap-5 pt-5">dashboard</div>
}

export const dashboardRoute = new Route({
  getParentRoute: () => sluggedRoute,
  path: "/dashboard",
  component: Dashboard,
})
