import type { VariablesOf } from "@graphql-typed-document-node/core"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import { Route, useNavigate, useRouter, useRouterState } from "@tanstack/react-router"
import type { MouseEvent } from "react"
import React from "react"
import { useForm } from "react-hook-form"
import z from "zod"

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Separator,
  ToastAction,
  useToast,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { useOnboardedUserInfo } from "#store/user-info.js"

import { clientsContactsRoute } from "../index.js"

const ClientContactCreateDocument = graphql(/* GraphQL */ `
  mutation ClientContactCreate($input: MutationClientContactCreateInput!) {
    clientContactCreate(input: $input) {
      id
    }
  }
`)

const contactSchema = z.object({
  firstName: z.string().trim().min(1, { message: "Invalid First Name" }),
  lastName: z.string().trim().min(1, { message: "Invalid Last Name" }),
  email: z.string().trim().min(1, { message: "Invalid Email" }).email("Invalid Email"),
  phone: z
    .string()
    .trim()
    .min(10, { message: "Invalid Phone Number" })
    .max(14, { message: "Invalid Phone Number" }),
})

type ContactFormType = z.infer<typeof contactSchema>

type ContactFormFieldsType = {
  field: "firstName" | "lastName" | "email" | "phone"
  label: string
  placeholder: string
}

const contactFormFields: ContactFormFieldsType[] = [
  { field: "firstName", label: "First Name*", placeholder: "First Name" },
  { field: "lastName", label: "Last Name*", placeholder: "Last Name" },
  { field: "email", label: "Email*", placeholder: "Email" },
  { field: "phone", label: "Phone*", placeholder: "Phone" },
]

export const AddContact = () => {
  const navigate = useNavigate()
  const { currentOrg } = useOnboardedUserInfo()
  const [clientId] = useRouterState({
    select: (s) => s.location.pathname.split("/clients")[1].split("/").slice(1),
  })
  const router = useRouter()
  const { toast } = useToast()

  const form = useForm<ContactFormType>({
    resolver: zodResolver(contactSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },

    mode: "onSubmit",
  })

  const resetField = form.resetField

  const { mutate } = useMutation({
    mutationFn: (variables: VariablesOf<typeof ClientContactCreateDocument>) =>
      client.request(ClientContactCreateDocument, variables),
    onSuccess: () => {
      form.reset()
      void navigate({
        to: "/$slug/clients/$client/contacts",
        params: { slug: currentOrg.slug, client: clientId },
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while adding a client contact.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const onSubmitHandler = (values: ContactFormType) => {
    mutate({
      input: { clientId, contact: { ...values, preferredContactMethod: "email" } },
    })
  }

  const goBackHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    router.history.back()
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmitHandler)}
        className="flex w-full flex-col items-start justify-start gap-4"
      >
        <h3 className="text-xl font-semibold">Add a contact</h3>
        <div className="flex w-full flex-col gap-2 lg:w-1/2">
          {contactFormFields.map((item, index) => (
            <FormField
              key={index}
              control={form.control}
              name={item.field}
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <div className="flex items-center">
                    <FormLabel className="mr-2 w-1/2">{item.label}</FormLabel>
                    <div className="flex w-full flex-col gap-1">
                      <FormControl>
                        <Input
                          placeholder={item.placeholder}
                          {...field}
                          defaultValue=""
                          onChange={(e) => {
                            resetField(item.field)
                            form.setValue(item.field, e.target.value)
                          }}
                        />
                      </FormControl>
                      <FormMessage className="pl-1 text-xs" />
                    </div>
                  </div>
                </FormItem>
              )}
            />
          ))}
        </div>

        <Separator />

        <div className="flex w-full justify-start gap-4">
          <Button
            variant="outline"
            onClick={(event) => {
              goBackHandler(event)
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </div>
      </form>
    </Form>
  )
}

export const clientsContactsAddRoute = new Route({
  getParentRoute: () => clientsContactsRoute,
  path: "/add-contact",
  component: AddContact,
})
