import type { VariablesOf } from "@graphql-typed-document-node/core"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import { useRouterState } from "@tanstack/react-router"
import { useForm } from "react-hook-form"
import { z } from "zod"

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ToastAction,
  useToast,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client.js"
import { queryClient } from "#query-client.js"

import { TagsInput } from "./tags-input.js"
import { UploadArea } from "./upload-area.js"

const ClientDocumentCreateDocument = graphql(/* GraphQL */ `
  mutation ClientDocumentCreate($input: MutationClientDocumentCreateInput!) {
    clientDocumentCreate(input: $input) {
      id
      documentName
      owner
      tag
      url
    }
  }
`)

const documentSchema = z.object({
  file: z
    .instanceof(File)
    .refine((file) => file.type === "application/pdf", {
      message: "Only pdf files are allowed.",
    })
    .nullable(),
  documentName: z
    .string()
    .min(2, {
      message: "Document name must be at least 2 characters.",
    })
    .max(150),
  owner: z.string().min(1).max(150),
  tag: z.array(z.string().min(1).max(150)),
})

type DocumentFormType = z.infer<typeof documentSchema>

export const UploadDocumentModal = () => {
  const { toast } = useToast()
  const [clientId] = useRouterState({
    select: (s) => s.location.pathname.split("/clients")[1].split("/").slice(1),
  })
  const form = useForm<DocumentFormType>({
    resolver: zodResolver(documentSchema),
    defaultValues: {
      file: null,
      documentName: "",
      owner: "",
      tag: [],
    },
  })

  const { mutate } = useMutation({
    mutationFn: (variables: VariablesOf<typeof ClientDocumentCreateDocument>) =>
      client.request(ClientDocumentCreateDocument, variables),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["clientDocs"] })
      toast({
        title: "Success!",
        description: "Client document has been added successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async (error) => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while adding a client document.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  function onSubmit(values: DocumentFormType) {
    const { documentName, file, owner, tag } = values

    const reader = new FileReader()

    reader.onload = () => {
      const result = reader.result
      const base64File = (result as string).split(",")[1]
      mutate({
        input: {
          clientId,
          document: {
            documentName,
            owner,
            tag: tag[0],
            file: base64File,
          },
        },
      })
    }
    reader.readAsDataURL(file!)
  }
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="primary" className="p-2 text-xs md:p-4 md:text-sm">
          Add a document
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add a document</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="file"
              render={({ field }) => (
                <FormItem className="flex items-center justify-between gap-2">
                  <UploadArea onFileChange={(file: File) => field.onChange(file)} />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="documentName"
              render={({ field }) => (
                <FormItem className="flex items-center justify-between gap-2">
                  <FormLabel className="w-1/2">Name of document</FormLabel>
                  <FormControl>
                    <Input placeholder="Name of document" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="owner"
              render={({ field }) => (
                <FormItem className="flex items-center justify-between gap-2">
                  <FormLabel className="w-1/2">Owner</FormLabel>
                  <FormControl>
                    <Input placeholder="Owner" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="tag"
              render={({ field }) => (
                <FormItem className="flex items-center justify-between gap-2">
                  <FormLabel className="w-1/2">Tags</FormLabel>
                  <FormControl>
                    <TagsInput tags={[...field.value]} setTags={field.onChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex gap-4">
              <DialogClose asChild>
                <Button type="button" variant="outline">
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!form.formState.isValid}
                >
                  Submit
                </Button>
              </DialogClose>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
