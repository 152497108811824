import type { UseFormReturn } from "react-hook-form"

import type { FlightFollowUpListFormType } from "#pages/flights/flights-list/flights-list.js"
import { useTableStateStore } from "#pages/flights/flights-store.js"

import { AccordionFormOperator } from "./components/accordion-form-operator.js"
import { AccordionFormResponsible } from "./components/accordion-form-responsible.js"

export const AccordionContentPreFlight = ({
  form,
  flightId,
  flightIndex,
}: {
  form: UseFormReturn<FlightFollowUpListFormType>
  flightId: string
  flightIndex: number
}) => {
  const { isGeneralTableExpanded, isPreparationTableExpanded, isPreFlightTableExpanded } =
    useTableStateStore()

  return (
    <div className="flex gap-56">
      {!isGeneralTableExpanded && isPreparationTableExpanded && (
        <AccordionFormOperator flightId={flightId} />
      )}
      {!isGeneralTableExpanded &&
        !isPreparationTableExpanded &&
        isPreFlightTableExpanded && (
          <div className="flex w-full gap-32">
            <AccordionFormResponsible
              flightId={flightId}
              form={form}
              flightIndex={flightIndex}
            />
            <AccordionFormOperator flightId={flightId} />
          </div>
        )}
      {!isGeneralTableExpanded &&
        !isPreparationTableExpanded &&
        !isPreFlightTableExpanded && (
          <AccordionFormResponsible
            flightId={flightId}
            form={form}
            flightIndex={flightIndex}
          />
        )}
    </div>
  )
}
