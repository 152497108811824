import type { ControllerRenderProps } from "react-hook-form"

import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@fourel/ui"

import type { AircraftFormType } from "../create-aircraft-modal.js"

const aircraftClassData = [
  {
    name: "Piston",
    description: "Small piston-powered aircraft.",
  },
  {
    name: "Turbo prop",
    description: "Turbo prop-powered aircraft that seat up to 19 passengers.",
  },
  {
    name: "Entry level jet (VLJ)",
    description: "Jet aircraft with a MTOW of up to 12 000 lbs.",
  },
  {
    name: "Light jet",
    description: "Jet aircraft with a MTOW between 12 000 and 18 000 lbs.",
  },
  {
    name: "Super light jet",
    description: "Jet aircraft with a MTOW between 18 000 and 22 000 lbs.",
  },
  {
    name: "Midsize jet",
    description: "Jet aircraft with a MTOW between 22 000 and 30 000 lbs.",
  },
  {
    name: "Super midsize jet",
    description: "Jet aircraft with a MTOW between 30 000 and 40 000 lbs.",
  },
  {
    name: "Heavy jet",
    description: "Jet aircraft with a MTOW of more than 41 000 lbs.",
  },
  {
    name: "Ultra long range",
    description: "Heavy jets with a range of more than 5 000 NM.",
  },
  {
    name: "VIP airliner",
    description: "VIP-configured airliners.",
  },
  {
    name: "Airliner",
    description: "Non-VIP airliners that seat 20 passengers or more.",
  },
  {
    name: "Helicopter single engine",
    description: "Helicopter with single engine.",
  },
  {
    name: "Helicopter twin engine",
    description: "Helicopter with twin engine.",
  },
]

interface AircraftClassSelectProps {
  field: ControllerRenderProps<AircraftFormType, "class">
  setValue: (field: "class", value: string) => void
  revalidate: (name: "class") => void
}
export const AircraftClassSelect = ({
  field,
  setValue,
  revalidate,
}: AircraftClassSelectProps) => {
  return (
    <FormItem className="w-full">
      <div className="flex items-center justify-between gap-2">
        <FormLabel className="w-1/2 text-xs dark:text-slate-200">
          Aircraft Class
        </FormLabel>
        <FormControl>
          <Select
            {...field}
            onValueChange={(value) => {
              setValue("class", value)
              revalidate("class")
            }}
          >
            <SelectTrigger className="text-muted-foreground w-full text-xs font-medium hover:bg-slate-100">
              <SelectValue
                placeholder="Aircraft class"
                className="placeholder:text-gray-500"
              >
                <p className="text-black">{field.value}</p>
              </SelectValue>
            </SelectTrigger>
            <SelectContent className="max-h-56">
              <SelectGroup className="max-w-[calc(var(--radix-select-trigger-width)-8px)] cursor-pointer text-slate-900 [&>*]:cursor-pointer [&>*]:text-xs">
                {aircraftClassData.map((item) => (
                  <SelectItem key={item.name} value={item.name}>
                    <p>{item.name}</p>
                    <p className="text-gray-400">{item.description}</p>
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </FormControl>
      </div>
      <div className="w-full">
        <FormMessage className="text-xs" />
      </div>
    </FormItem>
  )
}
