import { Outlet, Route } from "@tanstack/react-router"

import { sluggedRoute } from "#router.js"

export const offersRoute = new Route({
  getParentRoute: () => sluggedRoute,
  path: "/offers",
  component: () => {
    return (
      <div className="h-[calc(100vh-112px)] md:h-[calc(100vh-64px)]">
        <Outlet />
      </div>
    )
  },
})
