import { Outlet, Route } from "@tanstack/react-router"

import { sluggedRoute } from "#router.js"

import { TripRequestsHeader } from "./components/trip-requests-header.js"

export const tripRequestsRoute = new Route({
  getParentRoute: () => sluggedRoute,
  path: "/trip-requests",
  component: () => (
    <div className="flex flex-col">
      <TripRequestsHeader />
      <div className="h-[calc(100vh-176px)] flex-grow overflow-y-auto px-6 pt-6 md:h-[calc(100vh-64px)]">
        <Outlet />
      </div>
    </div>
  ),
})
