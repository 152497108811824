import { lazyRouteComponent, Route } from "@tanstack/react-router"

import { previewOfferRoute } from "#pages/offers/preview-offer/index.js"

export const offerGeneralRoute = new Route({
  getParentRoute: () => previewOfferRoute,
  path: "/",
  validateSearch: (
    search: Record<string, unknown>,
  ): { preview?: boolean; bookManually?: boolean } => {
    return {
      preview: search.preview === true || false,
      bookManually: search.bookManually === true || false,
    }
  },
  component: lazyRouteComponent(() => import("./offer-general.js"), "OfferGeneral"),
})
