import type { ColumnDef } from "@tanstack/react-table"

import { Checkbox, cn } from "@fourel/ui"

import type { AdvertiseCountryItem } from "../models/advertise-data-types.js"

export const columns: ColumnDef<AdvertiseCountryItem>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className={cn("h-6 w-6", row.getIsSelected() ? "!bg-violet-700" : "bg-white")}
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "country",
    header: () => null,
    cell: ({ row }) => (
      <div className="font-semibold dark:text-slate-200">{row.getValue("country")}</div>
    ),
  },
  {
    accessorKey: "price",
    header: () => <div className="text-right">Price</div>,
    cell: ({ row }) => {
      const price = parseFloat(row.getValue("price"))

      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(price)

      return (
        <div className="text-right font-semibold dark:text-slate-200">{formatted}</div>
      )
    },
  },
  {
    id: "region",
    accessorKey: "region",
    header: () => null,
    cell: () => null,
  },
  {
    id: "audience",
    accessorKey: "audience",
    header: () => null,
    cell: () => <p>aud</p>,
  },
]
