import X from "lucide-static/icons/x.svg"
import type { KeyboardEvent } from "react"
import InlineSVG from "react-inlinesvg"

import { Badge } from "@fourel/ui"

export const TagsInput = ({
  tags,
  setTags,
}: {
  tags: string[]
  setTags: (tags: string[]) => void
}) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key !== "Enter") return

    if (e.key === "Enter") {
      e.preventDefault()
      const value = e.currentTarget.value.trim()
      if (value) {
        setTags([...tags, value])
        e.currentTarget.value = ""
      }
    }
  }

  const removeTag = (index: number): void => {
    setTags(tags.filter((_, i) => i !== index))
  }

  return (
    <div className="flex min-h-10 w-full flex-wrap items-start gap-2 rounded-md border p-2">
      {tags &&
        tags.map((tag, index) => (
          <Badge
            key={index}
            className="bg-secondary hover:bg-secondary flex cursor-pointer items-center gap-2 rounded-md font-normal dark:bg-gray-600"
            onClick={() => removeTag(index)}
          >
            <span className="text-sm text-slate-950 dark:text-slate-200">{tag}</span>
            <InlineSVG src={X} className="size-4 text-slate-950 dark:text-slate-200" />
          </Badge>
        ))}
      <input
        type="text"
        className="flex-grow border-none bg-transparent pl-1 outline-none placeholder:text-sm"
        placeholder="Enter tags"
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}
