import { useRouterState } from "@tanstack/react-router"

export const AdvertiseHeader = () => {
  const subMenuName = useRouterState({
    select: (s) => s.location.pathname.split("/")[4],
  })

  return (
    <div className="flex h-16 w-full items-center justify-between border-b border-gray-200 bg-gray-50 p-4 px-6 text-xl font-bold md:text-2xl dark:bg-slate-950">
      {subMenuName === "new"
        ? "To new clients"
        : subMenuName === undefined
          ? "To my clients"
          : "Templates"}
    </div>
  )
}
