export const FlightsHeader = () => {
  return (
    <div
      className="sticky left-0 top-0 h-16 border-b border-gray-200 bg-gray-50 p-4 px-6 dark:bg-gray-950"
      style={{ zIndex: 120 }}
    >
      <h1 className="text-xl font-bold md:text-2xl">Flights</h1>
    </div>
  )
}
