import { useQuery } from "@tanstack/react-query"
import { Link, useRouterState } from "@tanstack/react-router"

import { Button } from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { useOnboardedUserInfo } from "#store/user-info.js"

const ClientDocument = graphql(/* GraphQL */ `
  query ClientName($input: QueryClientInput!) {
    client(input: $input) {
      name
    }
  }
`)

const ClientContactByIdDocument = graphql(/* GraphQL */ `
  query ClientContactNameById($input: QueryClientContactByIdInput!) {
    clientContactById(input: $input) {
      firstName
      lastName
    }
  }
`)

export const ContactsHeader = () => {
  const { currentOrg } = useOnboardedUserInfo()

  const [clientId, category, contactId] = useRouterState({
    select: (s) => s.location.pathname.split("/clients")[1].split("/").slice(1),
  })

  const { data } = useQuery({
    queryKey: ["clientGetName"],
    queryFn: () => client.request(ClientDocument, { input: { clientId } }),
    enabled: !!clientId,
  })

  const { data: contactName } = useQuery({
    queryKey: ["clientContactGetName"],
    queryFn: () => client.request(ClientContactByIdDocument, { input: { contactId } }),
    enabled: !!contactId,
  })

  const clientsMenuTitle = `${data?.client.name} \\ ${category[0].toUpperCase()}${category.slice(1)} ${contactName && contactId && contactId !== "add-contact" ? `\\ ${contactName.clientContactById.lastName} ${contactName.clientContactById.firstName}` : ""}`

  return (
    <div className="flex h-16 w-full items-center justify-between border-b border-gray-200 bg-gray-50 p-4 px-6 dark:bg-gray-950">
      <h1 className="text-xl font-bold md:text-2xl">{clientsMenuTitle}</h1>
      <Link
        to="/$slug/clients/$client/contacts/add-contact"
        params={{ slug: currentOrg.slug, client: clientId }}
      >
        <Button variant="primary" className="p-2 text-xs md:p-4 md:text-sm">
          Add a contact
        </Button>
      </Link>
    </div>
  )
}
