import { Outlet, useRouterState } from "@tanstack/react-router"

import { useOnboardedUserInfo } from "#store/user-info.js"

import { AddClientHeader } from "./components/add-client-header.js"
import { ClientsListHeader } from "./components/clients-list-header.js"
import { ContactsHeader } from "./components/contacts-header.js"
import { DocumentsHeader } from "./components/documents-header.js"
import { OffersHeader } from "./components/offers-header.js"
import { StandardHeader } from "./components/standard-header.js"

export const Clients = () => {
  const { currentOrg } = useOnboardedUserInfo()
  const subMenuName = useRouterState({
    select: (s) => s.location.pathname.split(`/${currentOrg.slug}`)[1],
  })
  const showStandardHeader =
    (subMenuName.includes("clients") && subMenuName.includes("flights")) ||
    subMenuName.includes("quotations") ||
    subMenuName.includes("invoices") ||
    subMenuName.includes("contracts") ||
    subMenuName.includes("general")

  return (
    <div className="w-full">
      {subMenuName === "/clients" && <ClientsListHeader />}
      {subMenuName.includes("add-client") && <AddClientHeader />}
      {subMenuName.includes("offers") && subMenuName.includes("clients") && (
        <OffersHeader />
      )}
      {subMenuName.includes("documents") && <DocumentsHeader />}
      {subMenuName.includes("contacts") && <ContactsHeader />}
      {showStandardHeader && <StandardHeader />}

      <div className="h-[calc(100vh-176px)] overflow-y-auto px-6 pt-6 md:h-[calc(100vh-64px)]">
        <Outlet />
      </div>
    </div>
  )
}
