import React from "react"
import type { UseFormReturn } from "react-hook-form"

import { cn, FormControl, FormField, FormItem, FormMessage } from "@fourel/ui"

import type { FlightsOrganizationByDateQuery } from "#gql/graphql.js"
import { useAccordionStore, useTableStateStore } from "#pages/flights/flights-store.js"
import type { SelectOptionType } from "#pages/flights/models/flight-follow-up-types.js"

import { AccordionContentContract } from "../accordion/accordion-content-contract.js"
import type { FlightFollowUpListFormType } from "../flights-list.js"
import { FlightsListSelect } from "./components/select.js"
import { TableExpandHeader } from "./components/table-expand-header.js"
import { TableStateTag } from "./components/table-state-tag.js"

type ContractPaymentFieldNames =
  | "operatorContract"
  | "clientContract"
  | "operatorPayment"
  | "clientPayment"
  | "leg"

interface TableFieldConfig {
  fieldName: ContractPaymentFieldNames
  options: SelectOptionType[]
}

const tableSubheaderData = ["Operator", "Customer", "Operator", "Customer", "Leg"]

const contractOptions: SelectOptionType[] = [
  { field: "Signed", value: "signed", default: false },
  { field: "Not signed", value: "not_signed", default: false },
]

const paymentOptions: SelectOptionType[] = [
  { field: "Not paid", value: "not_paid", default: true },
  { field: "Paid", value: "paid", default: false },
]

const legStatusOptions: SelectOptionType[] = [
  { field: "Not selected", value: "not_selected", default: true },
  { field: "Confirmed", value: "confirmed", default: false },
  { field: "Completed", value: "completed", default: false },
]

const tableFieldsConfig: TableFieldConfig[] = [
  { fieldName: "operatorContract", options: contractOptions },
  { fieldName: "clientContract", options: contractOptions },
  { fieldName: "operatorPayment", options: paymentOptions },
  { fieldName: "clientPayment", options: paymentOptions },
  { fieldName: "leg", options: legStatusOptions },
]

export const TableContractPayment = ({
  flightData,
  form,
}: {
  flightData: FlightsOrganizationByDateQuery["flightsOrganizationByDate"]
  form: UseFormReturn<FlightFollowUpListFormType>
}) => {
  const { toggleAccordion, getAccordionState } = useAccordionStore()
  const { isContractTableExpanded } = useTableStateStore()

  return (
    <div>
      <TableExpandHeader tableName="Contract" />

      <div className={`overflow-hidden ${isContractTableExpanded ? "w-full" : "w-auto"}`}>
        <table className={cn(!isContractTableExpanded && "w-52")}>
          <thead className="h-[35px] border-b">
            <tr className="text-muted-foreground border-r text-xs font-bold">
              {isContractTableExpanded
                ? tableSubheaderData.map((header, index) => (
                    <th key={index} className="font-medium">
                      {header}
                    </th>
                  ))
                : null}
            </tr>
          </thead>

          <tbody>
            {flightData.length &&
              flightData.map((flight, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={cn(
                      (index + 1) % 2 === 0 && "bg-blue-300/10",
                      "h-[60px] border border-l-0 text-center text-sm font-semibold",
                    )}
                    onClick={() => toggleAccordion(flight.id)}
                  >
                    {isContractTableExpanded ? (
                      tableFieldsConfig.map(({ fieldName, options }, fieldIndex) => (
                        <td key={fieldIndex}>
                          <FormField
                            control={form.control}
                            name={`contractPaymentTableLegs.${index}.${fieldName}`}
                            render={() => (
                              <FormItem className="relative w-32 space-y-0 p-2">
                                <FormControl>
                                  <FlightsListSelect
                                    form={form}
                                    options={options}
                                    fieldName={`contractPaymentTableLegs.${index}.${fieldName}`}
                                    flightId={flight.id}
                                    flightDocumentsId={flight.flightDocuments?.id}
                                    flightPreparationId={flight.flightPreparation?.id}
                                    preFlightId={flight.preFlight?.id}
                                    flightCateringId={
                                      flight.flightPreparation?.flightCatering[0]?.id
                                    }
                                    index={index}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </td>
                      ))
                    ) : (
                      <td className="flex min-w-36 justify-center p-2">
                        <TableStateTag
                          fieldName={`contractPaymentTableLegs.${index}`}
                          form={form}
                        />
                      </td>
                    )}
                  </tr>
                  {getAccordionState(flight.id)?.isExpanded && (
                    <tr>
                      <td
                        colSpan={tableSubheaderData.length}
                        className={cn(
                          (index + 1) % 2 === 0 && "bg-blue-300/10",
                          index + 1 === flightData.length && "border-b",
                          "h-[200px] border-r p-4 align-top",
                        )}
                      >
                        <AccordionContentContract flightId={flight.id!} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
