import { Route } from "@tanstack/react-router"

import { clientsRoute } from "#pages/clients/index.js"

export const Activities = () => {
  return (
    <div className="flex flex-col">
      <p>Activities</p>
    </div>
  )
}

export const clientsActivitiesRoute = new Route({
  getParentRoute: () => clientsRoute,
  path: "$client/activities",
  component: Activities,
})
