import { cn } from "@fourel/ui"

export const SpinningLogo = (props: React.HTMLAttributes<HTMLImageElement>) => {
  return (
    <img
      src="/fourel-logo.svg"
      alt="Loading"
      style={{ animation: "logo-spin 1s ease-in-out infinite" }}
      {...props}
      className={cn("h-[128px] w-[128px]", props.className)}
    />
  )
}
