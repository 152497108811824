import type { VariablesOf } from "@graphql-typed-document-node/core"
import { useMutation } from "@tanstack/react-query"
import { useWatch, type UseFormReturn } from "react-hook-form"

import {
  cn,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  ToastAction,
  useToast,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client.js"
import type { SelectedValueType } from "#pages/flights/models/flight-follow-up-types.js"

import type { FlightFollowUpListFormType } from "../../flights-list.js"

const FlightUpdateEnumStatusesDocuments = graphql(/* GraphQL */ `
  mutation FlightUpdateEnumStatuses($input: MutationFlightUpdateEnumStatusesInput!) {
    flightUpdateEnumStatuses(input: $input)
  }
`)

interface SelectOptionType {
  value: SelectedValueType
  field: string
  default: boolean
}
interface FlightsListSelectProps {
  fieldName:
    | `generalTableLegs.${number}.status`
    | `generalTableLegs.${number}.paxBrief`
    | `generalTableLegs.${number}.departureTerminal`
    | `generalTableLegs.${number}.arrivalTerminal`
    | `preparationTableLegs.${number}.depArrConfirmation`
    | `preparationTableLegs.${number}.pax`
    | `preparationTableLegs.${number}.flightCatering`
    | `preparationTableLegs.${number}.transfer`
    | `preparationTableLegs.${number}.pprForCar`
    | `preFlightTableLegs.${number}.acPosition`
    | `preFlightTableLegs.${number}.slots`
    | `preFlightTableLegs.${number}.crew`
    | `preFlightTableLegs.${number}.fuel`
    | `preFlightTableLegs.${number}.catering`
    | `preFlightTableLegs.${number}.acStatus`
    | `preFlightTableLegs.${number}.pax`
    | `contractPaymentTableLegs.${number}.operatorContract`
    | `contractPaymentTableLegs.${number}.clientContract`
    | `contractPaymentTableLegs.${number}.operatorPayment`
    | `contractPaymentTableLegs.${number}.clientPayment`
    | `contractPaymentTableLegs.${number}.leg`
  options: SelectOptionType[]
  form: UseFormReturn<FlightFollowUpListFormType>
  flightId: string
  flightDocumentsId: string
  flightPreparationId: string
  preFlightId: string
  flightCateringId: string
  index: number
}

export function FlightsListSelect({
  options,
  fieldName,
  form,
  flightId,
  flightDocumentsId,
  flightPreparationId,
  preFlightId,
  flightCateringId,
  index,
}: FlightsListSelectProps) {
  const { toast } = useToast()

  const { mutate: mutateFlight, isPending: isPendingFlight } = useMutation({
    mutationFn: (variables: VariablesOf<typeof FlightUpdateEnumStatusesDocuments>) =>
      client.request(FlightUpdateEnumStatusesDocuments, variables),
    onSuccess: () => {
      toast({
        title: "Success!",
        description: "Flight has been updated successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async (error) => {
      console.warn(error)
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while updating a Flight.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const selectedValue = useWatch({
    control: form.control,
    name: fieldName,
  })

  const submitOnChange = (
    curFlightId: string,
    curFlightDocumentsId: string,
    curPreFlightId: string,
    curFlightPreparationId: string,
    curFlightCateringId: string,
    curIndex: number,
  ) => {
    void form.handleSubmit((values: FlightFollowUpListFormType) => {
      const currentPreFlight = values.preFlightTableLegs[curIndex]
      const currentContractPayment = values.contractPaymentTableLegs[curIndex]
      const currentPreparation = values.preparationTableLegs[curIndex]
      const currentGeneral = values.generalTableLegs[curIndex]
      const currentFlightCatering = values.preparationTableLegs[curIndex].flightCatering

      mutateFlight({
        input: {
          flight: {
            arrivalTerminal: currentGeneral.arrivalTerminal!,
            departureTerminal: currentGeneral.departureTerminal!,
            paxBrief: currentGeneral.paxBrief === "yes",
            status: currentGeneral.status!,
          },
          flightId: curFlightId,
          flightDocuments: {
            clientContract: currentContractPayment.clientContract!,
            operatorContract: currentContractPayment.operatorContract!,
            clientPayment: currentContractPayment.clientPayment!,
            operatorPayment: currentContractPayment.operatorPayment!,
            leg: currentContractPayment.leg!,
          },
          flightDocumentsId: curFlightDocumentsId,
          flightPreparation: {
            depArrConfirmation: currentPreparation.depArrConfirmation!,
            pax: currentPreparation.pax!,
            pprForCar: currentPreparation.pprForCar!,
            transfer: currentPreparation.transfer!,
          },
          flightPreparationId: curFlightPreparationId,
          preFlight: {
            acPosition: currentPreFlight.acPosition!,
            acStatus: currentPreFlight.acStatus!,
            catering: currentPreFlight.catering!,
            crew: currentPreFlight.crew!,
            fuel: currentPreFlight.fuel === "yes",
            pax: currentPreFlight.pax!,
            slots: currentPreFlight.slots!,
          },
          preFlightId: curPreFlightId,
          flightCatering: currentFlightCatering!,
          flightCateringId: curFlightCateringId,
        },
      })
    })()
  }

  const onValueChangeHandler = (value: SelectedValueType) => {
    form.setValue(fieldName, value)
    submitOnChange(
      flightId,
      flightDocumentsId,
      preFlightId,
      flightPreparationId,
      flightCateringId,
      index,
    )
  }

  const curSelectValue = form.getValues(fieldName)
  const isDefault = options.find((option) => option.value === curSelectValue)?.default

  const bgColor = isDefault ? "bg-red-100" : "bg-green-100"

  return (
    <Select
      value={selectedValue}
      disabled={isPendingFlight}
      onValueChange={onValueChangeHandler}
    >
      <SelectTrigger className={cn(bgColor, "text-sm")}>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {options.map((item, idx) => (
            <SelectItem
              key={`${item.value}-${idx}`}
              value={item.value}
              className="text-xs"
            >
              {item.field}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
