import { useQuery } from "@tanstack/react-query"
import type { ColumnDef } from "@tanstack/react-table"
import { z } from "zod"

import { cn, Separator } from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { useTableResize } from "#pages/clients/utils/use-table-resize.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

import { DataTable } from "./invites-table.js"

const InviteByOrganizationIdDocument = graphql(/* GraphQL */ `
  query InvitesByOrganizationId($input: QueryInviteByOrganizationIdInput!) {
    inviteByOrganizationId(input: $input) {
      id
      email
      role
    }
  }
`)

const inviteSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  role: z.string(),
})
type Invite = z.infer<typeof inviteSchema>

const columns: ColumnDef<Invite>[] = [
  {
    id: "number",
    header: () => <div className="flex justify-center">#</div>,
    cell: ({ row }) => <div className="flex justify-center">{row.index + 1}</div>,
  },
  {
    id: "email",
    header: () => <div className="flex justify-center">Email</div>,
    cell: ({ row }) => <div className="flex justify-center">{row.original.email}</div>,
  },
  {
    id: "role",
    header: () => <div className="flex justify-center">Role</div>,
    cell: ({ row }) => (
      <div className="flex justify-center">
        {row.original.role[0].toUpperCase() + row.original.role.slice(1)}
      </div>
    ),
  },
]
export const SettingsCompanyInvites = () => {
  const { currentOrg } = useOnboardedUserInfo()
  const { data } = useQuery({
    queryKey: ["OrganizationInvites"],
    queryFn: () =>
      client.request(InviteByOrganizationIdDocument, {
        input: { organizationId: currentOrg.id },
      }),
  })
  const db = z.array(inviteSchema).parse(data?.inviteByOrganizationId ?? [])
  const width = useTableResize()
  return (
    <div className="pt-12">
      <h1 className="dark:text-foreground pb-1 text-lg font-semibold text-slate-900">
        Company pending invites
      </h1>
      <span className="dark:text-muted-foreground block pb-6 text-sm text-slate-600">
        All of your company's invitations can be found below.
      </span>
      <Separator />
      <div className={cn(`width-[${width}]`)}>
        {db.length !== 0 ? (
          <DataTable data={db} columns={columns} />
        ) : (
          <span className="dark:text-muted-foreground block pt-6 text-sm text-slate-600">
            There are currently no pending invitations.
          </span>
        )}
      </div>
    </div>
  )
}
