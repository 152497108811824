import type { UseFormReturn } from "react-hook-form"

import type { FlightFollowUpListFormType } from "#pages/flights/flights-list/flights-list.js"
import { useTableStateStore } from "#pages/flights/flights-store.js"

import { AccordionFormCrew } from "./components/accordion-form-crew.js"
import { AccordionFormOperator } from "./components/accordion-form-operator.js"
import { AccordionFormResponsible } from "./components/accordion-form-responsible.js"

export const AccordionContentPreparation = ({
  form,
  flightId,
  flightIndex,
}: {
  form: UseFormReturn<FlightFollowUpListFormType>
  flightId: string
  flightIndex: number
}) => {
  const { isGeneralTableExpanded, isPreparationTableExpanded } = useTableStateStore()
  return (
    <div className="flex gap-52">
      {!isGeneralTableExpanded && isPreparationTableExpanded && (
        <>
          <AccordionFormCrew flightId={flightId} form={form} flightIndex={flightIndex} />
          <AccordionFormResponsible
            flightId={flightId}
            form={form}
            flightIndex={flightIndex}
          />
        </>
      )}
      {isGeneralTableExpanded && isPreparationTableExpanded && (
        <AccordionFormOperator flightId={flightId} />
      )}
      {!isGeneralTableExpanded && !isPreparationTableExpanded && (
        <AccordionFormCrew flightId={flightId} form={form} flightIndex={flightIndex} />
      )}
      {isGeneralTableExpanded && !isPreparationTableExpanded && (
        <AccordionFormOperator flightId={flightId} />
      )}
    </div>
  )
}
