import { Link } from "@tanstack/react-router"

import { Button } from "@fourel/ui"

import { FeatureGate } from "#feature-gate/feature-gate.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

export const ClientsListHeader = () => {
  const { currentOrg } = useOnboardedUserInfo()

  return (
    <div className="flex h-16 w-full items-center justify-between border-b border-gray-200 bg-gray-50 p-4 px-6 dark:bg-gray-950">
      <h1 className="text-xl font-bold md:text-2xl">Clients</h1>
      <div className="flex gap-2">
        <Link to="/$slug/clients/add-client" params={{ slug: currentOrg.slug }}>
          <Button variant="primary" className="p-2 text-xs md:p-4 md:text-sm">
            Add client
          </Button>
        </Link>
        <FeatureGate name="ClientImportButton">
          <Button variant="outline" className="p-2 text-xs md:p-4 md:text-sm">
            Import
          </Button>
        </FeatureGate>
      </div>
    </div>
  )
}
