import type { VariablesOf } from "@graphql-typed-document-node/core"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "@tanstack/react-router"
import DeleteIcon from "lucide-static/icons/trash-2.svg"
import React from "react"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  ToastAction,
  useToast,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client.js"
import { queryClient } from "#query-client.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

const ClientContactDeleteDocument = graphql(/* GraphQL */ `
  mutation ClientContactDelete($input: MutationClientContactDeleteInput!) {
    clientContactDelete(input: $input)
  }
`)

export const DeleteModal = ({
  id,
  clientId,
  buttonName,
  modalDescription,
  customStyles,
}: {
  id: string
  buttonName: string
  modalDescription: string
  customStyles?: string
  clientId?: string
}) => {
  const { toast } = useToast()
  const navigate = useNavigate()
  const { currentOrg } = useOnboardedUserInfo()

  const { mutate } = useMutation({
    mutationFn: (variables: VariablesOf<typeof ClientContactDeleteDocument>) =>
      client.request(ClientContactDeleteDocument, variables),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["clientGetContacts"] })
      void navigate({
        to: "/$slug/clients/$client/contacts",
        params: { slug: currentOrg.slug, client: clientId! },
      })
      toast({
        title: "Success!",
        description: "Client contact has been deleted successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async () => {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while deleting a client contact.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const deleteHandler = () => {
    mutate({
      input: {
        contactId: id,
      },
    })
  }

  return (
    <div className={customStyles}>
      <Dialog>
        <DialogTrigger asChild>
          <Button variant="outline">
            <InlineSVG src={DeleteIcon} className="pr-2" />
            <span>{buttonName}</span>
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete</DialogTitle>
            <DialogDescription>{modalDescription}</DialogDescription>
          </DialogHeader>
          <div className="flex gap-4">
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>
            <DialogTrigger asChild>
              <Button variant="destructive" onClick={deleteHandler}>
                Delete
              </Button>
            </DialogTrigger>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
