import { lazyRouteComponent, Route } from "@tanstack/react-router"
import AlertCircle from "lucide-static/icons/alert-circle.svg"
import InlineSVG from "react-inlinesvg"

import { Alert, AlertDescription, AlertTitle } from "@fourel/ui"

import { rootRoute } from "#router.js"

export const flightBriefRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/flight-brief",
  validateSearch: (search: Record<string, unknown>): { flightId: string } => {
    if ("flightId" in search) {
      return {
        flightId: String(search.flightId),
      }
    }
    throw new Error("Invalid URL")
  },
}).update({
  errorComponent: ({ error }) => {
    return (
      <div className="relative h-[100vh] w-[100vw]">
        <Alert
          variant="destructive"
          className="absoluabsolute -translate-y-1/2te left-1/2 top-1/2 w-max -translate-x-1/2 transform"
        >
          <InlineSVG src={AlertCircle} className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription className="flex flex-col gap-2">
            <p>
              {error instanceof Error ? error?.message : "Unknown error"}, please retry
            </p>
          </AlertDescription>
        </Alert>
      </div>
    )
  },
  component: lazyRouteComponent(() => import("./flight-brief.js"), "FlightBrief"),
})
