import { useQuery } from "@tanstack/react-query"
import { Route, useNavigate, useRouterState } from "@tanstack/react-router"
import type {
  ColumnDef,
  ColumnFiltersState,
  Row,
  SortingState,
  VisibilityState,
} from "@tanstack/react-table"
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import DotsHorizontalIcon from "lucide-static/icons/more-horizontal.svg"
import PencilLineIcon from "lucide-static/icons/pencil-line.svg"
import TrashIcon from "lucide-static/icons/trash-2.svg"
import { useMemo, useState } from "react"
import InlineSVG from "react-inlinesvg"
import { z } from "zod"

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Separator,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client"
import { useTableResize } from "#pages/clients/utils/use-table-resize.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

import { clientsContactsRoute } from "../index.js"

const ClientDocument = graphql(/* GraphQL */ `
  query ClientContacts($input: QueryClientInput!) {
    client(input: $input) {
      contacts {
        id
        firstName
        lastName
        email
        phone
      }
    }
  }
`)

const ContactDataType = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string(),
})

type ContactData = z.infer<typeof ContactDataType>

const columns: ColumnDef<ContactData>[] = [
  {
    accessorKey: "firstName",
    header: "First Name",
    cell: ({ row }) => <div className="font-medium">{row.getValue("firstName")}</div>,
  },
  {
    accessorKey: "lastName",
    header: () => "Last Name",
    cell: ({ row }) => (
      <div className="text-start font-medium">{row.getValue("lastName")}</div>
    ),
  },
  {
    accessorKey: "email",
    header: () => "Email",
    cell: ({ row }) => {
      return <div className="text-start font-medium">{row.getValue("email")}</div>
    },
  },
  {
    accessorKey: "phone",
    header: () => "Phone",
    cell: ({ row }) => {
      const phoneNum = row.getValue("phone") as string
      const formattedNumber = phoneNum.replace(
        /(\+\d{2})(\d{3})(\d{3})(\d{3})/,
        "$1 $2 $3 $4",
      )
      return <div className="text-start font-medium">{formattedNumber}</div>
    },
  },
  {
    id: "actions",
    enableHiding: false,
    header: () => <div className="text-center">Action</div>,
    cell: ({ row }) => {
      const payment = row.original

      return (
        <div className="text-center">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="h-10 w-10 p-0">
                <span className="sr-only">Open menu</span>
                <InlineSVG src={DotsHorizontalIcon} className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                className="flex justify-between"
                onClick={() => navigator.clipboard.writeText(payment.id)}
              >
                <span>Edit</span>
                <InlineSVG src={PencilLineIcon} className="h-4 w-4" />
              </DropdownMenuItem>
              <Separator />
              <DropdownMenuItem className="flex justify-between">
                <span>Delete</span>
                <InlineSVG src={TrashIcon} className="h-4 w-4" />
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )
    },
  },
]

export function Contacts() {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = useState({})
  const { currentOrg } = useOnboardedUserInfo()
  const dynamicTableWidth = useTableResize()

  const navigate = useNavigate()

  const [clientId] = useRouterState({
    select: (s) => s.location.pathname.split("/clients")[1].split("/").slice(1),
  })

  const { data: queryData } = useQuery({
    queryKey: ["clientGetContacts"],
    queryFn: () => client.request(ClientDocument, { input: { clientId } }),
  })

  const data: ContactData[] = useMemo(
    () =>
      queryData?.client.contacts.map((it) => ({
        id: it.id,
        firstName: `${it.firstName}`,
        lastName: `${it.lastName}`,
        email: `${it.email}` ?? "",
        phone: `${it.phone}` ?? "",
      })) ?? [],
    [queryData],
  )

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  const navigateToEditContactHandler = (row: Row<ContactData>) => {
    const contactId = row.original.id
    void navigate({
      to: `/$slug/clients/$client/contacts/$contact`,
      params: { slug: currentOrg.slug, client: clientId, contact: contactId },
    })
  }

  return (
    <div
      className="overflow-x-auto rounded-md border"
      style={{ width: dynamicTableWidth }}
    >
      <Table className="whitespace-nowrap">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                onClick={() => navigateToEditContactHandler(row)}
                className="hover:cursor-pointer"
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

export const clientsContactsListRoute = new Route({
  getParentRoute: () => clientsContactsRoute,
  path: "/",
  component: Contacts,
})
