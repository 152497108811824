import type { ControllerRenderProps } from "react-hook-form"

import { Button, FormControl, FormItem, Input } from "@fourel/ui"

import type { AircraftFormType } from "../create-aircraft-modal.js"

interface PaxSelectProps {
  field: ControllerRenderProps<AircraftFormType, "pax">
  setValue: (field: "pax", value: string) => void
  revalidate: (name: "pax") => void
}

export const PaxSelect = ({ field, setValue, revalidate }: PaxSelectProps) => {
  return (
    <FormItem className="w-full">
      <div className="flex items-center justify-between gap-2">
        <FormControl>
          <div className="flex items-center gap-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                const value = parseInt(field.value, 10) || 1
                if (value > 1) {
                  setValue("pax", `${value - 1}`)
                  revalidate("pax")
                }
              }}
            >
              -
            </Button>
            <Input
              className="text-center text-xs font-medium placeholder:text-left hover:bg-slate-100"
              type="text"
              placeholder="Passengers*"
              autoFocus={false}
              maxLength={2}
              {...field}
              onChange={(event) => {
                const value = event.target.value.replace(/[^0-9]/g, "")
                setValue("pax", value)
                revalidate("pax")
              }}
            />
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                const value = parseInt(field.value, 10) || 0
                if (value < 50) {
                  setValue("pax", `${value + 1}`)
                  revalidate("pax")
                }
              }}
            >
              +
            </Button>
          </div>
        </FormControl>
      </div>
    </FormItem>
  )
}
