import { zodResolver } from "@hookform/resolvers/zod"
import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Form } from "@fourel/ui"

import { graphql } from "#gql"
import { client } from "#graphql-client.js"
import { FlightsSpinningLogo } from "#pages/flights/components/flights-spinning-logo.js"

import { useReferenceDateStore } from "../flights-store.js"
import { TableContractPayment } from "./tables/table-contract-payment.js"
import { TableGeneral } from "./tables/table-general.js"
import { TablePreFlight } from "./tables/table-pre-flight.js"
import { TablePreparation } from "./tables/table-preparation.js"

const generalTableSchema = z.object({
  status: z.enum(["not_selected", "confirmed", "awaiting_reply", "cancelled"]).optional(),
  paxBrief: z.enum(["yes", "no"]).optional(),
  departureTerminal: z
    .enum(["not_selected", "vip", "general_aviation_terminal", "main"])
    .optional(),
  arrivalTerminal: z
    .enum(["not_selected", "vip", "general_aviation_terminal", "main"])
    .optional(),
})

const preparationTableSchema = z.object({
  depArrConfirmation: z.enum(["confirmed", "not_selected", "need_update"]).optional(),
  pax: z.enum(["requested", "sent", "received"]).optional(),
  flightCatering: z
    .enum([
      "not_selected",
      "not_required",
      "pending",
      "confirmed",
      "req_sent_to_pax",
      "received_from_pax",
      "req_sent_to_operator",
      "confirmed_by_operator",
    ])
    .optional(),
  transfer: z
    .enum([
      "not_selected",
      "not_required",
      "requested",
      "sent_to_operator",
      "confirmed_by_operator",
    ])
    .optional(),
  pprForCar: z
    .enum(["not_selected", "not_required", "requested", "confirmed"])
    .optional(),
})

const preFlightTableSchema = z.object({
  acPosition: z.enum(["not_departed", "departed"]).optional(),
  slots: z.enum(["not_granted", "granted"]).optional(),
  crew: z.enum(["not_arrived", "arrived"]).optional(),
  fuel: z.enum(["no", "yes"]).optional(),
  catering: z.enum(["not_loaded", "loaded"]).optional(),
  acStatus: z.enum(["not_ready", "ready"]).optional(),
  pax: z.enum(["not_arrived", "arrived"]).optional(),
})

const contractPaymentTableSchema = z.object({
  operatorContract: z.enum(["signed", "not_signed"]).optional(),
  clientContract: z.enum(["signed", "not_signed"]).optional(),
  operatorPayment: z.enum(["paid", "not_paid"]).optional(),
  clientPayment: z.enum(["paid", "not_paid"]).optional(),
  leg: z.enum(["not_selected", "confirmed", "completed"]).optional(),
})

const flightCrewTableSchema = z.array(
  z.object({
    id: z.string(),
    position: z.enum(["pilot_in_command", "first_officer", "flight_attendant"]),
    name: z.string().optional(),
    phone: z
      .union([
        z.string().regex(/^\+?[1-9]\d{1,14}$/, "Invalid phone number format"),
        z.literal(""),
      ])
      .optional(),
  }),
)

const flightHostSchema = z.object({
  departureHost: z.object({
    id: z.string().optional(),
    name: z.string().optional(),
    phone: z.string().optional(),
  }),
  arrivalHost: z.object({
    id: z.string().optional(),
    name: z.string().optional(),
    phone: z.string().optional(),
  }),
})

const flightAssistantSchema = z.object({
  brokerAssistant: z
    .array(
      z.string().refine((val) => val !== "", {
        message: "Broker Assistant is required",
      }),
    )
    .max(2, { message: "You can select up to 2 users only" }),

  flightSupports: z
    .array(
      z.string().refine((val) => val !== "", {
        message: "Flight Supports is required",
      }),
    )
    .max(2, { message: "You can select up to 2 users only" }),
})

const flightLegSchema = z.object({
  departureDate: z
    .union([z.date().nullable(), z.string().nullable()])
    .refine((val) => val !== null && val !== "", {
      message: "Departure date is required",
    }),
  estimatedTimeOfDeparture: z.string().min(1, {
    message: "Time of departure is required",
  }),
  pax: z
    .string()
    .trim()
    .refine(
      (value) => {
        const parsedValue = parseInt(value, 10)
        return !isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= 50
      },
      {
        message: "Pax is required",
      },
    ),
})

const flightFollowUpListSchema = z.object({
  generalTableLegs: generalTableSchema.array(),
  preparationTableLegs: preparationTableSchema.array(),
  preFlightTableLegs: preFlightTableSchema.array(),
  contractPaymentTableLegs: contractPaymentTableSchema.array(),
  flightCrew: flightCrewTableSchema.array(),
  flightHost: flightHostSchema.array(),
  flightAssistant: flightAssistantSchema.array(),
  flightLeg: flightLegSchema.array(),
})

export type FlightFollowUpListFormType = z.infer<typeof flightFollowUpListSchema>

const FlightsOrganizationByDateDocument = graphql(/* GraphQL */ `
  query FlightsOrganizationByDate($input: QueryFlightsOrganizationByDateInput!) {
    flightsOrganizationByDate(input: $input) {
      id
      sequenceNumber
      status
      createdAt
      updatedAt
      organizationId
      arrivalTerminal
      departureTerminal
      crew {
        id
        name
        phone
        position
        createdAt
        updatedAt
      }
      trip {
        id
        status
      }
      flightDocuments {
        id
        clientContract
        clientPayment
        operatorContract
        operatorPayment
        leg
        createdAt
        updatedAt
      }
      flightPreparation {
        id
        pax
        depArrConfirmation
        flightCatering {
          id
          status
          createdAt
          updatedAt
        }
        pprForCar
        transfer
        createdAt
        updatedAt
      }
      paxBrief
      preFlight {
        pax
        crew
        acPosition
        acStatus
        catering
        id
        fuel
        slots
        createdAt
        updatedAt
      }
      remarks
      taskList {
        id
        status
        description
      }
      quoteLeg {
        estimatedTimeOfDeparture
        estimatedTimeOfArrival
        departureAirport {
          icaoCode
          iataCode
        }
        arrivalAirport {
          icaoCode
          iataCode
        }
        arrivalDate
        departureDate
        pax
        aircraft {
          registrationNumber
        }
      }
      departureAirportFlightHost {
        id
        name
        phone
        createdAt
        updatedAt
      }
      arrivalAirportFlightHost {
        id
        name
        phone
        createdAt
        updatedAt
      }
      flightBroker {
        id
        name
      }
      flightBrokerAssistance {
        id
        name
      }
      flightSupports {
        id
        name
      }
    }
  }
`)

const tablesDefaultValues: FlightFollowUpListFormType = {
  generalTableLegs: [
    {
      status: "not_selected",
      paxBrief: "no",
      departureTerminal: "not_selected",
      arrivalTerminal: "not_selected",
    },
  ],
  preparationTableLegs: [
    {
      depArrConfirmation: "not_selected",
      pax: "requested",
      flightCatering: "not_selected",
      transfer: "not_selected",
      pprForCar: "not_selected",
    },
  ],
  preFlightTableLegs: [
    {
      acPosition: "not_departed",
      slots: "not_granted",
      crew: "not_arrived",
      fuel: "no",
      catering: "not_loaded",
      acStatus: "not_ready",
      pax: "not_arrived",
    },
  ],
  contractPaymentTableLegs: [
    {
      operatorContract: "signed",
      clientContract: "signed",
      operatorPayment: "not_paid",
      clientPayment: "not_paid",
      leg: "not_selected",
    },
  ],
  flightCrew: [
    [
      { id: "", name: "", phone: "", position: "pilot_in_command" },
      { id: "", name: "", phone: "", position: "first_officer" },
      { id: "", name: "", phone: "", position: "flight_attendant" },
    ],
  ],
  flightHost: [
    {
      departureHost: {
        id: "",
        name: "",
        phone: "",
      },
      arrivalHost: {
        id: "",
        name: "",
        phone: "",
      },
    },
  ],
  flightAssistant: [
    {
      brokerAssistant: [],
      flightSupports: [],
    },
  ],
  flightLeg: [
    {
      departureDate: "",
      estimatedTimeOfDeparture: "",
      pax: "",
    },
  ],
}

export const FlightsList = () => {
  const { referenceDate, endDate } = useReferenceDateStore()

  const { data, isLoading } = useQuery({
    queryKey: ["organizationFlightsByDate", referenceDate, endDate],
    queryFn: () =>
      client.request(FlightsOrganizationByDateDocument, {
        input: {
          date: referenceDate.toISOString(),
          endDate: endDate ? endDate.toISOString() : null,
        },
      }),
    enabled: !!referenceDate,
  })

  const form = useForm<FlightFollowUpListFormType>({
    resolver: zodResolver(flightFollowUpListSchema),
    defaultValues: tablesDefaultValues,
    reValidateMode: "onChange",
    mode: "all",
  })

  useEffect(() => {
    if (!isLoading && data && data.flightsOrganizationByDate.length) {
      const mappedData: FlightFollowUpListFormType = {
        generalTableLegs: data.flightsOrganizationByDate.map((flight) => ({
          status: flight.status || "not_selected",
          paxBrief: flight.paxBrief ? "yes" : "no",
          departureTerminal: flight.departureTerminal || "not_selected",
          arrivalTerminal: flight.arrivalTerminal || "not_selected",
        })),
        preparationTableLegs: data.flightsOrganizationByDate.map((flight) => ({
          depArrConfirmation:
            flight.flightPreparation?.depArrConfirmation || "not_selected",
          pax: flight.flightPreparation?.pax || "requested",
          flightCatering:
            flight.flightPreparation?.flightCatering[0]?.status || "not_selected",
          transfer: flight.flightPreparation?.transfer || "not_selected",
          pprForCar: flight.flightPreparation?.pprForCar || "not_selected",
        })),
        preFlightTableLegs: data.flightsOrganizationByDate.map((flight) => ({
          acPosition: flight.preFlight?.acPosition || "not_departed",
          slots: flight.preFlight?.slots || "not_granted",
          crew: flight.preFlight?.crew || "not_arrived",
          fuel: flight.preFlight?.fuel ? "yes" : "no",
          catering: flight.preFlight?.catering || "not_loaded",
          acStatus: flight.preFlight?.acStatus || "not_ready",
          pax: flight.preFlight?.pax || "not_arrived",
        })),
        contractPaymentTableLegs: data.flightsOrganizationByDate.map((flight) => ({
          operatorContract: flight.flightDocuments?.operatorContract || "signed",
          clientContract: flight.flightDocuments?.clientContract || "signed",
          operatorPayment: flight.flightDocuments?.operatorPayment || "not_paid",
          clientPayment: flight.flightDocuments?.clientPayment || "not_paid",
          leg: flight.flightDocuments?.leg || "not_selected",
        })),
        flightCrew: data.flightsOrganizationByDate.map((flight) => {
          return flight.crew.map((item) => ({
            id: item.id!,
            position: item.position!,
            name: item.name || "",
            phone: item.phone || "",
          }))
        }),
        flightHost: data.flightsOrganizationByDate.map((flight) => {
          return {
            departureHost: {
              id: flight.departureAirportFlightHost?.id || "",
              name: flight.departureAirportFlightHost?.name || "",
              phone: flight.departureAirportFlightHost?.phone || "",
            },
            arrivalHost: {
              id: flight.arrivalAirportFlightHost?.id || "",
              name: flight.arrivalAirportFlightHost?.name || "",
              phone: flight.arrivalAirportFlightHost?.phone || "",
            },
          }
        }),
        flightAssistant: data.flightsOrganizationByDate.map((flight) => ({
          brokerAssistant: flight.flightBrokerAssistance?.map((it) => it.id) || [],
          flightSupports: flight.flightSupports?.map((it) => it.id) || [],
        })),

        flightLeg: data.flightsOrganizationByDate.map((flight) => ({
          departureDate: flight.quoteLeg?.departureDate.slice(0, 10) || "",
          estimatedTimeOfDeparture:
            flight.quoteLeg?.estimatedTimeOfDeparture.slice(0, 5) || "",
          pax: String(flight.quoteLeg?.pax) || "",
        })),
      }

      form.reset(mappedData)
    }
  }, [data, form, isLoading])

  if (isLoading) {
    return <FlightsSpinningLogo />
  }

  return (
    <Form {...form}>
      <form>
        {data && data.flightsOrganizationByDate.length ? (
          <div className="flex max-w-full overflow-x-auto *:mb-2">
            <TableGeneral flightData={data.flightsOrganizationByDate} form={form} />
            <TablePreparation flightData={data.flightsOrganizationByDate} form={form} />
            <TablePreFlight flightData={data.flightsOrganizationByDate} form={form} />
            <TableContractPayment
              flightData={data.flightsOrganizationByDate}
              form={form}
            />
          </div>
        ) : (
          <p className="mt-12 text-center">No flights have found</p>
        )}
      </form>
    </Form>
  )
}
