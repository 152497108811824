import type { VariablesOf } from "@graphql-typed-document-node/core"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"

import {
  Button,
  Dialog,
  DialogClose,
  DialogContentCustom,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ScrollArea,
  Textarea,
  ToastAction,
  useToast,
} from "@fourel/ui"

import { QueryKeys } from "#constants/query-keys.js"
import { graphql } from "#gql"
import { client } from "#graphql-client.js"

import { AircraftClassSelect } from "./components/aircraft-class-select.js"
import { AircraftOperatorSelect } from "./components/aircraft-operator-select.js"
import { PaxSelect } from "./components/pax-select.js"
import { UploadArea } from "./components/upload-area.js"
import { YearOfBuildInput } from "./components/year-of-build-input.js"

const OperatorsDocument = graphql(/* GraphQL */ `
  query Operators {
    operators {
      id
      name
    }
  }
`)

const AircraftCreateDocument = graphql(/* GraphQL */ `
  mutation AircraftCreate($input: MutationAircraftCreateInput!) {
    aircraftCreate(input: $input) {
      id
      operatorId
      registrationNumber
      model
      class
      location
      yearOfBuild
      pax
      description
      images
    }
  }
`)

const IsUniqueAircraftRegNumberDocument = graphql(/* GraphQL */ `
  query IsUniqueAircraftRegNumber($input: QueryIsUniqueAircraftRegNumberInput!) {
    isUniqueAircraftRegNumber(input: $input)
  }
`)

const fileSchema = z.instanceof(File).refine((file) => file.type === "image/png")

const aircraftSchema = z.object({
  operatorName: z.string().min(1, "Operator Name is required").max(150),
  operatorId: z.string().min(1, "Operator Name is required"),
  model: z.string().min(1, "Aircraft Model is required").max(150),
  class: z.string().min(1, "Aircraft Class is required").max(150),
  location: z.string().min(1, "Location is required").max(150),
  yearOfBuild: z.coerce
    .number()
    .gte(1900, "Year of build must be a valid year greater than 1900.")
    .lte(new Date().getFullYear(), "Year of build cannot be in the future.")
    .refine((value) => value !== undefined || value !== null, {
      message: "Year of build is required",
    }),
  pax: z
    .string()
    .trim()
    .refine(
      (value) => {
        const parsedValue = parseInt(value, 10)
        return !isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= 50
      },
      {
        message: "Pax is required",
      },
    ),
  regNumber: z
    .string()
    .min(5, "Reg. Number must contain at least 5 characters")
    .max(11, "Reg. Number must contain maximum 11 characters"),
  description: z.literal("").or(z.string().min(1, "Description is optional")),
  files: z
    .array(fileSchema)
    .default([])
    .nullable()
    .refine((files) => files?.every((file) => file.type === "image/png"), {
      message: "Only png files are allowed.",
    }),
})

export type AircraftFormType = z.infer<typeof aircraftSchema>

const AircraftFormInputFields = [
  { field: "model", label: "Aircraft Model", placeholder: "Aircraft Model" },
  { field: "regNumber", label: "Reg. Number", placeholder: "Reg. Number" },
  { field: "location", label: "Location", placeholder: "Location" },
] as const

export const CreateAircraftModal = () => {
  const { toast } = useToast()
  const [isOpen, setIsOpen] = useState(false)

  const form = useForm<AircraftFormType>({
    resolver: zodResolver(aircraftSchema),
    defaultValues: {
      operatorName: "",
      class: "",
      model: "",
      location: "",
      regNumber: "",
      description: "",
      files: [],
      pax: "",
      yearOfBuild: undefined,
    },

    reValidateMode: "onBlur",
  })

  const { data } = useQuery({
    queryKey: [QueryKeys.Operators.Get],
    queryFn: () => client.request(OperatorsDocument),
  })

  const fetchedOperator =
    data?.operators.map((it) => ({
      id: it.id,
      name: it.name,
    })) || []

  const { mutate } = useMutation({
    mutationFn: (variables: VariablesOf<typeof AircraftCreateDocument>) =>
      client.request(AircraftCreateDocument, variables),
    onSuccess: () => {
      form.reset()
      toast({
        title: "Success!",
        description: "Aircraft has been added successfully.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
    onError: async (error) => {
      console.info(error)
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while adding an aircraft document.",
        action: <ToastAction altText="Close">Close</ToastAction>,
      })
    },
  })

  const isAvailableRegNumber = async ({ regNumber }: { regNumber: string }) => {
    const res = await client.request(IsUniqueAircraftRegNumberDocument, {
      input: { regNumber },
    })
    return res.isUniqueAircraftRegNumber
  }

  const submitHandler = form.handleSubmit(async (values: AircraftFormType) => {
    const isAvailable = await isAvailableRegNumber({ regNumber: values.regNumber })

    if (!isAvailable) {
      form.setError("regNumber", {
        type: "manual",
        message: "Aircraft with this registration number already exists.",
      })
    } else {
      const { files } = values

      const base64Files = files
        ? await Promise.all(
            files.map((file) => {
              return new Promise<{
                file: string
              }>((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  const result = reader.result as string
                  const base64File = result.split(",")[1]
                  resolve({
                    file: base64File,
                  })
                }

                reader.onerror = (error) => reject(error)
                reader.readAsDataURL(file)
              })
            }),
          )
        : []

      mutate({
        input: {
          aircraft: {
            registrationNumber: values.regNumber,
            model: values.model,
            class: values.class,
            location: values.location,
            yearOfBuild: values.yearOfBuild,
            pax: +values.pax,
            description: values.description?.trim()
              ? values.description
              : "No description",
          },
          files: base64Files,
          operatorId: values.operatorId,
        },
      })
      setIsOpen(false)
    }
  })

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild>
        <Button variant="primary" className="w-full" onClick={() => setIsOpen(true)}>
          Create an Aircraft
        </Button>
      </DialogTrigger>
      <DialogContentCustom>
        <ScrollArea className="max-h-[calc(100vh-5rem)]">
          <div className="p-2">
            <DialogHeader className="mb-4">
              <DialogTitle>Create an Aircraft</DialogTitle>
            </DialogHeader>
            <Form {...form}>
              <form
                onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
                  event.stopPropagation()
                  event.preventDefault()
                  await submitHandler()
                }}
                className="space-y-4 transition-all duration-300 ease-in-out"
              >
                <FormField
                  control={form.control}
                  name="files"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <div className="flex items-center justify-between gap-2">
                        <UploadArea
                          onFileChange={(files: File[]) => field.onChange(files)}
                          setValue={form.setValue}
                          revalidate={form.trigger}
                        />
                      </div>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="class"
                  render={({ field }) => (
                    <FormItem className="flex items-center justify-between gap-2">
                      <AircraftClassSelect
                        field={field}
                        setValue={form.setValue}
                        revalidate={form.trigger}
                      />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="yearOfBuild"
                  render={({ field }) => (
                    <YearOfBuildInput
                      field={field}
                      placeholder="Year Of Build"
                      label="Year Of Build"
                      setValue={form.setValue}
                    />
                  )}
                />
                <FormField
                  control={form.control}
                  name="operatorName"
                  render={({ field }) => (
                    <AircraftOperatorSelect
                      field={field}
                      setValue={form.setValue}
                      fetchedOperator={fetchedOperator}
                      revalidate={form.trigger}
                    />
                  )}
                />
                {AircraftFormInputFields.map((item) => (
                  <FormField
                    control={form.control}
                    name={item.field}
                    key={item.field}
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <div className="flex items-center justify-between gap-2">
                          <FormLabel className="w-1/2 text-xs">{item.label}</FormLabel>
                          <FormControl className="w-full text-xs">
                            <Input placeholder={item.placeholder} {...field} />
                          </FormControl>
                        </div>
                        <FormMessage className="text-xs" />
                      </FormItem>
                    )}
                  />
                ))}
                <FormField
                  control={form.control}
                  name="pax"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <div className="flex items-center justify-between gap-2">
                        <FormLabel className="w-1/2 text-xs">Pax</FormLabel>
                        <FormControl>
                          <PaxSelect
                            field={field}
                            setValue={form.setValue}
                            revalidate={form.trigger}
                          />
                        </FormControl>
                      </div>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Textarea
                          className="text-xs"
                          placeholder="Type an Aircraft description here."
                          onChange={(event) => field.onChange(event.target.value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex gap-4">
                  <DialogClose asChild>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        form.reset()
                        setIsOpen(false)
                      }}
                    >
                      Cancel
                    </Button>
                  </DialogClose>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={form.formState.isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </ScrollArea>
      </DialogContentCustom>
    </Dialog>
  )
}
