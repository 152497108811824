import { Route } from "@tanstack/react-router"

import { clientsRoute } from "#pages/clients/index.js"

export const Tasks = () => {
  return (
    <div className="flex flex-col">
      <p>Tasks</p>
    </div>
  )
}

export const clientsTasksRoute = new Route({
  getParentRoute: () => clientsRoute,
  path: "$client/tasks",
  component: Tasks,
})
