import ArrowDown from "lucide-static/icons/arrow-down.svg"
import ArrowUp from "lucide-static/icons/arrow-up.svg"
import InlineSVG from "react-inlinesvg"

import { Button } from "@fourel/ui"

import { useReferenceDateStore } from "../flights-store.js"

export const FlightsDateSelect = () => {
  const { referenceDate, setReferenceDate, setEndDate, setFilterTitle } =
    useReferenceDateStore()

  const goToPreviousMonth = () => {
    const newDate = new Date(referenceDate)
    newDate.setMonth(newDate.getMonth() - 1, 1)
    setReferenceDate(newDate)
    setEndDate(null)
    setFilterTitle(null)
  }

  const goToNextMonth = () => {
    const newDate = new Date(referenceDate)
    newDate.setMonth(newDate.getMonth() + 1, 1)
    setReferenceDate(newDate)
    setEndDate(null)
    setFilterTitle(null)
  }

  return (
    <div className="flex h-auto items-center gap-4">
      <div className="flex items-center gap-2">
        <h3 className="text-lg font-semibold">
          {referenceDate.toLocaleString("en-EN", { month: "long" })}{" "}
        </h3>
        <h3 className="text-lg font-semibold">{new Date().getFullYear()}</h3>
      </div>
      <Button variant="outline" onClick={goToNextMonth} className="size-8 p-0">
        <InlineSVG src={ArrowDown} className="size-4" />
      </Button>
      <Button variant="outline" onClick={goToPreviousMonth} className="size-8 p-0">
        <InlineSVG src={ArrowUp} className="size-4" />
      </Button>
    </div>
  )
}
