import { Link, useRouterState } from "@tanstack/react-router"
import AppWindow from "lucide-static/icons/app-window.svg"
import BellDot from "lucide-static/icons/bell-dot.svg"
import BuildingIcon from "lucide-static/icons/building.svg"
import ChevronRightIcon from "lucide-static/icons/chevron-right.svg"
import ReceiptText from "lucide-static/icons/receipt.svg"
import SquareUser from "lucide-static/icons/square-user.svg"
import UserCogIcon from "lucide-static/icons/user-cog.svg"
import Users from "lucide-static/icons/users.svg"
import InlineSVG from "react-inlinesvg"

import { cn, Command, CommandGroup, CommandItem, CommandList } from "@fourel/ui"

import { FeatureGate } from "#feature-gate/feature-gate.js"
import { FeaturesConfig } from "#feature-gate/features.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

const commandInputStyles =
  "mb-2 cursor-pointer gap-2 p-2 text-violet-500  aria-selected:text-violet-500 aria-selected:bg-violet-700 aria-selected:hover:bg-violet-800 aria-selected:hover:text-white aria-selected:transition-all aria-selected:duration-200 aria-selected:ease-in-out"

const workspaceItems = [
  { url: "./members", label: "Members", icon: Users },
  FeaturesConfig.SettingsBillingRoute && {
    url: "billing",
    label: "Billing",
    icon: ReceiptText,
  },
].filter(Boolean)
const profileItems = [
  { url: "./profile", label: "Profile", icon: SquareUser },
  {
    url: "./appearance",
    label: "Appearance",
    icon: AppWindow,
  },
  { url: "./notifications", label: "Notifications", icon: BellDot },
]

export const SettingsMenuTabs = ({ open }: { open: boolean }) => {
  const { currentOrg } = useOnboardedUserInfo()
  const menuCategoryRegex = /\/settings\/([^/]+)/
  const menuCategoryMatch = useRouterState({
    select: (s) => s.location.pathname.match(menuCategoryRegex),
  })
  const menuCategoryName = menuCategoryMatch ? menuCategoryMatch[1] : "no match"
  return (
    <div className="flex flex-auto overflow-hidden text-sm">
      <Command className="static w-full flex-auto overflow-y-auto bg-transparent">
        <CommandList className="flex flex-auto flex-col overflow-y-auto [&>*]:flex [&>*]:flex-auto [&>*]:flex-col">
          <CommandGroup className={cn("p-0", !open && "mr-4")}>
            <div
              className={cn(
                "mb-3 flex items-center p-2 text-stone-100/70",
                !open && "hidden",
              )}
            >
              <InlineSVG src={BuildingIcon} className="mr-2 h-4 w-4" />
              <span>Workspace settings</span>
            </div>
            {workspaceItems.map((item) => (
              <Link
                to={item.url}
                key={item.label}
                from="/$slug/settings"
                params={{ slug: currentOrg.slug }}
              >
                <CommandItem
                  className={cn(
                    commandInputStyles,
                    !open && "mx-auto w-fit",
                    item.url.includes(menuCategoryName)
                      ? "bg-violet-900 text-white aria-selected:bg-violet-900 aria-selected:text-white"
                      : "",
                  )}
                >
                  <InlineSVG src={item.icon} className="h-5 w-5 min-w-5 text-white" />
                  <span
                    className={cn(
                      "whitespace-nowrap text-white",
                      !open && "hidden text-[0px]",
                    )}
                  >
                    {item.label}
                  </span>
                  <InlineSVG
                    src={ChevronRightIcon}
                    className={cn("ml-auto h-5 w-5 min-w-5", !open && "hidden")}
                  />
                </CommandItem>
              </Link>
            ))}
            <FeatureGate name="SettingsAccount">
              <div className="py-3" />
              <div
                className={cn(
                  "mb-3 flex items-center p-2 text-stone-100/70",
                  !open && "hidden",
                )}
              >
                <InlineSVG src={UserCogIcon} className="mr-2 h-4 w-4" />
                <span>My Account</span>
              </div>
              {profileItems.map((item) => (
                <Link
                  to={item.url}
                  key={item.label}
                  from="/$slug/settings"
                  params={{ slug: currentOrg.slug }}
                >
                  <CommandItem
                    className={cn(
                      commandInputStyles,
                      !open && "mx-auto w-fit",
                      item.url.includes(menuCategoryName)
                        ? "bg-violet-900 text-white aria-selected:bg-violet-900 aria-selected:text-white"
                        : "",
                    )}
                  >
                    <InlineSVG src={item.icon} className="h-5 w-5 min-w-5 text-white" />
                    <span
                      className={cn(
                        "whitespace-nowrap text-white",
                        !open && "hidden text-[0px]",
                      )}
                    >
                      {item.label}
                    </span>
                    <InlineSVG
                      src={ChevronRightIcon}
                      className={cn("ml-auto h-5 w-5 min-w-5", !open && "hidden")}
                    />
                  </CommandItem>
                </Link>
              ))}
            </FeatureGate>
          </CommandGroup>
        </CommandList>
      </Command>
    </div>
  )
}
