import { Route } from "@tanstack/react-router"
import type {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
} from "@tanstack/react-table"
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import FileTextIcon from "lucide-static/icons/file-text.svg"
import SearchIcon from "lucide-static/icons/search.svg"
// import UploadIcon from "lucide-static/icons/upload.svg"
import { useState } from "react"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@fourel/ui"

import { clientsRoute } from "#pages/clients/index.js"
import { useTableResize } from "#pages/clients/utils/use-table-resize.js"

export type InvoiceDataType = {
  id: string
  flight: string
  flightDate: string
  regNumber: string
  contact: string
  created: string
  url: string
}

const data: InvoiceDataType[] = [
  {
    id: "m5gr84i9",
    flight: "LCA-RIX",
    flightDate: "01.03.2024-02.03.2024",
    regNumber: "YL-ARE",
    contact: "Katerina Nehlibka",
    created: "01.03.2024",
    url: "https://www.airbus.com/sites/g/files/jlcbta136/files/2023-01/Airbus-Commercial-Aircraft-presentation-January-2023.pdf",
  },
  {
    id: "3u1reuv4",
    flight: "PZD-RIX",
    flightDate: "01.03.2024-02.03.2024",
    regNumber: "YL-ARE",
    contact: "Katerina Nehlibka",
    created: "01.03.2024",
    url: "https://www.airbus.com/sites/g/files/jlcbta136/files/2023-01/Airbus-Commercial-Aircraft-presentation-January-2023.pdf",
  },
  {
    id: "derv1ws0",
    flight: "ANT-PZD",
    flightDate: "01.03.2024-02.03.2024",
    regNumber: "YL-ARE",
    contact: "Katerina Nehlibka",
    created: "01.03.2024",
    url: "https://www.airbus.com/sites/g/files/jlcbta136/files/2023-01/Airbus-Commercial-Aircraft-presentation-January-2023.pdf",
  },
  {
    id: "5kma53ae",
    flight: "MDN-ZLP",
    flightDate: "01.03.2024-02.03.2024",
    regNumber: "YL-ARE",
    contact: "Katerina Nehlibka",
    created: "01.03.2024",
    url: "https://www.mcpsmt.org/cms/lib/MT01001940/Centricity/domain/851/lesson%20plans/high/AirplanePresentation.pdf",
  },
]

const handleDownloadPdf = (
  pdfUrl: string,
  fileName: string,
  removeAfterDownload: boolean = true,
) => {
  fetch(pdfUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `${fileName}.pdf`)
      document.body.appendChild(link)
      link.click()
      if (removeAfterDownload) {
        document.body.removeChild(link)
      }
    })
    .catch((error) => console.error("Error downloading PDF:", error))
}

// const handleDownloadAllPdf = (invoiceData: InvoiceDataType[]) => {
//   invoiceData.forEach((item) => {
//     handleDownloadPdf(item.url, item.regNumber, false)
//   })
// }

export const columns: ColumnDef<InvoiceDataType>[] = [
  {
    accessorKey: "flight",
    header: "Flight",
    cell: ({ row }) => <div>{row.getValue("flight")}</div>,
  },
  {
    accessorKey: "flightDate",
    header: () => <div>Flight date</div>,
    cell: ({ row }) => <div>{row.getValue("flightDate")}</div>,
  },
  {
    accessorKey: "regNumber",
    header: () => <div>Reg. number</div>,
    cell: ({ row }) => {
      return <div>{row.getValue("regNumber")}</div>
    },
  },
  {
    accessorKey: "contact",
    header: () => <div>Contact</div>,
    cell: ({ row }) => {
      return <div>{row.getValue("contact")}</div>
    },
  },
  {
    accessorKey: "created",
    header: () => <div>Created</div>,
    cell: ({ row }) => {
      return <div>{row.getValue("created")}</div>
    },
  },
  {
    accessorKey: "url",
    header: () => <div>Files</div>,
    cell: ({ row }) => {
      const invoiceLink = row.getValue("url") as string
      const flightDirection = row.getValue("flight") as string
      const creationDate = row.getValue("created") as string
      const fileName = `${flightDirection} Invoice. ${creationDate}`

      return (
        <Button
          variant="outline"
          onClick={() => handleDownloadPdf(invoiceLink, fileName)}
        >
          <InlineSVG src={FileTextIcon} className="pr-2" />
          <span>{fileName}</span>
        </Button>
      )
    },
  },
]

export function Invoices() {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = useState({})
  const dynamicTableWidth = useTableResize()

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col items-start justify-between gap-4 lg:flex-row lg:items-center">
        {/* <Button variant="outline" onClick={() => handleDownloadAllPdf(data)}>
          <span className="pr-4">Upload all</span>
          <InlineSVG src={UploadIcon} className="w-4" />
        </Button> */}
        <div className="relative">
          <Input
            placeholder="Search"
            value={(table.getColumn("flight")?.getFilterValue() as string) ?? ""}
            onChange={(event) =>
              table.getColumn("flight")?.setFilterValue(event.target.value)
            }
            className="max-w-sm"
          />
          <InlineSVG
            src={SearchIcon}
            className="absolute right-2 top-2 w-4 text-gray-400"
          />
        </div>
      </div>
      <div
        className="overflow-x-auto rounded-md border"
        style={{ width: dynamicTableWidth }}
      >
        <Table className="whitespace-nowrap">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export const clientsInvoicesRoute = new Route({
  getParentRoute: () => clientsRoute,
  path: "$client/invoices",
  component: Invoices,
})
