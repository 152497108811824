import type { RouterHistory } from "@tanstack/react-router"
import { Outlet, redirect, RootRoute, Route, Router } from "@tanstack/react-router"
import { lazy } from "react"

import { createQuoteRoute } from "#components/quotations/create-quote/index.js"
import { Config } from "#config-fe"
import { a404Route } from "#pages/404/index.js"
import {
  advertiseRoute,
  templatesRoute,
  toMyClientsRoute,
  toNewClientsRoute,
} from "#pages/advertise/index.js"
import {
  clientsActivitiesRoute,
  clientsAddClientRoute,
  clientsAllClientsRoute,
  clientsContactsAddRoute,
  clientsContactsEditRoute,
  clientsContactsListRoute,
  clientsContactsRoute,
  clientsContractsRoute,
  clientsDocumentsRoute,
  clientsFlightsRoute,
  clientsGeneralRoute,
  clientsInvoicesRoute,
  clientsListsRoute,
  clientsOffersApprovedRoute,
  clientsOffersCancelledRoute,
  clientsOffersExpiredRoute,
  clientsOffersPendingRoute,
  clientsOffersRoute,
  clientsOffersSignedRoute,
  clientsRoute,
  clientsTasksRoute,
  clientsTriggersRoute,
} from "#pages/clients/index.js"
import { dashboardRoute } from "#pages/dashboard/index.js"
import { flightBriefRoute } from "#pages/dynamic-flight/flight-brief/flight-brief-route.js"
import { flightConfirmationRoute } from "#pages/dynamic-flight/flight-confirmation/flight-confirmation-route.js"
import { dynamicOfferRoute } from "#pages/dynamic-offer/index.js"
import {
  flightsCalendarRoute,
  flightsListRoute,
  flightsRoute,
} from "#pages/flights/index.js"
import {
  gmailCallbackRoute,
  hubspotCallbackRoute,
  integrationRoute,
  outlookCallbackRoute,
  salesforceCallbackRoute,
} from "#pages/integration/index.js"
import { Lobby } from "#pages/lobby/lobby.js"
import {
  loginGoogleRoute,
  loginIndexRoute,
  loginMappingRoute,
  loginMappingSuccessRoute,
  loginSuccessRoute,
  loginVerifyRoute,
} from "#pages/login/index.js"
import { loginInviteRoute } from "#pages/login/invite/index.js"
import { createOfferRoute } from "#pages/offers/create-offer/index.js"
import {
  approvedRoute,
  cancelledRoute,
  expiredRoute,
  offerClientRoute,
  offerEditRoute,
  offerGeneralRoute,
  offersRoute,
  pendingRoute,
  previewOfferRoute,
  signedRoute,
} from "#pages/offers/index.js"
import {
  onboardingIndexRoute,
  onboardingJoinRoute,
  onboardingMembersRoute,
  onboardingOrganizationRoute,
  onboardingSuccessRoute,
  onboardingWelcomeRoute,
} from "#pages/onboarding/index.js"
import { paymentsRoute } from "#pages/payments/index.js"
import {
  combinedQuotes,
  quotationsListRoute,
  quotationsRoute,
} from "#pages/quotations/index.js"
import {
  settingsAppearanceRoute,
  settingsBillingPlanRoute,
  settingsBillingRoute,
  settingsMembersRoute,
  settingsNotificationsRoute,
  settingsProfileRoute,
  settingsRoute,
} from "#pages/settings/index.js"
import { subscriptionRoute } from "#pages/subscription/index.js"
import {
  activeTripRequestsRoute,
  addTripRequestsRoute,
  inactiveTripRequestsRoute,
} from "#pages/trip-requests/index.js"
import { tripRequestsRoute } from "#pages/trip-requests/trip-requests-route.js"
import { useUserInfo } from "#store/user-info.js"

export const shouldNeverHappen = (_: never) => {
  return new Error("Should never happen")
}

const TanStackRouterDevtools =
  Config.VITE_ENVIRONMENT !== "local"
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in local development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      )

// Create a root route
export const rootRoute = new RootRoute({
  component: () => (
    <>
      <Outlet />
      <TanStackRouterDevtools position="bottom-right" />
    </>
  ),
})

// This function, based on the status of the user will try to figure the best URL to redirect user to.
const getBestRedirectUrl = () => {
  const userInfo = useUserInfo.getState()
  if (userInfo.state === "Init") {
    throw new Error("User info is not initialized, this should never happen")
  }

  if (userInfo.state === "NotAuthenticated") {
    throw redirect({
      to: "/login",
      search: {
        // Use the current location to power a redirect after login
        // (Do not use `router.state.resolvedLocation` as it can
        // potentially lag behind the actual current location)
        redirect: window.location.href,
      },
    })
  }

  if (userInfo.state === "Onboarded") {
    throw redirect({
      to: `/$slug/clients`,
      params: {
        slug: userInfo.currentOrg.slug,
      },
    })
  }
  if (userInfo.state === "NotOnboarded") {
    throw redirect({
      to: "/onboarding",
    })
  }
  throw shouldNeverHappen(userInfo)
}

// Create an index route
export const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/",
  beforeLoad: () => {
    const { pathname } = window.location
    const userInfo = useUserInfo.getState()

    // Not a root dir, so render whatever the page and rest of the router will handle it if needed
    if (pathname !== "/") {
      return undefined
    }
    if (userInfo.state === "NotAuthenticated") {
      throw redirect({
        to: "/login",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: pathname !== "/" ? window.location.href : undefined,
        },
      })
    }
    return getBestRedirectUrl()
  },
})

export const onboardingRoute = new Route({
  getParentRoute: () => authenticatedRoute,
  path: "/onboarding",
})

export const loginRoute = new Route({
  getParentRoute: () => indexRoute,
  path: "/login",
  validateSearch: (search) => {
    if ("redirect" in search) {
      return { redirect: search.redirect as string }
    }
    return {}
  },
  beforeLoad: () => {
    const userInfo = useUserInfo.getState()
    if (userInfo.state === "NotAuthenticated") {
      return undefined
    }
    return getBestRedirectUrl()
  },
})

export const sluggedRoute = new Route({
  getParentRoute: () => onboardedGuardRoute,
  path: "/$slug",
  component: () => <Lobby />,
})

export const authenticatedRoute = new Route({
  getParentRoute: () => rootRoute,
  id: "authenticated-route",
  beforeLoad: () => {
    const userInfo = useUserInfo.getState()
    if (userInfo.state === "NotAuthenticated") {
      return getBestRedirectUrl()
    }
    return undefined
  },
})
export const onboardedGuardRoute = new Route({
  getParentRoute: () => authenticatedRoute,
  id: "onboarded-route",
  beforeLoad: () => {
    const userInfo = useUserInfo.getState()
    if (userInfo.state === "Onboarded") {
      return undefined
    }
    return getBestRedirectUrl()
  },
})

// Create the route tree using your routes
const routeTree = rootRoute.addChildren([
  a404Route,
  dynamicOfferRoute,
  flightBriefRoute,
  flightConfirmationRoute,
  indexRoute.addChildren([
    subscriptionRoute,
    loginMappingRoute,
    loginMappingSuccessRoute,
    loginRoute.addChildren([
      loginIndexRoute,
      loginVerifyRoute,
      loginGoogleRoute,
      loginInviteRoute,
      loginSuccessRoute,
    ]),
    authenticatedRoute.addChildren([
      onboardingJoinRoute,
      onboardingRoute.addChildren([
        onboardingIndexRoute,
        onboardingWelcomeRoute,
        onboardingOrganizationRoute,
        onboardingMembersRoute,
        onboardingSuccessRoute,
      ]),
      onboardedGuardRoute.addChildren([
        hubspotCallbackRoute,
        gmailCallbackRoute,
        salesforceCallbackRoute,
        outlookCallbackRoute,
        sluggedRoute.addChildren([
          clientsRoute.addChildren([
            clientsAddClientRoute,
            clientsActivitiesRoute,
            clientsContactsRoute.addChildren([
              clientsContactsListRoute,
              clientsContactsEditRoute,
              clientsContactsAddRoute,
            ]),
            clientsContractsRoute,
            clientsDocumentsRoute,
            clientsFlightsRoute,
            clientsGeneralRoute,
            clientsInvoicesRoute,
            clientsListsRoute,
            clientsAllClientsRoute,
            clientsOffersRoute.addChildren([
              clientsOffersApprovedRoute,
              clientsOffersCancelledRoute,
              clientsOffersExpiredRoute,
              clientsOffersPendingRoute,
              clientsOffersSignedRoute,
            ]),
            // clientsQuotationsRoute.addChildren([
            //   clientsCreateOfferRoute,
            //   clientsQuotationsListRoute,
            // ]),
            clientsTasksRoute,
            clientsTriggersRoute,
          ]),
          offersRoute.addChildren([
            createOfferRoute,
            previewOfferRoute.addChildren([
              offerClientRoute,
              offerEditRoute,
              offerGeneralRoute,
            ]),
            signedRoute,
            approvedRoute,
            cancelledRoute,
            expiredRoute,
            pendingRoute,
          ]),
          quotationsRoute.addChildren([
            quotationsListRoute,
            createQuoteRoute,
            combinedQuotes,
          ]),
          paymentsRoute,
          flightsRoute.addChildren([flightsCalendarRoute, flightsListRoute]),

          tripRequestsRoute.addChildren([
            addTripRequestsRoute,
            activeTripRequestsRoute,
            inactiveTripRequestsRoute,
          ]),
          integrationRoute,
          dashboardRoute,
          advertiseRoute.addChildren([
            toMyClientsRoute,
            toNewClientsRoute,
            templatesRoute,
          ]),
          settingsRoute.addChildren([
            settingsProfileRoute,
            settingsAppearanceRoute,
            settingsNotificationsRoute,
            settingsMembersRoute,
            settingsBillingPlanRoute,
            settingsBillingRoute,
          ]),
        ]),
      ]),
    ]),
  ]),
])

// Create the router using your route tree
export const createRouter = (params?: { history?: RouterHistory }) =>
  new Router({ routeTree, defaultPreload: "intent", history: params?.history })

// Register your router for maximum type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: ReturnType<typeof createRouter>
  }
}
