import { Outlet, Route } from "@tanstack/react-router"

import { clientsRoute } from "#pages/clients/index.js"

export function Contacts() {
  return <Outlet />
}

export const clientsContactsRoute = new Route({
  getParentRoute: () => clientsRoute,
  path: "$client/contacts",
  component: Contacts,
})
