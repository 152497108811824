import { useTableStateStore } from "#pages/flights/flights-store.js"

import { AccordionFormOperator } from "./components/accordion-form-operator.js"

export const AccordionContentContract = ({ flightId }: { flightId: string }) => {
  const { isGeneralTableExpanded, isPreFlightTableExpanded, isPreparationTableExpanded } =
    useTableStateStore()

  return (
    !isGeneralTableExpanded &&
    !isPreparationTableExpanded &&
    !isPreFlightTableExpanded && <AccordionFormOperator flightId={flightId} />
  )
}
