import { useQuery } from "@tanstack/react-query"

import { Offer } from "#components/offers/components/offer.js"
import { graphql } from "#gql"
import { client } from "#graphql-client.js"

import { OfferSpinningLogo } from "../components/offer-spinning-logo.js"
import { OffersHeader } from "../components/offers-header.js"

const OrganizationOffersByStatusDocument = graphql(/* GraphQL */ `
  query OrganizationOffersByStatusPending($input: QueryOrganizationOffersByStatusInput!) {
    organizationOffersByStatus(input: $input) {
      id
      status
      expiresAt
      trip {
        id
        client {
          id
          name
        }
      }
      tripOptionsOffered {
        id
        createdAt
        quotes {
          createdAt
          expiresAt
          id
          status
          currency
          price
          notes
          quoteLegs {
            notes
            id
            sequenceNumber
            pax
            estimatedTimeOfDeparture
            estimatedTimeOfArrival
            arrivalDate
            departureDate
            aircraft {
              id
              pax
              registrationNumber
              yearOfBuild
              model
              images
            }
            departureAirport {
              id
              city
              name
              icaoCode
              iataCode
            }
            arrivalAirport {
              id
              city
              name
              icaoCode
              iataCode
            }
          }
        }
      }
    }
  }
`)

export const Pending = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["organizationOffersByStatusPending"],
    queryFn: () =>
      client.request(OrganizationOffersByStatusDocument, {
        input: { status: "pending" },
      }),
  })

  if (isLoading) {
    return <OfferSpinningLogo />
  }

  const offers = data?.organizationOffersByStatus

  return (
    <>
      <OffersHeader title="Pending" />
      {offers && offers.length > 0 ? (
        offers.map((offer) => {
          const quotes = offer.tripOptionsOffered.flatMap(
            (tripOption) => tripOption.quotes,
          )
          return (
            <Offer
              key={offer.id}
              quotes={quotes}
              status={offer.status}
              id={offer.id}
              expiresAt={offer.expiresAt}
              trip={offer.trip}
            />
          )
        })
      ) : (
        <div className="flex flex-col">
          <p className="py-3 text-center text-lg font-semibold">No Pending offers</p>
        </div>
      )}
    </>
  )
}
