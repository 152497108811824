import { Link, useNavigate, useRouterState } from "@tanstack/react-router"
import ChevronsLeftIcon from "lucide-static/icons/chevrons-left.svg"
import ArrowLeft from "lucide-static/icons/move-left.svg"
import InlineSVG from "react-inlinesvg"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  cn,
  Command,
  CommandGroup,
  CommandItemCustom,
  CommandList,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "@fourel/ui"

import { FeatureGate } from "#feature-gate/feature-gate.js"
import {
  informationItems,
  manageItems,
  menuItems,
  offersItems,
} from "#pages/lobby/components/clients-submenu.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

export function MobileClientsSubmenu() {
  const { currentOrg } = useOnboardedUserInfo()
  const navigate = useNavigate()

  const commandItemStyle =
    "mb-2 cursor-pointer gap-2 px-6 py-2 transition-all duration-200 ease-in-out hover:bg-violet-600/10 hover:text-violet-600 dark:hover:bg-slate-800 dark:hover:text-white"

  const clientName = useRouterState({
    select: (s) => s.location.pathname.split("clients/")[1].split("/")[0],
  })

  const submenuCategoryName = useRouterState({
    select: (s) => {
      const pathnameParts = s.location.pathname.split("/")
      return pathnameParts.includes("contacts")
        ? "contacts"
        : pathnameParts[pathnameParts.length - 1]
    },
  })

  const navigateToClientsHandler = () => {
    void navigate({
      to: "/$slug/clients",
      params: { slug: currentOrg.slug },
    })
  }

  return (
    <div className="block md:hidden">
      <Drawer direction="left">
        <div className="flex h-10 w-full items-center gap-4 border-b border-gray-200 bg-white px-4 dark:bg-slate-900">
          <DrawerTrigger asChild>
            <Button
              variant="link"
              size="sm"
              data-testid="mobile-clients-submenu-button"
              className={cn("h-8 p-0")}
            >
              <InlineSVG
                src={ChevronsLeftIcon}
                className={cn(
                  "h-5 w-5 transition-all duration-300 ease-in-out hover:text-purple-800",
                )}
              />
            </Button>
          </DrawerTrigger>
          <span className="text-xl font-bold">Clients</span>
        </div>
        <DrawerContent className="h-screen max-w-72 rounded-none">
          <div className={cn("flex items-center gap-4 px-6 pt-4")}>
            <DrawerClose asChild>
              <Button variant="link" size="sm" className={cn("h-8 p-0")}>
                <InlineSVG
                  src={ChevronsLeftIcon}
                  className={cn(
                    "h-5 w-5 transition-all duration-300 ease-in-out hover:text-purple-800",
                  )}
                />
              </Button>
            </DrawerClose>
            <span className={cn("whitespace-nowrap text-2xl font-bold tracking-wider")}>
              Clients
            </span>
          </div>
          <Button
            variant="link"
            onClick={navigateToClientsHandler}
            className="flex items-center justify-start gap-4 p-7 text-sm text-gray-500 transition-all duration-300 ease-in-out hover:text-purple-800 hover:no-underline"
          >
            <InlineSVG src={ArrowLeft} className="h-5 w-5" />
            <span>Back to clients</span>
          </Button>
          <div className="flex flex-auto overflow-hidden p-1 text-sm">
            <Command className="static w-full flex-auto overflow-y-auto bg-transparent">
              <CommandList className="flex flex-auto flex-col overflow-y-auto [&>*]:flex [&>*]:flex-auto [&>*]:flex-col">
                <CommandGroup className={cn("p-0")}>
                  {menuItems.map((item) => {
                    return item.label === "Offers" ? (
                      <Accordion type="single" collapsible key={item.label}>
                        <AccordionItem value={item.label}>
                          <AccordionTrigger
                            className={cn(
                              "mb-2 cursor-pointer gap-2 p-2 px-6 transition-all duration-300 ease-in-out hover:bg-violet-600/10 hover:text-violet-600 hover:no-underline dark:hover:bg-slate-800",
                            )}
                          >
                            {item.label}
                          </AccordionTrigger>
                          <AccordionContent>
                            {offersItems.map((offer) => (
                              <Link
                                to={offer.url}
                                key={offer.label}
                                from="/$slug/clients"
                                params={{
                                  slug: currentOrg.slug,
                                  client: clientName,
                                }}
                              >
                                <DrawerClose className="w-full">
                                  <CommandItemCustom
                                    className={cn(
                                      "ml-6",
                                      commandItemStyle,
                                      offer.url.includes(submenuCategoryName)
                                        ? "bg-violet-700 text-white hover:bg-violet-600/90 hover:text-white dark:hover:bg-violet-600/90"
                                        : "",
                                    )}
                                  >
                                    {offer.label}
                                  </CommandItemCustom>
                                </DrawerClose>
                              </Link>
                            ))}
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    ) : (
                      <Link
                        to={item.url}
                        key={item.label}
                        from="/$slug/clients"
                        params={{
                          slug: currentOrg.slug,
                          client: clientName,
                        }}
                      >
                        <DrawerClose className="w-full">
                          <CommandItemCustom
                            key={item.label}
                            className={cn(
                              commandItemStyle,
                              item.url.includes(submenuCategoryName)
                                ? "bg-violet-700 text-white hover:bg-violet-600/90 hover:text-white dark:hover:bg-violet-600/90"
                                : "",
                            )}
                          >
                            <span className={cn("whitespace-nowrap")}>{item.label}</span>
                          </CommandItemCustom>
                        </DrawerClose>
                      </Link>
                    )
                  })}
                </CommandGroup>
                <p
                  className={cn(
                    "my-5 flex items-center gap-2 pl-6 text-xs text-slate-400 after:block after:h-[1px] after:flex-auto after:bg-slate-400",
                  )}
                >
                  Information
                </p>
                <CommandGroup className={cn("p-0")}>
                  {informationItems.map((item) => (
                    <Link
                      to={item.url}
                      key={item.label}
                      from="/$slug/clients"
                      params={{
                        slug: currentOrg.slug,
                        client: clientName,
                      }}
                    >
                      <DrawerClose className="w-full">
                        <CommandItemCustom
                          className={cn(
                            commandItemStyle,
                            item.url.includes(submenuCategoryName)
                              ? "bg-violet-700 text-white hover:bg-violet-600/90 hover:text-white dark:hover:bg-violet-600/90"
                              : "",
                          )}
                        >
                          <span className={cn("whitespace-nowrap")}>{item.label}</span>
                        </CommandItemCustom>
                      </DrawerClose>
                    </Link>
                  ))}
                </CommandGroup>
                <FeatureGate name="ClientActivitiesRoute">
                  <p
                    className={cn(
                      "my-5 flex items-center gap-2 pl-6 text-xs text-slate-400 after:block after:h-[1px] after:flex-auto after:bg-slate-400",
                    )}
                  >
                    Manage
                  </p>
                  <CommandGroup className={cn("p-0")}>
                    {manageItems.map((item) => (
                      <Link
                        to={item.url}
                        key={item.label}
                        from="/$slug/clients"
                        params={{
                          slug: currentOrg.slug,
                          client: clientName,
                        }}
                      >
                        <DrawerClose className="w-full">
                          <CommandItemCustom
                            className={cn(
                              commandItemStyle,
                              item.url.includes(submenuCategoryName)
                                ? "bg-violet-700 text-white hover:bg-violet-600/90 hover:text-white dark:hover:bg-violet-600/90"
                                : "",
                            )}
                          >
                            <span className={cn("whitespace-nowrap")}>{item.label}</span>
                          </CommandItemCustom>
                        </DrawerClose>
                      </Link>
                    ))}
                  </CommandGroup>
                </FeatureGate>
              </CommandList>
            </Command>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  )
}
